import { ReactElement, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import './styles.scss';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import Notification from 'components/common/Notification';
import { UserStore, useUserStore } from 'stores/useUserStore';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { ARTICLES, PAGE_TITLES } from 'constants/constants';
import dashboardService from 'services/dashboardService';
import { AxiosError, IProduct, TitleContentPair } from 'interfaces/interfaces';
import sharedService from 'services/sharedService';
import { GetDashboardDataParams } from 'interfaces/apiRequestInterfaces';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import SummaryHorizontal from './SummaryHorizontal';
import SummaryVertical from './SummaryVertical';
import SummaryMobile from './SummaryMobile';

interface State {
  activeProducts: IProduct[];
  dueProducts: IProduct[];
  hasEAPlusProduct: string;
  clientName: string;
  showSummary: boolean;
  globalWebContents: Record<string, string>;
  pageTitle: string;
}

const Dashboard = () => {
  const [state, setState] = useState<State>({
    activeProducts: [],
    dueProducts: [],
    hasEAPlusProduct: '',
    clientName: '',
    showSummary: false,
    globalWebContents: {},
    pageTitle: '',
  });

  const { globalContextState } = useContext(GlobalContext) as GlobalContextType;

  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { processMessages, notificationsData, setInterScreenNotification } = useNotificationsStore(
    (state: NotificationsStore) => ({
      processMessages: state.processMessages,
      notificationsData: state.notificationsData,
      setInterScreenNotification: state.setInterScreenNotification,
    }),
  );

  useEffect(() => {
    setState((prevState) => ({ ...prevState, pageTitle: initData?.clientName + PAGE_TITLES.HOME }));

    setTimeout(() => {
      if (notificationsData.interScreenNotification.length) {
        processMessages(notificationsData.interScreenNotification);
        setInterScreenNotification([]);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData?.clientName]);

  useEffect(() => {
    const getDashboardData = async () => {
      const params = {
        client: initData?.client,
        accountNumber: user?.accountNumber,
        policyNumber: user?.policyNumber,
        policyHolder: {
          firstName: user?.firstName,
          lastName: user?.lastName,
        },
        userid: user?.userid,
        isEmailSubscribed: user?.isEmailSubscribed,
      } as GetDashboardDataParams;

      try {
        setAppLoading(true);
        const response = await dashboardService.getDashboardData(params);
        if (response.status === 200) {
          setState((prevState) => ({
            ...prevState,
            activeProducts: response.data.activeProducts || [],
            dueProducts: response.data.paymentsDue || [],
            hasEAPlusProduct: response.data.hasEAPlusProduct,
            clientName: response.data.clientName,
            showSummary: true,
          }));
        } else {
          setState((prevState) => ({ ...prevState, activeProducts: [], dueProducts: [] }));
        }
        processMessages(response.data.messages);
      } catch (error) {
        console.error(error);
        const axiosError = error as AxiosError;
        processMessages(axiosError?.response?.data?.messages);
      } finally {
        setAppLoading(false);
      }
    };
    getDashboardData();
  }, [
    initData?.client,
    processMessages,
    setAppLoading,
    user?.accountNumber,
    user?.firstName,
    user?.lastName,
    user?.policyNumber,
    user?.userid,
    user?.isEmailSubscribed,
  ]);

  useEffect(() => {
    const getContent = async (titles: string[]) => {
      const params = {
        titles: titles,
      };
      try {
        const response = await sharedService.getContents(params);
        if (response.status === 200) {
          const webContents: { [key: string]: string } = {};
          response?.data?.items?.forEach((element: TitleContentPair) => {
            webContents[element.title] = element.content;
          });
          setState((prevState) => ({ ...prevState, globalWebContents: webContents }));
        }
      } catch (error) {
        console.error(error);
      }
    };
    getContent([
      ARTICLES.CLAIM_NOW,
      ARTICLES.PRODUCT_DISCLAIMER,
      ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING,
      ARTICLES.PRODUCT_DISCLAIMER_NOCOST,
      ARTICLES.SITE_DISCLAIMER,
      ARTICLES.AUTOPAY_MARKETTING,
      ARTICLES.TRANSACTION_DISCLAIMER,
      ARTICLES.EA_PLUS_MEMBER_NOTE,
    ]);
  }, []);

  const getDisclaimers = () => {
    const disclaimerTypesMap = [
      { name: 'autopay', content: state.globalWebContents[ARTICLES.PRODUCT_DISCLAIMER] },
      { name: 'combinedbill', content: state.globalWebContents[ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING] },
      { name: 'nocosttoyou', content: state.globalWebContents[ARTICLES.PRODUCT_DISCLAIMER_NOCOST] },
    ];
    const components = [] as ReactElement[];
    const iterated: string[] = [];
    state.activeProducts.forEach((product, index) => {
      const disclaimerObj = disclaimerTypesMap.find((item) => item.name === product.paymentStatusDisplayStyle);
      const notIterated = !iterated.includes(product.paymentStatusDisplayStyle);

      if (disclaimerObj && notIterated) {
        components.push(<div key={index} dangerouslySetInnerHTML={{ __html: disclaimerObj.content }}></div>);
        iterated.push(product.paymentStatusDisplayStyle);
      }
    });

    return components;
  };

  return (
    <>
      <Helmet>
        <title>
          {
            // this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.HOME] ||
            state.pageTitle
          }
        </title>
      </Helmet>
      <div className="innerBodyWrapper homePage">
        <div className="blueBackground"></div>
        {!!notificationsData.infoNotification.length && (
          <div className="notification-dashboard">
            <div className="col-12">
              {notificationsData?.infoNotification?.map((notification, index) => (
                <Notification key={index} id={index} message={notification} />
              ))}
            </div>
          </div>
        )}
        <div className="col-md-12 text-left p-0 position-relative">
          <h1 className="welcomeText">
            <span className="welcome">Welcome </span>
            <span className="nameColor">
              {user?.firstName} {user?.lastName}
            </span>
          </h1>
          {!!notificationsData.nonInfoNotificaiton.length &&
            notificationsData.nonInfoNotificaiton.map((notification, index) => (
              <Notification key={index} id={index} message={notification} />
            ))}

          {state.showSummary && (
            <div>
              {globalContextState.deviceWidth >= 768 && (
                <div>
                  {state.dueProducts.length <= 1 && (
                    <SummaryHorizontal
                      clientName={state.clientName}
                      hasEAPlusProduct={state.hasEAPlusProduct}
                      dueProducts={state.dueProducts}
                      activeProducts={state.activeProducts}
                      globalWebContents={state.globalWebContents}
                    />
                  )}
                  {state.dueProducts.length > 1 && (
                    <SummaryVertical
                      clientName={state.clientName}
                      hasEAPlusProduct={state.hasEAPlusProduct}
                      dueProducts={state.dueProducts}
                      activeProducts={state.activeProducts}
                      globalWebContents={state.globalWebContents}
                    />
                  )}
                  <div className="footerTextPara">
                    {getDisclaimers()}
                    <div
                      dangerouslySetInnerHTML={{ __html: state.globalWebContents[ARTICLES.TRANSACTION_DISCLAIMER] }}
                    ></div>
                  </div>
                </div>
              )}
              {globalContextState.deviceWidth <= 767 && (
                <SummaryMobile
                  clientName={state.clientName}
                  hasEAPlusProduct={state.hasEAPlusProduct}
                  dueProducts={state.dueProducts}
                  activeProducts={state.activeProducts}
                  globalWebContents={state.globalWebContents}
                  getDisclaimers={getDisclaimers}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
