import './NoDuePaymentCard.scss';

const NoDuePaymentCard = () => {
  return (
    <div className="flexWhiteBox col-lg-12 col-md-12 float-left p-0">
      <div className="leftColumn col-lg-12 col-md-12 col-xs-12 col-12 float-left p-0">
        <div className="noDuesContent">
          <div className="bodyRow">
            <div className="flexBox">
              <div className="noDues">
                <i className="materialicons">done</i>
                <span className="dueText">No Payments Due</span>
              </div>
            </div>
          </div>
          <div className="footerCol"></div>
        </div>
      </div>
    </div>
  );
};

export default NoDuePaymentCard;
