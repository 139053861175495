import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { StepperTab } from './StepperTab';
import { LOCALIZATION } from 'constants/localization';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { NOTIFICATIONS, PAGE_TITLES } from 'constants/constants';
import authService from 'services/authService';
import { VerifyPersonalInfoParams } from 'interfaces/apiRequestInterfaces';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import Notification from 'components/common/Notification';

interface State {
  planNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  pageTitle: string;
  isFormValid: boolean;
  showInfoPopup: boolean;
  globalWebContents: Record<string, string>;
}

export const PersonalInformation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const planNumber = location?.state?.planNumber;
  const [state, setState] = useState<State>({
    planNumber,
    firstName: location.state?.firstName || '',
    lastName: location.state?.lastName || '',
    email: location.state?.email || '',
    pageTitle: '',
    isFormValid: false,
    showInfoPopup: false,
    globalWebContents: {},
  });
  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));
  const { processMessages, notificationsData } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
    notificationsData: state.notificationsData,
  }));

  const checkRequiredFields = (state: State) => {
    if (/^\s*$/.test(state.firstName)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    } else if (/^\s*$/.test(state.lastName)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    } else if (/^\s*$/.test(state.email)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    } else {
      setState((prevState) => ({ ...prevState, isFormValid: true }));
      return true;
    }
  };

  const checkValidation = () => {
    // Check if plan number is present
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!state.planNumber) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      processMessages([
        {
          type: 'FAILURE',
          content: [NOTIFICATIONS.PLAN_NUMBER_NOT_FOUND],
        },
      ]);

      return false;
    } else if (!re.test(String(state.email.trim()).toLowerCase())) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      processMessages([
        {
          type: 'FAILURE',
          content: [NOTIFICATIONS.INVALID_EMAIL_FORMAT],
        },
      ]);
      return false;
    } else {
      setState((prevState) => ({ ...prevState, isFormValid: true }));

      return true;
    }
  };

  const handleSubmit = async () => {
    if (checkRequiredFields(state)) {
      if (checkValidation()) {
        try {
          setAppLoading(true);
          const { client } = initData ?? {};
          const params: VerifyPersonalInfoParams = {
            client: client as string,
            planNumber: state.planNumber,
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email,
          };

          const response = await authService.verifyPersonalInfo(params);
          localStorage.setItem('clientName', response.data.clientName);

          if (response.status === 200) {
            navigate('/sign-in-creds', {
              state: {
                ...params,
                accountNumber: response.data.insproAccountNumbers,
                questions: response.data.questions,
                personNumber: response.data.personNumber,
              },
            });
          } else {
            processMessages(response.data.messages);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setAppLoading(false);
        }
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    checkRequiredFields({ ...state, [name]: value });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (!planNumber) {
      navigate('/');
    }
  }, [navigate, planNumber]);

  useEffect(() => {
    const { clientName } = initData ?? {};
    setState((prevState) => ({ ...prevState, pageTitle: clientName + PAGE_TITLES.REGISTRATION_PERSONAL }));
    checkRequiredFields({ ...state });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData]);

  if (!planNumber) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>
          {
            // this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.REGISTRATION_PERSONAL] ||
            state.pageTitle
          }
        </title>
      </Helmet>
      <div className="registerWrapper ">
        <div className="registerHeader">
          <h1 className="mainHeadingT">{LOCALIZATION.EN.REGISTRATION_ALL_STEPS}</h1>
          {!!notificationsData.nonInfoNotificaiton.length &&
            notificationsData.nonInfoNotificaiton.map((notification, index) => (
              <Notification key={index} id={index} message={notification} />
            ))}
        </div>
        <StepperTab step={2} />
        <div className="usernameWrapper">
          <div className="lusernameColumn mobileButton">
            <div className="mt-3 mb-3 mobileView">
              <strong>Step 2: </strong> Personal Information
            </div>
            <div className="backgroundSetColor">
              <div className="formLayout">
                <div className="form-group">
                  <div className="firstname">
                    <label htmlFor="firstname">First Name</label>
                    <input
                      value={state.firstName}
                      className="form-control"
                      type="text"
                      id="firstName"
                      onKeyDown={handleKeyDown}
                      onChange={handleChange}
                      name="firstName"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="lastname mt-3">
                    <label htmlFor="lastname">Last Name</label>
                    <input
                      value={state.lastName}
                      className="form-control"
                      type="text"
                      id="lastname"
                      onKeyDown={handleKeyDown}
                      onChange={handleChange}
                      name="lastName"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="email mt-3">
                    <label htmlFor="email">Email</label>
                    <input
                      value={state.email}
                      className="form-control"
                      type="text"
                      id="email"
                      onKeyDown={handleKeyDown}
                      onChange={handleChange}
                      name="email"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="roundBottomButton">
              <button
                id="ma-ra-step2"
                className={`btn btn-primary agiaBlueButton ${!state.isFormValid && 'disabled'}`}
                type="submit"
                onClick={handleSubmit}
                value="Next"
              >
                Next: Login Information
              </button>
              <Link
                id="ma-ra-step2Login"
                state={state}
                to={{
                  pathname: `/account-details`,
                }}
              >
                Back
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
