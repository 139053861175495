import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

import './styles.scss';
import Header from './Header';
import { InitStore, useInitStore } from 'stores/useInitStore';
import authService from 'services/authService';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { NOTIFICATIONS } from 'constants/constants';
import sharedService from 'services/sharedService';

interface State {
  userName: string;
  isFormValid: boolean;
  isBot: boolean;
}

export const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<State>({
    userName: '',
    isFormValid: false,
    isBot: true,
  });
  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { processMessages } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
  }));

  const checkValidation = (value: string): boolean => {
    const isValid = !/^\s*$/.test(value);
    setState((prevState) => ({ ...prevState, isFormValid: isValid }));
    return isValid;
  };

  const handleSubmit = async () => {
    try {
      setAppLoading(true);
      if (!state.isBot) {
        if (checkValidation(state.userName)) {
          const { client } = initData ?? {};
          const params = `/userName/${state.userName}/client/${client}`;

          const response = await authService.forgotPassword1(params);

          if (response.status === 200) {
            navigate('/forgot-password-security-question', { state: response.data });
          } else {
            processMessages(response.data.messages);
          }
        }
      } else {
        alert(NOTIFICATIONS.BOT_SUSPECTED);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setAppLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setState((prevState) => ({ ...prevState, userName: value }));
    checkValidation(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const verifyRecaptcha = useCallback(
    async (recaptchaToken: string) => {
      if (recaptchaToken) {
        const params = {
          token: recaptchaToken,
        };

        setAppLoading(true);
        try {
          const response = await sharedService.verifyCaptcha(params);
          if (response.status === 200) {
            setState((prevState) => ({ ...prevState, isBot: response.data.isBot }));
          }
        } catch (error) {
          console.error(error);
        } finally {
          setAppLoading(false);
        }
      }
    },
    [setAppLoading],
  );

  return (
    <div className="usernameWrapper forgot-password-wrapper">
      <div className="lusernameColumn mobileButton">
        <Header />
        <div className="backgroundSetColor">
          <div className="formLayout">
            <div className="form-group">
              <div className="username">
                <label htmlFor="username">Username</label>
                <input
                  className="form-control"
                  type="text"
                  onKeyDown={handleKeyDown}
                  id="username"
                  onChange={handleChange}
                  name="username"
                  required
                />
                <Link to="/forgot-username" className="findPlanText">
                  Don&apos;t know your username?
                </Link>
                <div className="invalid-feedback">Please fill out this field.</div>
              </div>
            </div>
            <GoogleReCaptcha onVerify={verifyRecaptcha} />
          </div>
        </div>
        <div className="roundBottomButton">
          <button
            className={`btn btn-primary agiaBlueButton ${!state.isFormValid && 'disabled'}`}
            onClick={handleSubmit}
          >
            Next: Security Verification
          </button>
          <Link to="/">Back to login</Link>
        </div>
      </div>
    </div>
  );
};
