import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './styles.scss';
import profileService from 'services/profileService';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { UserStore, useUserStore } from 'stores/useUserStore';
import sharedService from 'services/sharedService';
import { ARTICLES, PAGE_TITLES } from 'constants/constants';
import { AxiosError, Notification as NotificationType, TitleContentPair, UserProfileType } from 'interfaces/interfaces';
import ChangeSecurityQuestionPopup from './ChangeSecurityQuestionPopup';
import ChangePasswordPopup from './ChangePasswordPopup';
import ChangeContactInfoPopup from './ChangeContactInfoPopup';
import Notification from 'components/common/Notification';
import { phoneFormatting } from 'utils/utils';
import { GetProfileParams } from 'interfaces/apiRequestInterfaces';

interface State {
  questions: Array<{
    questionId: string;
    question: string;
  }>;
  pageTitle: string;
  globalWebContents: Record<string, string>;
}

const MyProfile = () => {
  const [userProfile, setUserProfile] = useState<UserProfileType | null>(null);
  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState<boolean>(false);
  const [showChangeContactInfoPopUp, setShowChangeContactInfoPopUp] = useState<boolean>(false);
  const [showChangeSecurityQuestionPopup, setShowChangeSecurityQuestionPopup] = useState<boolean>(false);
  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));
  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));
  const { processMessages, notificationsData } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
    notificationsData: state.notificationsData,
  }));
  const [state, setState] = useState<State>({
    pageTitle: '',
    globalWebContents: {},
    questions: [],
  });

  const getSecurityQuestions = useCallback(async () => {
    const response = await sharedService.getSecurityQuestions();

    if (response.status === 200) {
      setState((prevState) => ({ ...prevState, questions: response.data.items }));
    } else {
      processMessages(response.data?.messages);
    }
  }, [processMessages]);

  const getProfile = useCallback(async () => {
    if (!user) {
      return null;
    }

    try {
      setAppLoading(true);
      const { client } = initData ?? {};
      const params: GetProfileParams = {
        client: client as string,
        accountNumber: user.accountNumber,
        policyNumber: user.policyNumber,
        policyHolder: {
          firstName: user.firstName,
          lastName: user.lastName,
        },
        userid: user.userid,
        isEmailSubscribed: user?.isEmailSubscribed,
      };
      const response = await profileService.getProfile(params);

      if (response.status === 200) {
        setUserProfile(response.data);
      } else {
        processMessages(response.data?.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  }, [initData, processMessages, setAppLoading, user]);

  const handleContactInfoPopupClose = (data?: {
    messages: NotificationType[];
    userProfile: UserProfileType | null;
  }) => {
    setShowChangeContactInfoPopUp(false);
    if (data) {
      processMessages(data?.messages);
      setUserProfile(data.userProfile);
    }
  };

  const handleChangePasswordClose = (data?: { messages: NotificationType[] }) => {
    setShowChangePasswordPopup(false);
    if (data) {
      processMessages(data?.messages);
    }
  };

  const handleSecurityQuestionClose = (data?: { selectedQuestion: string; messages: NotificationType[] }) => {
    setShowChangeSecurityQuestionPopup(false);
    if (data) {
      const updatedProfile = { ...userProfile, securityQuestion: data.selectedQuestion } as UserProfileType;
      setUserProfile(updatedProfile);
      processMessages(data?.messages);
    }
  };

  const handleResubscribe = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (!user?.csUserid) {
      try {
        setAppLoading(true);
        const response = await sharedService.resubscribe(userProfile?.userId as string);

        if (response.status === 200) {
          const updatedProfile = { ...userProfile, emailUnsubscribedStatus: false } as UserProfileType;
          setUserProfile(updatedProfile);
        } else {
          processMessages(response.data.messages);
        }
      } catch (error) {
        console.error(error);
        const axiosError = error as AxiosError;
        processMessages(axiosError?.response?.data?.messages);
      } finally {
        setAppLoading(false);
      }
    }
  };

  useEffect(() => {
    const { clientName } = initData ?? {};
    setState((prevState) => ({ ...prevState, pageTitle: clientName + PAGE_TITLES.REGISTRATION_LOGIN }));
    const getContent = async (titles: string[]) => {
      try {
        setAppLoading(true);
        const params = {
          titles,
        };
        const response = await sharedService.getContents(params);
        if (response.status === 200) {
          const webContents: Record<string, string> = {};
          response.data.items.forEach((element: TitleContentPair) => {
            webContents[element.title] = element.content;
          });
          setState((prevState) => ({ ...prevState, globalWebContents: webContents }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setAppLoading(false);
      }
    };

    getProfile();
    getContent([ARTICLES.MYPROFILE_TIP]);
    getSecurityQuestions();
  }, [getProfile, getSecurityQuestions, initData, setAppLoading]);

  return (
    <>
      <Helmet>
        <title>{state.pageTitle}</title>
      </Helmet>
      <div className="innerBodyWrapper">
        {notificationsData?.infoNotification?.map((notification, index) => (
          <Notification key={index} id={index} message={notification} />
        ))}
        <div className="float-left innerContent myProfile">
          <div className="myProfileContent">
            <h1 className="mainHeadingT">Profile</h1>
            {notificationsData?.nonInfoNotificaiton?.map((notification, index) => (
              <Notification key={index} id={index} message={notification} />
            ))}
            {userProfile && (
              <>
                {showChangePasswordPopup && (
                  <ChangePasswordPopup show={showChangePasswordPopup} hide={handleChangePasswordClose} />
                )}
                {showChangeContactInfoPopUp && (
                  <ChangeContactInfoPopup
                    userProfile={userProfile}
                    show={showChangeContactInfoPopUp}
                    hide={handleContactInfoPopupClose}
                  />
                )}
                {showChangeSecurityQuestionPopup && (
                  <ChangeSecurityQuestionPopup
                    selectedQuestion={userProfile.securityQuestion as string}
                    questions={state.questions}
                    show={showChangeSecurityQuestionPopup}
                    hide={handleSecurityQuestionClose}
                  />
                )}
                <div className="customerInfo">
                  <div className="profileName">
                    <label>{'Full Name'}</label>
                    <label>
                      {userProfile.policyHolder.firstName} {userProfile.policyHolder.lastName}
                    </label>
                  </div>
                  <div className="dob">
                    <label>{'Date of Birth'}</label>
                    <label>{userProfile.policyHolder.dateOfBirth}</label>
                  </div>
                </div>
                <div className="flexWhiteBox">
                  <div className="leftColumn">
                    <div className="headerColumn">
                      <h3>Contact Information</h3>
                      <Link
                        to="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          if (userProfile.editOption.save.disable === 'N') {
                            setShowChangeContactInfoPopUp(true);
                          }
                        }}
                        className={`makeChanges btn-links ${userProfile.editOption.save.disable === 'Y' ? 'disabled-link-btn' : ''}`}
                      >
                        Make Changes
                      </Link>
                    </div>
                    <div className="bodyRow">
                      <div className="flexBox">
                        <ul>
                          <li>
                            <span className="label">ADDRESS</span>
                            <span className="value">
                              <span title={userProfile.address.addressLine1}>{userProfile.address.addressLine1}</span>
                              {userProfile.address.addressLine2 && (
                                <span title={userProfile.address.addressLine2}>{userProfile.address.addressLine2}</span>
                              )}
                              {userProfile.address.addressLine3 && (
                                <span title={userProfile.address.addressLine3}>{userProfile.address.addressLine3}</span>
                              )}
                              <span
                                title={`${userProfile.address.cityName} ${userProfile.address.stateCode} ${userProfile.address.zipCode}`}
                                className="single-line-ellipses"
                              >
                                {userProfile.address.cityName}, {userProfile.address.stateCode}{' '}
                                {userProfile.address.zipCode}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label">PHONE</span>
                            <span className="value">{phoneFormatting(userProfile.phoneNumber)}</span>
                          </li>
                          <li>
                            <span className="label">EMAIL</span>
                            <span className="value">
                              <span title={userProfile.policyHolder.emailAddress} className="single-line-ellipses">
                                {userProfile.policyHolder.emailAddress}
                              </span>
                            </span>
                          </li>
                          {userProfile.emailUnsubscribedStatus && (
                            <li>
                              <span className="label">TRANSACTION EMAILS</span>
                              <span className="value">
                                <span title={userProfile.policyHolder.emailAddress} className="single-line-ellipses">
                                  <b>Unsubscribed</b>&nbsp;&nbsp;&nbsp;
                                  <a
                                    href="#/"
                                    onClick={handleResubscribe}
                                    className={`resubscribe-link ${user?.csUserid ? 'disabled-link-btn' : ''}`}
                                  >
                                    Resubscribe
                                  </a>
                                </span>
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="rightColumn">
                    <div className="headerColumn">
                      <h3>Security</h3>
                    </div>
                    <div className="bodyRow">
                      <div className="flexBox">
                        <ul>
                          <li>
                            <span className="label">PASSWORD</span>
                            <span className="value">
                              <span>***********</span>
                              <span>
                                <Link
                                  to="/#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (userProfile.editOption.save.disable === 'N') {
                                      setShowChangePasswordPopup(true);
                                    }
                                  }}
                                  className={`btn-links ${userProfile.editOption.save.disable === 'Y' ? 'disabled-link-btn' : ''}`}
                                >
                                  Change Password
                                </Link>
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label">SECURITY Q&A</span>
                            <span className="value">
                              <span>{userProfile.securityQuestion}</span>
                              <span>
                                <Link
                                  to="/#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (userProfile.editOption.save.disable === 'N') {
                                      setShowChangeSecurityQuestionPopup(true);
                                    }
                                  }}
                                  className={`btn-links ${userProfile.editOption.save.disable === 'Y' ? 'disabled-link-btn' : ''}`}
                                >
                                  Change
                                </Link>
                              </span>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="footerTextPara mt-4"
                  dangerouslySetInnerHTML={{ __html: state.globalWebContents[ARTICLES.MYPROFILE_TIP] }}
                ></div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
