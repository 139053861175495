import { create } from 'zustand';

import { PaymentMethod } from 'interfaces/interfaces';

export interface PaymentCenterStore {
  paymentMethods: PaymentMethod[];
  setPaymentMethods: (paymentMethods: PaymentMethod[]) => void;
  resetPaymentCenterStore: () => void;
}

const initialState = {
  paymentMethods: [],
};

export const usePaymentCenterStore = create<PaymentCenterStore>()((set) => ({
  ...initialState,
  setPaymentMethods: (paymentMethods: PaymentMethod[]) => set(() => ({ paymentMethods })),
  resetPaymentCenterStore: () => set(() => ({ ...initialState })),
}));
