import { Navigate, Outlet } from 'react-router-dom';

import Layout from 'components/Layout';
import authService from 'services/authService';

const PrivateRoutes = () => {
  return authService.isUserLoggedIn() ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoutes;
