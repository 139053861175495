import { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import './styles.scss';
import { ARTICLES, PAGE_TITLES } from 'constants/constants';
import ProductsMainMobile from './ProductsMainMobile';
import ProductsMainDesktop from './ProductsMainDesktop';
import Notification from 'components/common/Notification';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { AxiosError, Product, TitleContentPair } from 'interfaces/interfaces';
import sharedService from 'services/sharedService';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { UserStore, useUserStore } from 'stores/useUserStore';
import productsService from 'services/productsService';
import { GetProductsParams } from 'interfaces/apiRequestInterfaces';

interface State {
  pageTitle: string;
  globalWebContents: Record<string, string>;
}

const ProductsMain = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [inactiveProducts, setInactiveProducts] = useState<Product[]>([]);
  const [state, setState] = useState<State>({
    pageTitle: '',
    globalWebContents: {},
  });
  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));
  const { processMessages, notificationsData } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
    notificationsData: state.notificationsData,
  }));
  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));
  const { globalContextState } = useContext(GlobalContext) as GlobalContextType;

  const getProducts = useCallback(async () => {
    if (!user) {
      return null;
    }

    try {
      setAppLoading(true);
      const { client } = initData ?? {};
      const params: GetProductsParams = {
        client: client as string,
        accountNumber: user.accountNumber,
        policyNumber: user.policyNumber,
        firstName: user.firstName,
        lastName: user.lastName,
        userid: user.userid,
        isEmailSubscribed: user?.isEmailSubscribed,
      };
      const response = await productsService.getProducts(params);

      if (response.status === 200) {
        setProducts(response.data.activeProducts || []);
        setInactiveProducts(response.data.inactiveProducts || []);
      } else {
        setProducts([]);
        processMessages(response.data?.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  }, [initData, processMessages, setAppLoading, user]);

  useEffect(() => {
    const { clientName } = initData ?? {};
    setState((prevState) => ({ ...prevState, pageTitle: clientName + PAGE_TITLES.PRODUCT_SUMMARY }));
    const getContent = async (titles: string[]) => {
      try {
        setAppLoading(true);
        const params = {
          titles,
        };
        const response = await sharedService.getContents(params);
        if (response.status === 200) {
          const webContents: Record<string, string> = {};
          response.data.items.forEach((element: TitleContentPair) => {
            webContents[element.title] = element.content;
          });
          setState((prevState) => ({ ...prevState, globalWebContents: webContents }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setAppLoading(false);
      }
    };

    getProducts();
    getContent([
      ARTICLES.CLAIM_NOW,
      ARTICLES.PRODUCT_DISCLAIMER,
      ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING,
      ARTICLES.PRODUCT_DISCLAIMER_NOCOST,
      ARTICLES.SITE_DISCLAIMER,
      ARTICLES.ANNUAL_PRODUCT_COST_DISCLAIMER,
    ]);
  }, [initData, setAppLoading, getProducts]);

  return (
    <>
      <Helmet>
        <title>
          {
            // globalContextState?.pagesTitle?.[PAGES_TITLE_KEYS.PRODUCT_SUMMARY] ||
            state.pageTitle
          }
        </title>
      </Helmet>
      <div className="innerBodyWrapper products-main-wrapper">
        {notificationsData.infoNotification?.map((notification, index: number) => (
          <Notification key={index} id={index} message={notification} />
        ))}
        <div className="productsDetails">
          <h1 className="mainHeadingT">Products</h1>
          {notificationsData.nonInfoNotificaiton.map((notification, index: number) => (
            <Notification key={index} id={index} message={notification} />
          ))}
          {globalContextState.deviceWidth >= 768 ? (
            <ProductsMainDesktop
              products={products}
              inactiveProducts={inactiveProducts}
              globalWebContents={state.globalWebContents}
            />
          ) : (
            <ProductsMainMobile
              products={products}
              inactiveProducts={inactiveProducts}
              globalWebContents={state.globalWebContents}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProductsMain;
