import Login from 'components/Login';
import CustomerServiceLogin from 'components/CustomerServiceLogin';
import { InitStore, useInitStore } from 'stores/useInitStore';

const Home = () => {
  const { initData } = useInitStore((state: InitStore) => ({
    initData: state.initData,
  }));

  return <> {initData?.isCustomerSite ? <CustomerServiceLogin /> : <Login />}</>;
};

export default Home;
