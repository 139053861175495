const HOST = import.meta.env.VITE_APP_HOST;
const INIT_DATA_HOST = import.meta.env.VITE_APP_INIT_DATA_HOST;
const ASSETS_BASE_URL = import.meta.env.VITE_APP_ASSETS_BASE_URL;
const APP_CONTENT_SERVICE_PATH = '/agia-appcontent-service/api/v1';
const MY_ACCOUNT_CORE_SERVICE_PATH = '/myaccount-core-service/api/v1';
const APP_AUTH_SERVICE_PATH = '/agia-appauth-service/api/v1';

export const ENDPOINTS = {
  initData: (hostName: string) => `${INIT_DATA_HOST}${APP_CONTENT_SERVICE_PATH}/client-attribute/${hostName}`,
  assetsBaseURL: ASSETS_BASE_URL,
  insproService: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/utility/serviceStatus`,
  getContents: (hostName: string) => `${HOST}${APP_CONTENT_SERVICE_PATH}/contents/client/${hostName}`,
  login: `${HOST}${APP_AUTH_SERVICE_PATH}/account/login`,
  publicIP: 'https://api.ipify.org/?format=json',
  updateUserInformation: `${HOST}${APP_AUTH_SERVICE_PATH}/account/updateUserInformation`,
  forgotPassword: `${HOST}${APP_AUTH_SERVICE_PATH}/account/password`,
  verifyCaptcha: `${HOST}${APP_AUTH_SERVICE_PATH}/security/bot`,
  forgotUsername: `${HOST}${APP_AUTH_SERVICE_PATH}/account/username`,
  verifyPolicyNumber: `${HOST}${APP_AUTH_SERVICE_PATH}/account/policy-verification`,
  verifyPersonalInfo: `${HOST}${APP_AUTH_SERVICE_PATH}/account/name-client-verification`,
  verifySignInCreds: `${HOST}${APP_AUTH_SERVICE_PATH}/account/registration`,
  getStatesList: `${HOST}${APP_CONTENT_SERVICE_PATH}/states?countryId=19`,
  getPagesTitle: `${HOST}${APP_CONTENT_SERVICE_PATH}/contentStructure`,
  logout: (access_token: string) => `${HOST}${APP_AUTH_SERVICE_PATH}/account/logout/${access_token}`,
  dashboard: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/homepage`,
  getProfile: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/profile`,
  updateMyProfile: `${HOST}${APP_AUTH_SERVICE_PATH}/user/profile`,
  getSecurityQuestions: `${HOST}${APP_AUTH_SERVICE_PATH}/user/getSecurityQuestions`,
  updateSecurityDetails: `${HOST}${APP_AUTH_SERVICE_PATH}/user/updateSecurityDetails`,
  changePassword: `${HOST}${APP_AUTH_SERVICE_PATH}/account/password`,
  resubscribe: (userId: string) => `${HOST}${APP_AUTH_SERVICE_PATH}/user/subscribe/false/userId/${userId}`,
  getPaymentMethods: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/payment/paymentMethods`,
  updatePaymentMethod: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/payment/paymentMethod`,
  deletePaymentMethod: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/payment/paymentMethodDelete`,
  getProducts: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/payment`,
  getUpdatedFrequency: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/payment/autoPaymentPreference`,
  getPaymentInfo: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/payment/paymentAmountBreakdown`,
  getPayNowHomePage: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/homepage/paynow`,
  getPayNow: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/payment/paynow`,
  submitPayNow: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/payment/oneTimePayment`,
  getProductsMain: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/product/summary`,
  getProductDetails: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/product/details`,
  viewDocument: (documentId: string, documentType: string, documentCategory: string) =>
    `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/product/document/${documentId}/documentSource/${documentType}/documentCategory/${documentCategory}`,
  resetPassword: `${HOST}${APP_AUTH_SERVICE_PATH}/account/reset-password`,
  unsubscribe: (userId: string) => `${HOST}${APP_AUTH_SERVICE_PATH}/user/subscribe/true/userId/${userId}`,
  searchClient: (searchKey: string, searchValue: string, userId?: string) =>
    `${HOST}${APP_AUTH_SERVICE_PATH}/customerService/${searchKey}/${searchValue}${userId ? `/user-id/${userId}` : ''}`,
  impersonateUser: (userId: string) =>
    `${HOST}${APP_AUTH_SERVICE_PATH}/customerService/impersonateUser/user-id/${userId}`,
  deleteUser: (userId: string) => `${HOST}${APP_AUTH_SERVICE_PATH}/customerService/deleteUser/username/${userId}`,
  backendServices: `${HOST}${MY_ACCOUNT_CORE_SERVICE_PATH}/utility/backendServices`,
};

export const byPassCsUserId = [ENDPOINTS.getContents(document.location.hostname)];

export const SENTRY_CONFIG = {
  sentryDSN: import.meta.env.VITE_APP_SENTRY_DSN,
  sentryENV: import.meta.env.VITE_APP_ENV,
};
