import Axios from './Axios';

import { GetProfileParams, UpdateProfileParams, UpdateSecurityDetailsParams } from 'interfaces/apiRequestInterfaces';
import { ENDPOINTS } from 'constants/endpoints';

const profileService = {
  getProfile: (params: GetProfileParams) => {
    return Axios().post(ENDPOINTS.getProfile, params);
  },
  updateSecurityDetails: (params: UpdateSecurityDetailsParams) => {
    return Axios().put(ENDPOINTS.updateSecurityDetails, params);
  },
  updateProfile: (params: UpdateProfileParams) => {
    return Axios().put(ENDPOINTS.updateMyProfile, params);
  },
};

export default profileService;
