import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import BModal from 'react-bootstrap/Modal';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { UserStore, useUserStore } from 'stores/useUserStore';

interface ModalProps {
  title: string;
  onClose: () => void;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

const Modal = ({ title, onClose, children, footer }: ModalProps) => {
  const location = useLocation();
  const [className, setClassName] = useState('');

  const { triggerGTMDataLayer } = useContext(GlobalContext) as GlobalContextType;

  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));

  const { initData } = useInitStore((state: InitStore) => ({
    initData: state.initData,
  }));

  useEffect(() => {
    if (title === 'Privacy Policy' || title === 'Terms and Conditions') {
      setClassName('addPaymentMethodPopup');
    }
    if (title === 'Where to find your Certificate/Plan Number?') {
      setClassName('registerPopup');
    }
    triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: `/${title.toLowerCase().replaceAll(' ', '-')}`,
      pageTitle: `${user?.csUserid ? 'CS:' : ''} ${initData?.clientName} - ${title}`,
    });

    return () => {
      triggerGTMDataLayer({
        event: 'MyAccountPageView',
        pagePath: location.pathname,
        pageTitle: `${user?.csUserid ? 'CS:' : ''} ${window.document.title}`,
      });
      setClassName('');
    };
  }, [initData?.clientName, location.pathname, title, triggerGTMDataLayer, user?.csUserid]);

  return (
    <BModal className={className} show onHide={onClose} backdrop="static">
      <BModal.Header closeButton>
        <BModal.Title>{title}</BModal.Title>
      </BModal.Header>
      <BModal.Body>{children}</BModal.Body>
      <BModal.Footer>
        {footer}
        <Button variant="outline-secondary" className="btn agiaBlueButton-outline grayButton" onClick={onClose}>
          Close
        </Button>
      </BModal.Footer>
    </BModal>
  );
};

export default Modal;
