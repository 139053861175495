/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import './styles.scss';
import { PAGE_TITLES } from 'constants/constants';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { CSUserStore, useCSUserStore } from 'stores/useCSUserStore';
import { AxiosError } from 'interfaces/interfaces';
import Notification from 'components/common/Notification';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import searchService from 'services/searchService';
import authService from 'services/authService';
import ConfirmModal from 'pages/PaymentCenter/PaymentCenterProducts/ConfirmModal';

interface State {
  searchValue: string;
  formSubmit: boolean;
  placeholder: Record<string, string>;
  title: Record<string, string>;
  searchResult: any;
  searchBy: string;
  pageTitle: string;
  showConfirmBox: boolean;
  confirmPopup: Record<string, string>;
  selectedUser: any;
  isFormValid: boolean;
}

const CustomerServiceHome = () => {
  const [state, setState] = useState<State>({
    searchValue: '',
    formSubmit: false,
    placeholder: {
      'policy-number': "Search by client's Policy Number",
      'account-number': "Search by client's Account Number",
      'user-name': 'Search by Username',
      'confirmation-number': 'Search by Payment Confirmation Number',
    },
    title: {},
    searchResult: [],
    searchBy: 'policy-number',
    pageTitle: '',
    showConfirmBox: false,
    confirmPopup: {
      title: 'Are you sure?',
      content: "Are you sure you want to delete this user's My Account registration?",
      positiveBtnText: 'Delete',
      negativeBtnText: 'Cancel',
    },
    selectedUser: null,
    isFormValid: false,
  });

  const { globalContextState } = useContext(GlobalContext) as GlobalContextType;

  const { csuser } = useCSUserStore((state: CSUserStore) => ({
    csuser: state.user,
  }));

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { processMessages, notificationsData, resetMessages } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
    notificationsData: state.notificationsData,
    resetMessages: state.resetMessages,
  }));

  useEffect(() => {
    setState((prevState) => ({ ...prevState, pageTitle: initData?.clientName + PAGE_TITLES.HOME }));
  }, [initData?.clientName]);

  const search = async () => {
    setState((prevState) => ({ ...prevState, formSubmit: true }));
    resetMessages();
    if (checkValidation()) {
      const params = {
        search: state.searchBy,
        value: state.searchValue,
        userId: csuser?.userid as string,
      };

      try {
        const response = await searchService.searchClient(params);
        setState((prevState) => ({ ...prevState, searchResult: [] }));
        if (response.status === 200) {
          if (params.search !== 'account-number' && params.search !== 'user-name') {
            prepareResult(response.data);
          } else if (response.data.items) {
            response.data.items.forEach((item: any) => {
              prepareResult(item);
            });
          }
        } else {
          processMessages(response.data.messages);
        }
      } catch (error) {
        console.error(error);
        const axiosError = error as AxiosError;
        processMessages(axiosError?.response?.data?.messages);
      } finally {
        setAppLoading(false);
      }
    }
  };

  const checkValidation = () => {
    if (/^$/.test(state.searchValue)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }
    setState((prevState) => ({ ...prevState, isFormValid: true }));
    return true;
  };

  const prepareResult = (result: any) => {
    const hasPaymentDetails = !!result.paymentDetail;
    const paymentMethodType =
      hasPaymentDetails && result.paymentDetail[0].paymentType === 'CREDITCARD' ? 'creditCard' : 'bankAccount';
    const paymentMethodStr =
      hasPaymentDetails &&
      `${result.paymentDetail[0][paymentMethodType].paymentType} ${result.paymentDetail[0][paymentMethodType].accountNumberLast4Digit}`;

    const data = {
      account_number: result.accountNumber,
      policy_number: result.policyNumber === null ? '' : result.policyNumber,
      user_name: result.userid,
      full_name: `${result.policyHolder.firstName ? result.policyHolder.firstName : ''} ${result.policyHolder.lastName !== '' ? result.policyHolder.lastName : ' '}`,
      client: result.client,
      comments: {
        payment_confirmation_number: [
          result.paymentConfirmationNumber ? 'Payment Confirmation No: ' + result.paymentConfirmationNumber : '',
        ].join('\n'),
        payment_amount: [hasPaymentDetails ? 'Payment Amount:' + result.paymentDetail[0].paymentAmount : ''].join(
          ':\n',
        ),
        payment_date: [result.paymentDate ? 'Payment Date:' + result.paymentDate : ''].join(':\n'),
        policy_numbers: [hasPaymentDetails ? 'Policy Number(s) Paid:' + result.paymentDetail[0].policyNumber : ''].join(
          '\n',
        ),
        payment_method: [paymentMethodStr ? 'Payment Methods: ' + paymentMethodStr : ''].join('\n'),
        billing_day: [result.billingDay ? 'Billing day: ' + result.billingDay : ''].join('\n'),
      },
      actualUserId: result.actualUserid,
    };
    const titles = {
      account_number: 'Account Number',
      policy_number: 'Policy Number',
      user_name: 'Username',
      full_name: 'Fullname',
      client: 'Client',
      comments: 'Comments',
    };

    setState((prevState) => ({ ...prevState, searchResult: [...prevState.searchResult, data], title: titles }));
  };

  const checkRequiredFields = useCallback(() => {
    if (/^\s*$/.test(state.searchValue)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    setState((prevState) => ({ ...prevState, isFormValid: true }));
    return true;
  }, [state.searchValue]);

  useEffect(() => {
    checkRequiredFields();
  }, [state.searchValue, checkRequiredFields]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      search();
    }
  };

  const impersonateUser = async (user: any) => {
    const params = {
      userId: user.actualUserId,
    };
    try {
      const response = await authService.impersonateUser(params);
      if (response.status === 200) {
        const userSession = { ...response.data, csUserid: csuser?.userid };
        window.open('https://' + response.data.siteURL + '/logging-in/' + btoa(JSON.stringify(userSession)));
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    }
  };

  const resetUserPassword = async (user: any) => {
    const params = {
      userName: user.actualUserId,
      isCustomerUser: true,
    };

    try {
      const response = await authService.resetUserPassword(params);
      processMessages(response.data.messages);
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    }
  };

  const deleteUser = (user: any) => {
    setState((prevState) => ({ ...prevState, showConfirmBox: true, selectedUser: user }));
  };

  const handleConfirmBoxClose = async (confirmation: boolean) => {
    setState((prevState) => ({ ...prevState, showConfirmBox: false }));

    if (confirmation) {
      const params = {
        userId: state.selectedUser.actualUserId,
      };
      try {
        const response = await authService.deleteUser(params);
        if (response.status === 200) {
          setState((prevState) => ({ ...prevState, searchResult: [] }));
        }
        processMessages(response.data.messages);
      } catch (error) {
        console.error(error);
        const axiosError = error as AxiosError;
        processMessages(axiosError?.response?.data?.messages);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {
            // this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.HOME] ||
            state.pageTitle
          }
        </title>
      </Helmet>
      {state.showConfirmBox && (
        <ConfirmModal
          show={state.showConfirmBox}
          hide={handleConfirmBoxClose}
          title={state.confirmPopup.title}
          content={state.confirmPopup.content}
          positiveBtnText={state.confirmPopup.positiveBtnText}
          negativeBtnText={state.confirmPopup.negativeBtnText}
        />
      )}
      <div className="innerBodyWrapper cunsumerHome">
        <div className="cunsumerHomeInner">
          <h1 className="mainHeadingT">Search Clients</h1>
          <div className="notification-container">
            {notificationsData.infoNotification.map((notification, index) => (
              <Notification key={index} id={index} message={notification} />
            ))}
            {notificationsData.nonInfoNotificaiton.map((notification, index) => (
              <Notification key={index} id={index} message={notification} />
            ))}
          </div>
          <div className="cunsumerSearchBody">
            <div className="row">
              <div className="col-sm-5 col-xs-12">
                <select className="form-control" name="searchBy" onChange={handleChange}>
                  {Object.keys(state.placeholder).map((key) => (
                    <option value={key} key={key}>
                      {state.placeholder[key]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-5 col-xs-12">
                <input
                  onKeyDown={handleKeyDown}
                  className={'form-control ' + (!state.isFormValid && state.formSubmit ? 'invalid-input' : '')}
                  type="text"
                  name="searchValue"
                  placeholder="Enter value"
                  value={state.searchValue}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-2 col-xs-12">
                <button
                  type="button"
                  className="btn agiaBlueButton btn-primary form-control"
                  onClick={search}
                  disabled={!state.isFormValid}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-xs-12">
              {!!Object.keys(state.searchResult).length && (
                <div className="table-wrapper">
                  <table
                    className={
                      'table table-light borderless ' +
                      (globalContextState.deviceWidth <= 767
                        ? 'card-list-table'
                        : 'productTable searchCustomer table-responsive')
                    }
                  >
                    <thead>
                      <tr>
                        {Object.keys(state.title).map((key) => (
                          <th className={key} key={key}>
                            {state.title[key]}
                          </th>
                        ))}
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(state.searchResult).map((rowKey, i) => (
                        <tr key={i}>
                          {Object.keys(state.searchResult[rowKey]).map(
                            (key) =>
                              state.title[key] && (
                                <td key={key} data-title={state.title[key]}>
                                  {typeof state.searchResult[rowKey][key] !== 'object'
                                    ? state.searchResult[rowKey][key]
                                    : Object.keys(state.searchResult[rowKey][key]).map((k: string) => (
                                        <div key={k}>{state.searchResult[rowKey][key][k]}</div>
                                      ))}
                                </td>
                              ),
                          )}
                          <td className="row-btns">
                            <button
                              type="button"
                              className={'btn agiaBlueButton btn-primary form-control'}
                              onClick={() => impersonateUser(state.searchResult[rowKey])}
                            >
                              Impersonate
                            </button>
                            <button
                              type="button"
                              className={'btn agiaBlueButton btn-primary form-control'}
                              onClick={() => resetUserPassword(state.searchResult[rowKey])}
                            >
                              Reset Password
                            </button>
                            <button
                              type="button"
                              className={'btn btn-danger form-control'}
                              onClick={() => deleteUser(state.searchResult[rowKey])}
                            >
                              Delete Account
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerServiceHome;
