import { useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { PaymentMethod, Notification as INotification, AxiosError } from 'interfaces/interfaces';
import paymentCenterService from 'services/paymentCenterService';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { UserStore, useUserStore } from 'stores/useUserStore';

interface DeletePaymentMethodModalProps {
  content: string;
  show: boolean;
  hide: (data?: PaymentMethod[] | null, messages?: INotification[]) => void;
  selectedPaymentMethod: PaymentMethod | null;
}

const DeletePaymentMethodModal = ({ show, hide, selectedPaymentMethod, content }: DeletePaymentMethodModalProps) => {
  const location = useLocation();
  const { triggerGTMDataLayer } = useContext(GlobalContext) as GlobalContextType;

  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  useEffect(() => {
    triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: '/confirm-delete-payment-method',
      pageTitle: `${user?.csUserid ? 'CS:' : ''} ${initData?.clientName} - Delete Payment Method?`,
    });
    return () => {
      triggerGTMDataLayer({
        event: 'MyAccountPageView',
        pagePath: location.pathname,
        pageTitle: `${user?.csUserid ? 'CS:' : ''} ${window.document.title}`,
      });
    };
  }, []);

  const deletePaymentMethod = async () => {
    const params = {
      client: initData?.client,
      accountNumber: user?.accountNumber,
      policyNumber: user?.policyNumber,
      firstName: user?.firstName,
      lastName: user?.lastName,
      userid: user?.userid,
      isEmailSubscribed: user?.isEmailSubscribed,
      recordId: selectedPaymentMethod?.paymentRecordId,
      methodType: selectedPaymentMethod?.bankAccount
        ? selectedPaymentMethod?.bankAccount.paymentType
        : selectedPaymentMethod?.creditCard.paymentType,
    } as Record<string, string>;

    try {
      setAppLoading(true);
      const response = await paymentCenterService.deletePaymentMethod(params);
      if (response.status === 200) {
        hide(response.data.paymentMethods || [], response.data.messages);
      } else {
        hide(null, response.data.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      hide(null, axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  };

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Payment Method?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => {
            hide();
          }}
          type="button"
          className="btn agiaBlueButton-outline"
          data-dismiss="modal"
        >
          Cancel
        </button>

        <button
          disabled={selectedPaymentMethod?.managePaymentMethodOption.deletePaymentMethodConfirmation.disable === 'Y'}
          onClick={deletePaymentMethod}
          type="button"
          className="btn btn-danger"
          data-dismiss="modal"
        >
          Delete Method
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeletePaymentMethodModal;
