import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import './styles.scss';
import Header from './Header';
import authService from 'services/authService';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { ForgotPasswordPostParams } from 'interfaces/apiRequestInterfaces';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';

interface State {
  answer: string;
  isFormValid: boolean;
}

export const ForgotPasswordSecurityQuestion = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const securityQuestion = location.state.securityQuestion;
  const [state, setState] = useState<State>({
    answer: '',
    isFormValid: false,
  });
  const { setAppLoading } = useInitStore((state: InitStore) => ({
    setAppLoading: state.setAppLoading,
  }));
  const { processMessages } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
  }));

  const checkValidation = (value: string): boolean => {
    const isValid = !/^\s*$/.test(value);
    setState((prevState) => ({ ...prevState, isFormValid: isValid }));
    return isValid;
  };

  const handleSubmit = async () => {
    try {
      setAppLoading(true);
      if (checkValidation(state.answer)) {
        const params: ForgotPasswordPostParams = {
          securityAnswer: state.answer,
          userName: location.state.userName,
          hostName: document.location.hostname,
          isCustomerUser: false,
        };

        const response = await authService.forgotPassword2(params);

        if (response.status === 200) {
          navigate('/');
        } else {
          processMessages(response.data.messages);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setAppLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setState((prevState) => ({ ...prevState, answer: value }));
    checkValidation(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (!securityQuestion) {
      navigate('/');
    }
  }, [navigate, securityQuestion]);

  if (!securityQuestion) {
    return null;
  }

  return (
    <div className="resetPwd usernameWrapper forgot-password-wrapper">
      <div className="lusernameColumn">
        <Header />
        <div className="backgroundSetColor">
          <div className="formLayout">
            <h2 className="homeHederText ms-3">Security Identification</h2>
            <div className="formLayout lastName">
              <label htmlFor="pwd">{securityQuestion}</label>
              <input
                className="form-control"
                type="text"
                id="answer"
                onKeyDown={handleKeyDown}
                name="answer"
                required
                onChange={handleChange}
              />
              <div className="invalid-feedback">Please fill out this field.</div>
            </div>
          </div>
        </div>
        <div className="roundBottomButton">
          <button
            type="button"
            className={'btn agiaBlueButton ' + (!state.isFormValid && 'disabled')}
            onClick={handleSubmit}
          >
            Submit
          </button>
          <Link to="/">Back to login</Link>
        </div>
      </div>
    </div>
  );
};
