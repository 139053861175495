import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';

import Notification from 'components/common/Notification';
import { PAGE_TITLES } from 'constants/constants';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { AxiosError } from 'interfaces/interfaces';
import sharedService from 'services/sharedService';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';

const Unsubscribe = () => {
  const location = useLocation();
  const params = useParams();
  const [pageTitle, setPageTitle] = useState<string>('');

  const { setShowNavigationBar } = useContext(GlobalContext) as GlobalContextType;

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { processMessages, notificationsData } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
    notificationsData: state.notificationsData,
  }));

  useEffect(() => {
    setPageTitle(initData?.clientName + PAGE_TITLES.UNSUBSCRIBE_EMAILS);
  }, [initData?.clientName]);

  useEffect(() => {
    if (location.pathname.includes('/unsubscribe/')) {
      setShowNavigationBar(false);
    }

    return () => {
      setShowNavigationBar(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleUnsubscribe = async () => {
      try {
        setAppLoading(true);
        const response = await sharedService.unsubscribe(params.token as string);
        processMessages(response.data.messages);
      } catch (error) {
        console.error(error);
        const axiosError = error as AxiosError;
        processMessages(axiosError?.response?.data?.messages);
      } finally {
        setAppLoading(false);
      }
    };
    handleUnsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {
            //   this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.UNSUBSCRIBE_EMAILS] ||
            pageTitle
          }
        </title>
      </Helmet>
      <div className="innerBodyWrapper">
        {notificationsData.nonInfoNotificaiton.map((notification, index) => (
          <Notification key={index} id={index} message={notification} />
        ))}
      </div>
    </>
  );
};

export default Unsubscribe;
