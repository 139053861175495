import { NOTIFICATIONS_TYPE } from 'constants/constants';
import { Notification as INotification } from 'interfaces/interfaces';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';

interface NotificationProps {
  id: number;
  message: INotification;
}

const Notification = ({ id, message }: NotificationProps) => {
  const { notificationsData, setInfoNotification, setPopupNotifications } = useNotificationsStore(
    (state: NotificationsStore) => ({
      setInfoNotification: state.setInfoNotification,
      notificationsData: state.notificationsData,
      setPopupNotifications: state.setPopupNotifications,
    }),
  );

  const checkAutoCloseNotification = () => {
    return message.type === 'INFO';
  };

  const handleClose = () => {
    const index = id;
    const notificationsInfo = Object.assign([], notificationsData.infoNotification);
    const notificationsInfoPopup = Object.assign([], notificationsData.popupNotifications);

    if (notificationsInfo.length > 0) notificationsInfo.splice(index, 1);
    if (notificationsInfoPopup.length > 0) notificationsInfoPopup.splice(index, 1);

    setInfoNotification(notificationsInfo);
    setPopupNotifications(notificationsInfoPopup);
  };

  return (
    <div className={'notification-box ' + NOTIFICATIONS_TYPE[message.type as keyof typeof NOTIFICATIONS_TYPE]}>
      {checkAutoCloseNotification() ? (
        <button className="notification-close materialicons" onClick={handleClose}>
          close
        </button>
      ) : null}

      <span
        className="message"
        dangerouslySetInnerHTML={{ __html: message.content ? message.content.join('\n') : '' }}
      ></span>
    </div>
  );
};

export default Notification;
