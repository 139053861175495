import { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  browserName,
  browserVersion,
  isMobile,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from 'react-device-detect';

import './styles.scss';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { PAGE_TITLES, ARTICLES } from 'constants/constants';
import { LOCALIZATION } from 'constants/localization';
import sharedService from 'services/sharedService';
import { getCookie } from 'services/cookieService';
import { AxiosError, TitleContentPair } from 'interfaces/interfaces';
import authService from 'services/authService';
import { UserStore, useUserStore } from 'stores/useUserStore';
import Notification from '../common/Notification';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';

interface State {
  username: string;
  password: string;
  isFormValid: boolean;
  hidePassword: boolean;
  rememberMe: boolean;
  enableActions: boolean;
  globalWebContents: Record<string, string>;
  pageTitle: string;
}

const Login = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState<State>({
    username: '',
    password: '',
    isFormValid: false,
    hidePassword: true,
    rememberMe: false,
    enableActions: false,
    globalWebContents: {},
    pageTitle: '',
  });

  const { globalContextState } = useContext(GlobalContext) as GlobalContextType;

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { setUser } = useUserStore((state: UserStore) => ({
    setUser: state.setUser,
  }));

  const { processMessages, notificationsData, setInterScreenNotification } = useNotificationsStore(
    (state: NotificationsStore) => ({
      processMessages: state.processMessages,
      notificationsData: state.notificationsData,
      setInterScreenNotification: state.setInterScreenNotification,
    }),
  );

  const rememberedUsername = localStorage.getItem('rememberedUsername');

  useEffect(() => {
    const getInsproData = async () => {
      try {
        const response = await sharedService.insproService();
        if (response.status === 200) {
          setState((prevState) => ({
            ...prevState,
            enableActions: response.data.insproAvailableInd,
            registerAttempt: getCookie('Attempt') === '' ? 0 : parseInt(getCookie('Attempt')),
          }));
        }
        processMessages(response.data.messages);
      } catch (error) {
        console.error(error);
      }
    };
    getInsproData();
  }, [processMessages]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, pageTitle: initData?.clientName + PAGE_TITLES.LOGIN }));

    setTimeout(() => {
      if (notificationsData?.interScreenNotification?.length) {
        processMessages(notificationsData?.interScreenNotification);
        setInterScreenNotification([]);
      }
    }, 100);

    if (params.token) {
      setUser(JSON.parse(atob(params.token)));
      navigate('/dashboard');
      window.location.reload();
    }

    if (rememberedUsername) {
      setState((prevState) => ({ ...prevState, username: rememberedUsername, rememberMe: true }));
    }
  }, [
    initData,
    params,
    navigate,
    rememberedUsername,
    setUser,
    processMessages,
    setInterScreenNotification,
    notificationsData.interScreenNotification,
  ]);

  useEffect(() => {
    const getContent = async (titles: string[]) => {
      const params = {
        titles: titles,
      };
      try {
        const response = await sharedService.getContents(params);
        if (response.status === 200) {
          const webContents: { [key: string]: string } = {};
          response?.data?.items?.forEach((element: TitleContentPair) => {
            webContents[element.title] = element.content;
          });
          setState((prevState) => ({ ...prevState, globalWebContents: webContents }));
        }
      } catch (error) {
        console.error(error);
      }
    };
    getContent([ARTICLES.LOGIN_FORM_REG_INFO, ARTICLES.MYACCOUNT_WELCOME]);
  }, []);

  useEffect(() => {
    const hideWelcomeTxt = JSON.parse(localStorage.getItem('hideWelcomeTxt') as string);
    const hasContent = state.globalWebContents[ARTICLES.MYACCOUNT_WELCOME];
    if (!hideWelcomeTxt && hasContent) {
      const infoNotifications = notificationsData.infoNotification;
      const customInfo = [
        {
          type: 'INFO',
          content: [state.globalWebContents[ARTICLES.MYACCOUNT_WELCOME]],
        },
      ];
      const result = infoNotifications.concat(customInfo);
      processMessages(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processMessages, state.globalWebContents]);

  const login = async () => {
    if (validate()) {
      const { client } = initData ?? {};
      const params = {
        username: state.username,
        password: state.password,
        client: client as string,
        isCustomerUser: false,
      };
      setAppLoading(true);
      try {
        const response = await authService.login(params);
        if (response.status === 200) {
          if (response.data.isMigratedUser === 'N') {
            navigate('/reactivate-account', { state: { email: state.username } });
            return;
          }
          if (response.data.access_token) {
            setUser(response.data);

            const ipResponse = await authService.getPublicIP();
            const params = {
              username: state.username,
              isCustomerUser: false,
              client: client as string,
              clientLoginIp: ipResponse.data?.ip,
              userDeviceInfo: {
                userOS: osName,
                userOSVersion: osVersion,
                browserName: browserName,
                browserVersion: browserVersion,
                isMobile: isMobile ? 'Y' : 'N',
                mobileModel: mobileModel,
                mobileVendor: mobileVendor,
              },
            };
            try {
              const response = await authService.updateUserInformation(params);
              if (response.status !== 403) {
                const { rememberMe, username } = state;
                if (rememberMe) {
                  localStorage.setItem('rememberedUsername', username);
                } else {
                  localStorage.removeItem('rememberedUsername');
                }
                localStorage.setItem('hideWelcomeTxt', 'true');
                navigate('/dashboard');
              }
            } catch (error) {
              console.error(error);
            }
          }
        } else {
          processMessages(response.data.messages);
        }
      } catch (error) {
        console.error(error);
        const axiosError = error as AxiosError;
        processMessages(axiosError?.response?.data?.messages);
      } finally {
        setAppLoading(false);
      }
    }
  };

  const validate = useCallback(() => {
    if (/^\s*$/.test(state.username)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    if (/^\s*$/.test(state.password)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    setState((prevState) => ({ ...prevState, isFormValid: true }));
    return true;
  }, [state.username, state.password]);

  useEffect(() => {
    validate();
  }, [state.username, state.password, validate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const handleRememberMe = () => {
    setState((prevState) => ({ ...prevState, rememberMe: !prevState.rememberMe }));
  };

  return (
    <>
      <Helmet>
        <title>
          {
            // this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.LOGIN] ||
            state.pageTitle
          }
        </title>
      </Helmet>
      {globalContextState.deviceWidth >= 768 && (
        <>
          {(!!notificationsData.infoNotification.length || !!notificationsData.nonInfoNotificaiton.length) && (
            <div className="desktopView loginNotification">
              {notificationsData.infoNotification.map((notification, index) => (
                <Notification key={index} id={index} message={notification} />
              ))}
              {notificationsData.nonInfoNotificaiton.map((notification, index) => (
                <Notification key={index} id={index} message={notification} />
              ))}
            </div>
          )}
        </>
      )}
      <div className="loginWrapper">
        <div className="loginWrapperInner">
          <div className="loginBodyRight">
            {globalContextState.deviceWidth <= 767 && (
              <>
                {(!!notificationsData.infoNotification.length || !!notificationsData.nonInfoNotificaiton.length) && (
                  <div className="mobileView loginNotification">
                    {notificationsData.infoNotification.map((notification, index) => (
                      <Notification key={index} id={index} message={notification} />
                    ))}
                    {notificationsData.nonInfoNotificaiton.map((notification, index) => (
                      <Notification key={index} id={index} message={notification} />
                    ))}
                  </div>
                )}
              </>
            )}
            <div className="loginColumn">
              <form action="/" className="was-validation" noValidate>
                <h1 className="desktopView commonHeadText">{LOCALIZATION.EN.LOGIN_TITLE}</h1>
                <h1 className="mobileView mainHeadingT text-center">{LOCALIZATION.EN.LOGIN_TITLE}</h1>
                <div className="form-group">
                  <div className="username">
                    <label htmlFor="user-email">Username</label>
                    <input
                      className="form-control"
                      onKeyDown={handleKeyDown}
                      type="text"
                      name="username"
                      value={state.username}
                      onChange={handleChange}
                      // disabled={!state.enableActions}
                    />
                    <div className="invalid-feedback">Please fill out this field.</div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="password">
                    <label htmlFor="user-password">Password</label>
                    <input
                      className="form-control password"
                      type={state.hidePassword ? 'password' : 'text'}
                      name="password"
                      onKeyDown={handleKeyDown}
                      onChange={handleChange}
                      // disabled={!state.enableActions}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setState((prevState) => ({ ...prevState, hidePassword: !prevState.hidePassword }));
                      }}
                      className="showHide"
                    >
                      <i className={'far ' + (state.hidePassword ? 'fa-eye' : 'fa-eye-slash')}></i>
                    </button>
                    <div className="invalid-feedback">Please fill out this field.</div>
                  </div>
                </div>
                <div className="checkRemember">
                  <input
                    type="checkbox"
                    checked={state.rememberMe}
                    onChange={handleRememberMe}
                    id="rememberCheck"
                    name="Remember me"
                  />
                  <span className="material-icons blankCheckbox">check_box_outline_blank</span>
                  <span className="material-icons selectedCheckbox">check_box</span>
                  <label className="rememberCheck" htmlFor="rememberCheck">
                    Remember my username
                  </label>
                </div>
                <div className="roundBottomButton">
                  <button
                    id="ma-lg-login"
                    className={
                      'btn btn-primary agiaBlueButton mt-3 btn-width-110 ' + (!state.isFormValid && 'disabled')
                    }
                    type="button"
                    value="Log In"
                    onClick={login}
                    // disabled={!state.enableActions}
                  >
                    Log In
                  </button>
                </div>
                <div className="forgotPwd bottomLinks row">
                  <div className="col-md-6 col-sm-6 col-xs-6 col-6 float-left pl-0 pr-0">
                    <Link
                      title={''}
                      to="/forgot-username"
                      onClick={(e) => {
                        if (!state.enableActions) {
                          e.preventDefault();
                        } else {
                          localStorage.setItem('hideWelcomeTxt', 'true');
                        }
                      }}
                    >
                      Forgot username?
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-6 col-6 float-left pl-0 pr-0">
                    <Link
                      title={''}
                      to="/forgot-password"
                      onClick={(e) => {
                        if (!state.enableActions) {
                          e.preventDefault();
                        } else {
                          localStorage.setItem('hideWelcomeTxt', 'true');
                        }
                      }}
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
              </form>
            </div>
            <div className="registerWrapperMain">
              <h2 className="loggedInText">First time logging in?</h2>
              <div className="roundBottomButton">
                <button
                  id="ma-ra-startRegister"
                  onClick={() => {
                    navigate('/account-details');
                  }}
                  className={'btn register-btn agiaBlueButton-outline btn-width-110 '}
                  type="button"
                  value="Submit"
                  disabled={!state.enableActions}
                >
                  Register for your online account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
