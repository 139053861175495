import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import './styles.scss';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { ARTICLES, PAGE_TITLES } from 'constants/constants';
import sharedService from 'services/sharedService';
import { Notification as INotification, PaymentMethod, TitleContentPair } from 'interfaces/interfaces';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { PaymentCenterStore, usePaymentCenterStore } from 'stores/usePaymentCenterStore';
import Notification from 'components/common/Notification';
import PaymentMethodModal from 'components/PaymentMethodModal';
import DeletePaymentMethodModal from 'components/DeletePaymentMethodModal';

interface State {
  globalWebContents: Record<string, string>;
  pageTitle: string;
  selectedPaymentMethod: PaymentMethod | null;
}

const PaymentMethods = () => {
  const [state, setState] = useState<State>({
    globalWebContents: {},
    pageTitle: '',
    selectedPaymentMethod: null,
  });
  const [showPaymentMethodPopup, setShowPaymentMethodPopup] = useState<boolean>(false);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState<boolean>(false);

  const { initData } = useInitStore((state: InitStore) => ({
    initData: state.initData,
  }));

  const { processMessages, notificationsData } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
    notificationsData: state.notificationsData,
  }));

  const { setPaymentMethods, paymentMethods } = usePaymentCenterStore((state: PaymentCenterStore) => ({
    setPaymentMethods: state.setPaymentMethods,
    paymentMethods: state.paymentMethods,
  }));

  useEffect(() => {
    setState((prevState) => ({ ...prevState, pageTitle: initData?.clientName + PAGE_TITLES.PAYMENT_METHODS }));
  }, [initData?.clientName]);

  useEffect(() => {
    const getContent = async (titles: string[]) => {
      const params = {
        titles: titles,
      };
      try {
        const response = await sharedService.getContents(params);
        if (response.status === 200) {
          const webContents: { [key: string]: string } = {};
          response?.data?.items?.forEach((element: TitleContentPair) => {
            webContents[element.title] = element.content;
          });
          setState((prevState) => ({ ...prevState, globalWebContents: webContents }));
        }
      } catch (error) {
        console.error(error);
      }
    };
    getContent([ARTICLES.CONFIRM_DELETE_PAYMENT_METHOD]);
  }, []);

  const handlePaymentMethodPopupClose = (data?: PaymentMethod[], messages?: INotification[]) => {
    setShowPaymentMethodPopup(false);

    if (data && messages) {
      processMessages(messages);
      setPaymentMethods(data);
      window.scrollTo(0, 0);
    }
  };

  const handleConfirmDeletePopupClose = (data?: PaymentMethod[] | null, messages?: INotification[]) => {
    setShowConfirmDeletePopup(false);

    if (data && messages) {
      processMessages(messages);
      setPaymentMethods(data);
    } else if (messages) {
      processMessages(messages);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {
            // this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.PAYMENT_METHODS] ||
            state.pageTitle
          }
        </title>
      </Helmet>
      <div>
        <div>
          {notificationsData.nonInfoNotificaiton.map((notification, index) => (
            <Notification key={index} id={index} message={notification} />
          ))}
          <div className="paymentMWrapper">
            <div className="flexWhiteBox">
              {paymentMethods.map((value) => (
                <div key={value.paymentRecordId} className="leftColumn">
                  <div className="headerColumn">
                    <h3>{value.paymentMethodHeader}</h3>
                    <div className="headerButton">
                      <Link
                        title={''}
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          if (value.managePaymentMethodOption.deletePaymentMethod.disable === 'N') {
                            setState((prevState) => ({ ...prevState, selectedPaymentMethod: value }));
                            setShowConfirmDeletePopup(true);
                          }
                        }}
                      >
                        <span
                          className={
                            value.managePaymentMethodOption.deletePaymentMethod.disable === 'Y'
                              ? 'disabled-link-btn'
                              : ''
                          }
                        >
                          Delete
                        </span>
                      </Link>
                      <Link
                        title={''}
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          if (value.managePaymentMethodOption.editPaymentMethod.disable === 'N') {
                            setState((prevState) => ({ ...prevState, selectedPaymentMethod: value }));
                            setShowPaymentMethodPopup(true);
                          }
                        }}
                      >
                        <span
                          className={
                            value.managePaymentMethodOption.editPaymentMethod.disable === 'Y' ? 'disabled-link-btn' : ''
                          }
                        >
                          Edit
                        </span>
                      </Link>
                    </div>
                  </div>
                  {!value.bankAccount && (
                    <div className="bodyRow">
                      <div className="flexBox">
                        <ul>
                          <li>
                            <span className="label">PAYMENT TYPE</span>
                            <span className="value">{value.paymentMethodHeader}</span>
                          </li>
                          <li>
                            <span className="label">CARDHOLDER NAME</span>
                            <span className="value single-line-ellipses" title={value.cardHolderName}>
                              {value.cardHolderName}
                            </span>
                          </li>
                          <li>
                            <span className="label">CARD NUMBER</span>
                            <span className="value">{value.creditCard.cardNumber}</span>
                          </li>
                          <li>
                            <span className="label">CARD EXPIRATION</span>
                            <span className="value">{value.creditCard.expirationDate}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}

                  {!value.creditCard && (
                    <div className="bodyRow">
                      <div className="flexBox">
                        <ul>
                          <li>
                            <span className="label">PAYMENT TYPE</span>
                            <span className="value">{value.paymentMethodHeader}</span>
                          </li>
                          <li>
                            <span className="label">ACCOUNT-HOLDER NAME</span>
                            <span className="value">{value.cardHolderName}</span>
                          </li>
                          <li>
                            <span className="label">ACCOUNT NUMBER</span>
                            <span className="value">{value.bankAccount.accountNumber}</span>
                          </li>
                          <li>
                            <span className="label">ROUTING NUMBER</span>
                            <span className="value">{value.bankAccount.maskedRoutingNumber}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div className="addPaymentM leftColumn dotted-spaced">
                <Link
                  title={''}
                  className="addPaymentButton"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setState((prevState) => ({ ...prevState, selectedPaymentMethod: null }));
                    setShowPaymentMethodPopup(true);
                  }}
                >
                  <div className="materialicons">add</div>
                  <div className="navText">Add Payment Method</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {showPaymentMethodPopup && (
          <PaymentMethodModal
            selectedPaymentMethod={state.selectedPaymentMethod}
            show={showPaymentMethodPopup}
            hide={handlePaymentMethodPopupClose}
          />
        )}
        {showConfirmDeletePopup && (
          <DeletePaymentMethodModal
            content={state.globalWebContents[ARTICLES.CONFIRM_DELETE_PAYMENT_METHOD]}
            selectedPaymentMethod={state.selectedPaymentMethod}
            show={showConfirmDeletePopup}
            hide={handleConfirmDeletePopupClose}
          />
        )}
      </div>
    </>
  );
};

export default PaymentMethods;
