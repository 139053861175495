import { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.scss';
import Notification from 'components/common/Notification';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import {
  AxiosError,
  PaymentMethod,
  Product,
  TitleContentPair,
  Notification as INotification,
  PaymentInfo,
} from 'interfaces/interfaces';
import { ARTICLES, MONTHS_LONG } from 'constants/constants';
import { InitStore, useInitStore } from 'stores/useInitStore';
import sharedService from 'services/sharedService';
import { UserStore, useUserStore } from 'stores/useUserStore';
import paymentCenterService from 'services/paymentCenterService';
import PaymentMethodModal from 'components/PaymentMethodModal';
import ProductsDesktop from './ProductsDesktop';
import ProductsMobile from './ProductsMobile';
import { PaymentCenterStore, usePaymentCenterStore } from 'stores/usePaymentCenterStore';
import { convertStringToAmount } from 'utils/utils';
import ChangeFrequencyAutopayModal from './ChangeFrequencyAutopayModal';
import ConfirmModal from './ConfirmModal';

interface State {
  products: Product[];
  paymentMethods: (PaymentMethod | PaymentMethodOptions)[];
  hasAnyPaymentMethods: boolean;
  clientName: string;
  paymentInfo: PaymentInfo | null;
  selectedProduct: Product | null;
  selectedPaymentMethod: string | null;
  globalWebContents: Record<string, string>;
  confirmPopup: {
    title: string;
    content: string;
    positiveBtnText: string;
    negativeBtnText: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeFrequencyParam?: any;
}

export interface PaymentMethodOptions {
  paymentRecordId: string;
  paymentMethodHeader: string;
}

const PaymentCenterProducts = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<State>({
    products: [],
    paymentMethods: [],
    hasAnyPaymentMethods: false,
    clientName: '',
    paymentInfo: null,
    selectedProduct: null,
    selectedPaymentMethod: null,
    globalWebContents: {},
    confirmPopup: {
      title: 'This is title',
      content: 'This is content',
      positiveBtnText: 'I Agree',
      negativeBtnText: 'I Do Not Agree',
    },
  });

  const [showAutopayOrChangeFrequencyPopup, setShowAutopayOrChangeFrequencyPopup] = useState<boolean>(false);
  const [showPaymentMethodPopup, setShowPaymentMethodPopup] = useState<boolean>(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);

  const { globalContextState } = useContext(GlobalContext) as GlobalContextType;

  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { processMessages, notificationsData, setPopupNotifications } = useNotificationsStore(
    (state: NotificationsStore) => ({
      processMessages: state.processMessages,
      notificationsData: state.notificationsData,
      setPopupNotifications: state.setPopupNotifications,
    }),
  );

  const { storePaymentMethods, setPaymentMethods } = usePaymentCenterStore((store: PaymentCenterStore) => ({
    storePaymentMethods: store.paymentMethods,
    setPaymentMethods: store.setPaymentMethods,
  }));

  useEffect(() => {
    const getContent = async (titles: string[]) => {
      const params = {
        titles: titles,
      };
      try {
        const response = await sharedService.getContents(params);
        if (response.status === 200) {
          const webContents: { [key: string]: string } = {};
          response?.data?.items?.forEach((element: TitleContentPair) => {
            webContents[element.title] = element.content;
          });
          setState((prevState) => ({ ...prevState, globalWebContents: webContents }));
        }
      } catch (error) {
        console.error(error);
      }
    };
    getContent([
      ARTICLES.CLAIM_NOW,
      ARTICLES.PRODUCT_DISCLAIMER,
      ARTICLES.PRODUCTS_NO_RADIO,
      ARTICLES.PRODUCTS_AUTO_TOOLTIP,
      ARTICLES.REMOVE_AUTOPAY_CONFIRM,
      ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING,
      ARTICLES.PRODUCT_DISCLAIMER_NOCOST,
      ARTICLES.SITE_DISCLAIMER,
      ARTICLES.AUTOPAY_INSURANCE,
      ARTICLES.AUTOPAY_NON_INSURANCE,
      ARTICLES.AUTOPAY_INSURANCE_NOBILL,
      ARTICLES.FREQUENCY_UPDATED,
      ARTICLES.FREQUENCY_UPDATED_NOBILL,
      ARTICLES.AUTOPAY_MARKETTING,
      ARTICLES.TRANSACTION_DISCLAIMER,
      ARTICLES.TOOLTIP_DUE_FREQUENCY,
      ARTICLES.TOOLTIP_FREQUENCY_NO_SELECTION,
    ]);
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      const params = {
        client: initData?.client,
        accountNumber: user?.accountNumber,
        policyNumber: user?.policyNumber,
        firstName: user?.firstName,
        lastName: user?.lastName,
        userid: user?.userid,
        isEmailSubscribed: user?.isEmailSubscribed,
      } as Record<string, string>;
      try {
        setAppLoading(true);
        const response = await paymentCenterService.getProducts(params);
        if (response.status === 200) {
          setState((prevState) => ({
            ...prevState,
            products: response.data.activeProducts || [],
            clientName: response.data.clientName,
          }));
        } else {
          setState((prevState) => ({ ...prevState, products: [], selectedProduct: null }));
        }
        processMessages(response.data.messages);
      } catch (error) {
        console.error(error);
        const axiosError = error as AxiosError;
        setState((prevState) => ({ ...prevState, products: [], selectedProduct: null }));
        processMessages(axiosError?.response?.data?.messages);
      } finally {
        setAppLoading(false);
      }
    };
    getProducts();
  }, [
    initData?.client,
    processMessages,
    setAppLoading,
    user?.accountNumber,
    user?.firstName,
    user?.lastName,
    user?.policyNumber,
    user?.userid,
    user?.isEmailSubscribed,
  ]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      paymentMethods: storePaymentMethods,
      hasAnyPaymentMethods: !!storePaymentMethods.length,
    }));
  }, [storePaymentMethods]);

  const radioBtnChange = (selectedProduct: Product) => {
    setState((prevState) => ({
      ...prevState,
      selectedProduct: selectedProduct,
      selectedPaymentMethod: identifySelectedPaymentMethod(selectedProduct),
    }));
  };

  const identifySelectedPaymentMethod = (selectedProduct: Product) => {
    const notOnAutoPay = selectedProduct.paymentStatusDisplayStyle !== 'autopay';

    if (notOnAutoPay) {
      return '00';
    } else if (!notOnAutoPay && state.hasAnyPaymentMethods) {
      return selectedProduct.currentPaymentMethodRecordId;
    } else {
      return 'ADD_NEW_PAYMENT_METHOD';
    }
  };

  const handlePayNow = (selectedProduct: Product | null) => {
    navigate('/payment-center/pay-now', {
      state: {
        selectedProduct: selectedProduct || state.selectedProduct,
        clientName: state.clientName,
        fromScreen: 'PRODUCTS',
      },
    });
  };

  const handleChangeFreqAutoPay = async (selectedProduct: Product) => {
    const params = {
      client: initData?.client,
      accountNumber: user?.accountNumber,
      policyNumber: selectedProduct.policyNumber,
      firstName: user?.firstName,
      lastName: user?.lastName,
      userid: user?.userid,
      isEmailSubscribed: user?.isEmailSubscribed,
      csUserid: '',
    } as Record<string, string>;
    try {
      setAppLoading(true);
      const response = await paymentCenterService.getUpdatedFrequency(params);
      if (response.status === 200) {
        setState((prevState) => ({
          ...prevState,
          selectedProduct: { ...selectedProduct, ...response.data.activeProducts[0] },
          paymentMethods: getModifiedPaymentMethods(response.data.activeProducts[0]),
          selectedPaymentMethod: identifySelectedPaymentMethod(response.data.activeProducts[0]),
        }));
        setShowAutopayOrChangeFrequencyPopup(true);
      } else {
        processMessages(response.data.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  };

  const getModifiedPaymentMethods = (selectedProduct: Product | null) => {
    let filteredPaymentMethods = state.paymentMethods;
    const enableOptions = selectedProduct?.paymentAction.saveChanges.disable === 'N';

    // Determine whether to add 'Remove Auto Pay' or 'Not on Auto Pay'
    if (selectedProduct?.paymentStatusDisplayStyle && selectedProduct.paymentStatusDisplayStyle === 'autopay') {
      const found = state.paymentMethods.some(
        (paymentMethod) => paymentMethod.paymentMethodHeader === 'Remove Auto Pay',
      );
      filteredPaymentMethods = filteredPaymentMethods.filter(
        (paymentMethod) => paymentMethod.paymentMethodHeader !== 'Not on Auto Pay',
      );
      if (!found && enableOptions) {
        filteredPaymentMethods.unshift({
          paymentRecordId: '00',
          paymentMethodHeader: 'Remove Auto Pay',
        });
      }
    } else {
      filteredPaymentMethods = filteredPaymentMethods.filter(
        (paymentMethod) => paymentMethod.paymentMethodHeader !== 'Remove Auto Pay',
      );
      const found = state.paymentMethods.some(
        (paymentMethod) => paymentMethod.paymentMethodHeader === 'Not on Auto Pay',
      );
      if (!found) {
        filteredPaymentMethods.unshift({
          paymentRecordId: '00',
          paymentMethodHeader: 'Not on Auto Pay',
        });
      }
    }

    // Add 'Add new payment method'
    const found = state.paymentMethods.some(
      (paymentMethod) => paymentMethod.paymentRecordId === 'ADD_NEW_PAYMENT_METHOD',
    );
    if (!found) {
      filteredPaymentMethods.push({
        paymentRecordId: 'ADD_NEW_PAYMENT_METHOD',
        paymentMethodHeader: 'Add new payment method',
      });
    }

    return filteredPaymentMethods;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeFrequencyAutopayClose = (data?: string, params?: any) => {
    if (data === 'ADD_NEW_PAYMENT_METHOD') {
      setShowPaymentMethodPopup(true);
      setShowAutopayOrChangeFrequencyPopup(false);
    } else if (data) {
      const type = getEmailType(params, state.selectedProduct as Product);
      setState((prevState) => ({
        ...prevState,
        confirmPopup: getConfirmPopupData(type, params) as typeof state.confirmPopup,
        changeFrequencyParam: params,
      }));
      setShowConfirmPopup(true);
      setShowAutopayOrChangeFrequencyPopup(false);
    } else {
      setShowAutopayOrChangeFrequencyPopup(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getEmailType = ({ paymentDetails }: any, product: Product) => {
    const { paymentMethodRecordId, billingMode } = paymentDetails[0];
    const isPaymentMethodUpdated = hasPaymentMethodUpdated(product, paymentMethodRecordId);

    // Remove AutoPay
    if (paymentMethodRecordId === '00' && product.paymentStatusDisplayStyle === 'autopay') {
      // if remove auto pay
      return 'remove';
    }

    // Both value get changed
    if (billingMode !== product.currentPaymentFrequecyCode && isPaymentMethodUpdated) {
      return 'both';
    }

    // From non auto pay to auto pay
    if (product.paymentStatusDisplayStyle !== 'autopay' && paymentMethodRecordId !== '00') {
      return product.billingFeeInd === 'Y' ? 'add-admin-fees' : 'add';
    }

    // Only frequency value get changed
    if (billingMode !== product.currentPaymentFrequecyCode && !isPaymentMethodUpdated) {
      return 'frequency';
    }

    // Only payment method value get changed
    if (billingMode === product.currentPaymentFrequecyCode && isPaymentMethodUpdated) {
      return 'payment-method';
    }

    // not change anything
    return 'none';
  };

  const hasPaymentMethodUpdated = (product: Product, paymentMethodRecordId: string) => {
    if (product.paymentStatusDisplayStyle === 'autopay') {
      // If on auto pay
      if (product.currentPaymentMethodRecordId !== paymentMethodRecordId) {
        // check if drop downs are changed
        return true;
      } else {
        return false;
      }
    } else {
      // if not on auto pay
      if (paymentMethodRecordId !== '00') {
        // if a valid payment method is selected instead of 'Not on Auto Pay'
        return true;
      } else {
        // if 'Not on Auto Pay' is selected, means payment method not updated
        return false;
      }
    }
  };

  const handleConfirmBoxClose = (flag: boolean) => {
    setShowConfirmPopup(false);
    if (flag) {
      submitChangeFrequency(state.changeFrequencyParam);
    } else {
      setShowAutopayOrChangeFrequencyPopup(true);
    }
  };

  const handlePaymentMethodPopupClose = (data?: PaymentMethod[], messages?: INotification[]) => {
    if (data) {
      if (messages) setPopupNotifications(messages);
      // this.getProducts();
      setPaymentMethods(data);
      setState((prevState) => ({
        ...prevState,
        paymentMethods: data,
        hasAnyPaymentMethods: !!data.length,
      }));

      if (state.selectedProduct) {
        setState((prevState) => ({
          ...prevState,
          selectedPaymentMethod: data[0].paymentRecordId,
          paymentMethods: getModifiedPaymentMethods(state.selectedProduct),
        }));
        setShowPaymentMethodPopup(false);
        setShowAutopayOrChangeFrequencyPopup(true);
      }
    } else {
      setShowPaymentMethodPopup(false);
      setShowAutopayOrChangeFrequencyPopup(true);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitChangeFrequency = async (params: any) => {
    try {
      setAppLoading(true);
      const response = await paymentCenterService.changeFrequencyAutopay(params);
      if (response.status === 200) {
        processMessages(response.data.messages);
        setState((prevState) => ({ ...prevState, products: response.data.activeProducts, selectedProduct: null }));
      } else {
        processMessages(response.data.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getConfirmPopupData = (type: string, params: any) => {
    if (type === 'add' || type === 'both' || type === 'payment-method' || type === 'add-admin-fees') {
      return {
        title: 'Payment Authorization',
        content: getAttestationContent(params),
        positiveBtnText: 'I Agree',
        negativeBtnText: 'I Do Not Agree',
      };
    }

    if (type === 'frequency') {
      return {
        title: 'Change Auto Pay or Frequency',
        content: getAttestationContentForFrequency(params),
        positiveBtnText: 'I Agree',
        negativeBtnText: 'Cancel Request',
      };
    }

    if (type === 'remove') {
      return {
        title: 'Confirm Remove Auto Pay',
        content: state.globalWebContents[ARTICLES.REMOVE_AUTOPAY_CONFIRM],
        positiveBtnText: 'I Agree',
        negativeBtnText: 'I Do Not Agree',
      };
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getAttestationContentForFrequency = (params: any) => {
    const product = state.selectedProduct;
    const isBillingFeeInd = product?.billingFeeInd === 'Y';
    let content = isBillingFeeInd
      ? state.globalWebContents[ARTICLES.FREQUENCY_UPDATED]
      : state.globalWebContents[ARTICLES.FREQUENCY_UPDATED_NOBILL];

    const paymentFreqObj = product?.availablePaymentFrequencyList.find(
      (item) => item.code === params.paymentDetails[0].billingMode,
    );

    const dataMap = [
      { field: '[$FREQUENCY$]', value: `${paymentFreqObj?.value1}` },
      { field: '[$OLD_FREQUENCY$]', value: `${product?.currentPaymentFrequecyType}` },
      { field: '[$AMOUNT_DUE$]', value: `${paymentFreqObj?.value2}` },
    ];

    dataMap.forEach((item) => {
      content = content.replaceAll(item.field, item.value);
    });

    return content;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getAttestationContent = (params: any) => {
    let content = '';
    const product = state.selectedProduct;
    const isInsuranceProduct = state.selectedProduct?.isInsuranceProduct === 'Y';
    const isBillingFeeInd = product?.billingFeeInd === 'Y';

    let dataMap, _dataMap;

    const paymentMethod = state.paymentMethods.find(
      (item) => item.paymentRecordId === params.paymentDetails[0].paymentMethodRecordId,
    );

    const paymentMethodType = (paymentMethod as PaymentMethod)?.creditCard ? 'creditCard' : 'bankAccount';

    const paymentFreqObj = product?.availablePaymentFrequencyList.find(
      (item) => item.code === params.paymentDetails[0].billingMode,
    );

    // Determine the type of web content
    if (isInsuranceProduct) {
      content = isBillingFeeInd
        ? state.globalWebContents[ARTICLES.AUTOPAY_INSURANCE]
        : state.globalWebContents[ARTICLES.AUTOPAY_INSURANCE_NOBILL];
    } else {
      content = state.globalWebContents[ARTICLES.AUTOPAY_NON_INSURANCE];
    }

    // Determine financial institution
    const accountStr = (paymentMethod as PaymentMethod)?.creditCard ? '' : ' account';
    const financialInstitutionName = (paymentMethod as PaymentMethod)[paymentMethodType]
      ? (paymentMethod as PaymentMethod)[paymentMethodType].financialInstitutionName + accountStr
      : '';

    // Define the common varaibles
    dataMap = [
      {
        field: '[$CURRENT_DATE$]',
        value: `${MONTHS_LONG[new Date().getMonth()]} ${new Date().getDate()}, ${new Date().getFullYear()}`,
      }, // Month DD, YYYY
      { field: '[$FULL_NAME$]', value: `${user?.firstName} ${user?.lastName}` },
      { field: '[$PRODUCT_NAME$]', value: `${product?.productDesc}` },
      { field: '[$CLIENT_NAME$]', value: state.clientName },
      { field: '[$TOLL_FREE$]', value: initData?.clientPhoneNumber },
      { field: '[$USER_NAME$]', value: `${user?.firstName} ${user?.lastName}` },
      { field: '[$DUE_DATE$]', value: `${product?.dueDate}` },
      { field: '[$FREQUENCY$]', value: `${paymentFreqObj?.value1}` },
      { field: '[$OLD_FREQUENCY$]', value: `${product?.currentPaymentFrequecyType}` },
      { field: '[$FIN_INSTITUTION$]', value: `${financialInstitutionName}` },
      {
        field: '[$ACCOUNT_NUM$]',
        value: `${(paymentMethod as PaymentMethod)[paymentMethodType]?.accountNumberLast4Digit}`,
      },
    ];

    // Define the dynamic and unique variables
    if (isInsuranceProduct && isBillingFeeInd) {
      const billingFeeObj = product?.billingFees?.find(
        (item) => item.code === (paymentMethod as PaymentMethod)?.billingMethodCode,
      );
      const premium = convertStringToAmount(paymentFreqObj?.value2 as string);
      const billFee = convertStringToAmount(billingFeeObj?.value2 as string);

      _dataMap = [
        { field: '[$AMOUNT_DUE$]', value: `$${(premium + billFee).toFixed(2)}` },
        { field: '[$PREMIUM$]', value: `${paymentFreqObj?.value2}` },
        { field: '[$BILLING_FEE$]', value: `${billingFeeObj?.value2}` },
      ];
    } else {
      _dataMap = [{ field: '[$AMOUNT_DUE$]', value: `${paymentFreqObj?.value2}` }];
    }

    dataMap = dataMap.concat(_dataMap);
    dataMap.forEach((item) => {
      content = content.replaceAll(item.field, item.value as string);
    });

    return content;
  };

  const getPaymentInfo = async (policyNumber: string) => {
    const params = {
      client: initData?.client,
      accountNumber: user?.accountNumber,
      policyNumber: policyNumber,
      firstName: user?.firstName,
      lastName: user?.lastName,
      userid: user?.userid,
      isEmailSubscribed: user?.isEmailSubscribed,
      csUserid: '',
    } as Record<string, string>;
    try {
      setAppLoading(true);
      const response = await paymentCenterService.getPaymentInfo(params);
      if (response.status === 200) {
        const updatedProducts = state.products.map((product) => {
          if (product.policyNumber === policyNumber) {
            return { ...product, paymentInfo: response.data.paymentInfo, paymentInfoLoaded: true };
          } else {
            return product;
          }
        });
        setState((prevState) => ({ ...prevState, products: updatedProducts }));
      } else {
        processMessages(response.data.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  };

  const getDisclaimers = () => {
    const disclaimerTypesMap = [
      { name: 'autopay', content: state.globalWebContents[ARTICLES.PRODUCT_DISCLAIMER] },
      { name: 'combinedbill', content: state.globalWebContents[ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING] },
      { name: 'nocosttoyou', content: state.globalWebContents[ARTICLES.PRODUCT_DISCLAIMER_NOCOST] },
    ];
    const components = [] as ReactElement[];
    const iterated: string[] = [];
    state.products.forEach((product, index) => {
      const disclaimerObj = disclaimerTypesMap.find((item) => item.name === product.paymentStatusDisplayStyle);
      const notIterated = !iterated.includes(product.paymentStatusDisplayStyle);

      if (disclaimerObj && notIterated) {
        components.push(<div key={index} dangerouslySetInnerHTML={{ __html: disclaimerObj.content }}></div>);
        iterated.push(product.paymentStatusDisplayStyle);
      }
    });

    return components;
  };

  return (
    <>
      <div className="payment-center-products">
        {notificationsData.nonInfoNotificaiton.map((notification, index) => (
          <Notification key={index} id={index} message={notification} />
        ))}
        {!!state.products.length && (
          <>
            {globalContextState.deviceWidth >= 768 && (
              <ProductsDesktop
                products={state.products}
                radioBtnChange={radioBtnChange}
                selectedProduct={state.selectedProduct}
                handleChangeFreqAutoPay={handleChangeFreqAutoPay}
                handlePayNow={handlePayNow}
                globalWebContents={state.globalWebContents}
                getPaymentInfo={getPaymentInfo}
                getDisclaimers={getDisclaimers}
              />
            )}
            {globalContextState.deviceWidth <= 767 && (
              <ProductsMobile
                products={state.products}
                handleChangeFreqAutoPay={handleChangeFreqAutoPay}
                handlePayNow={handlePayNow}
                globalWebContents={state.globalWebContents}
                getPaymentInfo={getPaymentInfo}
                getDisclaimers={getDisclaimers}
              />
            )}
          </>
        )}
      </div>
      {showPaymentMethodPopup && (
        <PaymentMethodModal
          selectedPaymentMethod={null}
          show={showPaymentMethodPopup}
          hide={handlePaymentMethodPopupClose}
        />
      )}
      {showAutopayOrChangeFrequencyPopup && state.selectedProduct && (
        <ChangeFrequencyAutopayModal
          product={state.selectedProduct}
          paymentMethods={state.paymentMethods as PaymentMethod[]}
          selectedPaymentFrequecy={state.selectedProduct.currentPaymentFrequecyCode}
          selectedPaymentMethod={state.selectedPaymentMethod}
          show={showAutopayOrChangeFrequencyPopup}
          hide={handleChangeFrequencyAutopayClose}
        />
      )}
      {showConfirmPopup && (
        <ConfirmModal
          show={showConfirmPopup}
          hide={handleConfirmBoxClose}
          title={state.confirmPopup.title}
          content={state.confirmPopup.content}
          positiveBtnText={state.confirmPopup.positiveBtnText}
          negativeBtnText={state.confirmPopup.negativeBtnText}
        />
      )}
    </>
  );
};

export default PaymentCenterProducts;
