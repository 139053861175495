import { useContext } from 'react';

import { CARDS_TYPE, MONTHS } from 'constants/constants';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { CreditCardForm as ICreditCardForm } from 'components/PaymentMethodModal';
import { numberAndDash, getCardYears, formatCard } from 'utils/utils';

interface CreditCardFormProps {
  form: ICreditCardForm;
  inputChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const CreditCardForm = ({ form, inputChange, handleKeyDown }: CreditCardFormProps) => {
  const { globalContextState } = useContext(GlobalContext) as GlobalContextType;

  const years = getCardYears();

  const formatCardNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isValid = numberAndDash(value);
    if (isValid) {
      const formattedNum = formatCard(value);
      e.target.value = formattedNum;
      inputChange(e);
    }
  };

  const checkZip = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isValid = numberAndDash(value);
    if (isValid) {
      inputChange(e);
    }
  };

  return (
    <div className="formPanel mainPopupWraper">
      <div className="leftPanel">
        <h2 className="bInfoText">Payment Information</h2>
        <div className="form-group">
          <div className="cardType">
            <label htmlFor="cardType">Card Type</label>
            <select
              disabled={form.editMode}
              value={form.selectedCardType}
              onChange={inputChange}
              name="selectedCardType"
            >
              <option value="">Select</option>
              {CARDS_TYPE.map((value) => (
                <option key={value.id} value={value.id}>
                  {value.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-group">
          <div className="nameOnCard ">
            <label htmlFor="nameoncard">Cardholder Name</label>
            <input
              className="form-control"
              type="text"
              name="cardHolderName"
              value={form.cardHolderName}
              onKeyDown={handleKeyDown}
              onChange={inputChange}
              maxLength={30}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="ccNum">
            <label htmlFor="ccNum">Credit Card Number</label>
            <input
              disabled={form.editMode}
              className="form-control"
              type="text"
              name="cardNumber"
              value={form.cardNumber}
              onKeyDown={handleKeyDown}
              onChange={formatCardNumber}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="state">
            <label htmlFor="expdate">Exp Date</label>
            <select value={form.expMonth} onChange={inputChange} name="expMonth">
              <option value="">Select</option>
              {MONTHS.map((value) => (
                <option key={value.id} value={value.id}>
                  {value.name}
                </option>
              ))}
            </select>
          </div>

          <div className="zipCode">
            <select value={form.expYear} onChange={inputChange} name="expYear">
              <option value="">Select Year</option>
              {years.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="rightPanel">
        <h2 className="bInfoText">Billing Information</h2>
        <div className="form-group">
          <div className="address">
            <label htmlFor="address">Address 1</label>
            <input
              className="form-control"
              type="text"
              name="address1"
              value={form.address1}
              onChange={inputChange}
              onKeyDown={handleKeyDown}
              maxLength={30}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="address">
            <label htmlFor="address">Address 2</label>
            <input
              className="form-control"
              type="text"
              name="address2"
              value={form.address2}
              onChange={inputChange}
              onKeyDown={handleKeyDown}
              maxLength={30}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="address">
            <label htmlFor="address">Address 3</label>
            <input
              className="form-control"
              type="text"
              name="address3"
              value={form.address3}
              onChange={inputChange}
              onKeyDown={handleKeyDown}
              maxLength={30}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="city">
            <label htmlFor="city">City</label>
            <input
              className="form-control"
              type="text"
              name="city"
              value={form.city}
              onChange={inputChange}
              onKeyDown={handleKeyDown}
              maxLength={30}
            />
          </div>
        </div>

        <div className="form-group displayFlex">
          <div className="state">
            <label htmlFor="state">State</label>
            <select name="selectedState" value={form.selectedState} onChange={inputChange}>
              <option value="">Please Select</option>
              {globalContextState.states.map((value) => (
                <option key={value.stateCode} value={value.stateCode}>
                  {value.stateCode}
                </option>
              ))}
            </select>
          </div>

          <div className="zipCode">
            <label htmlFor="zipCode">Zip</label>
            <input
              className="form-control"
              type="text"
              name="zip"
              value={form.zip}
              onChange={checkZip}
              onKeyDown={handleKeyDown}
              maxLength={10}
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCardForm;
