import React, { useCallback, useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from 'react-router-dom';

import Notification from 'components/common/Notification';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { UpdateSecurityDetailsParams } from 'interfaces/apiRequestInterfaces';
import profileService from 'services/profileService';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { UserStore, useUserStore } from 'stores/useUserStore';
import { AxiosError, Notification as NotificationType } from 'interfaces/interfaces';

interface Question {
  questionId: string;
  question: string;
}

interface ChangeSecurityQuestionPopupProps {
  show: boolean;
  hide: (data?: { selectedQuestion: string; messages: NotificationType[] }) => void;
  selectedQuestion: string;
  questions: Question[];
}

const ChangeSecurityQuestionPopup: React.FC<ChangeSecurityQuestionPopupProps> = ({
  show,
  hide,
  selectedQuestion: initialSelectedQuestion,
  questions,
}) => {
  const location = useLocation();
  const [selectedQuestion, setSelectedQuestion] = useState<string>(initialSelectedQuestion);
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const { triggerGTMDataLayer } = useContext(GlobalContext) as GlobalContextType;
  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));
  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));
  const { setPopupNotifications, notificationsData, resetPopupNotifications } = useNotificationsStore(
    (state: NotificationsStore) => ({
      setPopupNotifications: state.setPopupNotifications,
      notificationsData: state.notificationsData,
      resetPopupNotifications: state.resetPopupNotifications,
    }),
  );

  const checkRequiredFields = useCallback(() => {
    const isValid = selectedQuestion?.trim() !== '' && selectedAnswer?.trim() !== '';
    setIsFormValid(isValid);
  }, [selectedQuestion, selectedAnswer]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'selectedQuestion') {
      setSelectedQuestion(value);
    } else {
      setSelectedAnswer(value);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (!isFormValid) return;

    try {
      setAppLoading(true);
      const params: UpdateSecurityDetailsParams = {
        securityQuestion: selectedQuestion,
        securityAnswer: selectedAnswer,
        userName: user?.userid as string,
      };

      const response = await profileService.updateSecurityDetails(params);

      if (response.status === 200) {
        resetPopupNotifications();
        hide({
          messages: response.data.messages,
          selectedQuestion: selectedQuestion,
        });
      } else {
        setPopupNotifications(response.data.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      setPopupNotifications(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  };

  useEffect(() => {
    checkRequiredFields();
  }, [selectedQuestion, selectedAnswer, checkRequiredFields]);

  useEffect(() => {
    triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: '/change-security-question-answer',
      pageTitle: `${user?.csUserid ? 'CS:' : ''} ${initData?.clientName} - Change Security Q&A`,
    });

    return () => {
      triggerGTMDataLayer({
        event: 'MyAccountPageView',
        pagePath: window.location.pathname,
        pageTitle: `${user?.csUserid ? 'CS:' : ''} ${window.document.title}`,
      });
    };
  }, [initData?.clientName, triggerGTMDataLayer, location.pathname, user?.csUserid]);

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={() => {
        resetPopupNotifications();
        hide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Change Security Q&A</Modal.Title>
        {notificationsData?.popupNotifications?.map((notification, index) => (
          <Notification key={index} id={index} message={notification} />
        ))}
      </Modal.Header>
      <Modal.Body>
        <div className="formPanel">
          <div className="form-group">
            <div className="oldPassword cardType">
              <label htmlFor="selectedQuestion">Select a Security Question</label>
              <select
                value={selectedQuestion}
                name="selectedQuestion"
                onChange={handleChange}
                className="questionAnswer"
              >
                <option value="">Please Select</option>
                {questions.map((value) => (
                  <option key={value.questionId} value={value.question}>
                    {value.question}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <div className="answerqa">
              <label htmlFor="answerqa">Answer</label>
              <input
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                className="form-control"
                type="text"
                name="selectedAnswer"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn agiaBlueButton-outline"
          onClick={() => {
            resetPopupNotifications();
            hide();
          }}
        >
          Cancel
        </button>

        <button disabled={!isFormValid} type="button" className="btn agiaBlueButton" onClick={handleSubmit}>
          Save changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeSecurityQuestionPopup;
