import { ReactElement, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './styles.scss';
import { ARTICLES } from 'constants/constants';
import { Product } from 'interfaces/interfaces';
import { convertStringToAmount } from 'utils/utils';

interface ProductsMobileProps {
  products: Product[];
  globalWebContents: Record<string, string>;
  handlePayNow: (selectedProduct: Product | null) => void;
  handleChangeFreqAutoPay: (selectedProduct: Product) => Promise<void>;
  getPaymentInfo: (policyNumber: string) => Promise<void>;
  getDisclaimers: () => ReactElement[];
}

const ProductsMobile = ({
  products,
  handleChangeFreqAutoPay,
  handlePayNow,
  globalWebContents,
  getPaymentInfo,
  getDisclaimers,
}: ProductsMobileProps) => {
  const [open, setOpen] = useState<Record<string, boolean>>({});

  const toggleOpen = async (id: string) => {
    const selectedProduct = products.find((product) => product.policyNumber === id);
    if (!open[id] && !selectedProduct?.paymentInfoLoaded) {
      await getPaymentInfo(id);
    }

    setOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const getFooterBtns = (value: Product) => {
    const enablePayNow = value.paymentAction.payNowLink.disable !== 'Y';
    const enableFrequency = value.paymentAction.changeAutoPayOrFrequency.disable !== 'Y';
    const payNowBtnLabel = value.paymentAction.payNowLink.label;
    const freqBtnLabel = value.paymentAction.changeAutoPayOrFrequency.label;

    if (value.paymentStatus && enablePayNow) {
      return (
        <div className="footerCol">
          {enableFrequency && (
            <div className="leftText">
              <Link
                className="ma-pc-autopayFrequency"
                to="#"
                onClick={() => {
                  handleChangeFreqAutoPay(value);
                }}
              >
                {freqBtnLabel}
              </Link>
            </div>
          )}

          <div className="rightBtn">
            <div className="squareButton">
              <button
                onClick={() => {
                  handlePayNow(value);
                }}
                className="ma-pc-payNow btn agiaBlueButton payNow"
                type="button"
                value="Submit"
              >
                {payNowBtnLabel}
              </button>
            </div>
          </div>
        </div>
      );
    } else if (enableFrequency) {
      return (
        <div className="footerCol">
          <div className="leftText singleCol">
            <Link
              className="ma-pc-autopayFrequency"
              to="#"
              onClick={() => {
                handleChangeFreqAutoPay(value);
              }}
            >
              {freqBtnLabel}
            </Link>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="mobileView homePayDues">
      <div className="singleCol activeRowTable">
        {products.map((value) => (
          <div key={value.policyNumber} className="flexWhiteBox col-lg-12 col-md-12 float-left p-0">
            <div className="leftColumn col-lg-12 col-md-12 col-xs-12 col-12 float-left p-0">
              <div
                className={
                  'headerColumn ' +
                  (value.paymentStatus && value.paymentStatus.toLowerCase() === 'due now' ? 'selected' : '')
                }
              >
                <h3>
                  <Link title={''} to={`/product-details/${value.policyNumber}`}>
                    {value.productDesc}
                  </Link>
                </h3>
                <div className="btn-links">
                  <span className={'paymentStatusBadge ' + value.paymentStatusDisplayStyle}>{value.paymentStatus}</span>
                </div>
              </div>
              <div className="bodyRow">
                <div className="flexBox">
                  <ul>
                    <li>
                      <span className="label">CERTIFICATE/PLAN NUMBER</span>
                      <span className="value">{value.policyNumber}</span>
                    </li>
                    <li>
                      <span className="label">PAYMENT FREQUENCY</span>
                      <span className="value">{value.currentPaymentFrequecyType}</span>
                    </li>
                    <li>
                      <span className="label">AUTO PAY METHOD</span>
                      <span className="value">{value.currentPaymentMethod}</span>
                    </li>
                    <li>
                      <span className="label">AMOUNT DUE</span>
                      <span className="value">
                        {value.showPaymentAmountBreakdown === false && (
                          <span>
                            {value.amountDue}
                            <sup dangerouslySetInnerHTML={{ __html: value.amountDueSpecialCharacter }}></sup>
                          </span>
                        )}
                        {value.showPaymentAmountBreakdown === true && (
                          <span>
                            <Link
                              onClick={(e) => {
                                e.preventDefault();
                                toggleOpen(value.policyNumber);
                              }}
                              aria-controls="payNow-collapse-text"
                              aria-expanded={open[value.policyNumber] ?? false}
                              className="payNow-collapse-text"
                              to="#"
                            >
                              <span className="priceValue">
                                {value.amountDue}
                                <sup dangerouslySetInnerHTML={{ __html: value.amountDueSpecialCharacter }}></sup>
                              </span>
                              <span className="materialicons leftArrow">chevron_left</span>
                              <span className="materialicons downArrow">expand_more</span>
                            </Link>
                          </span>
                        )}
                      </span>
                    </li>
                    {value.paymentInfo && (
                      <Collapse in={open[value.policyNumber]}>
                        <div id="payNowCollapseText">
                          <div className="bodyRow">
                            <div className="flexBox">
                              <ul>
                                <li>
                                  <span className="label">AMOUNT DUE</span>
                                  <span className="value">{value.paymentInfo.amountDue || '-'}</span>
                                  <span className="tooltip-break-special-chars">
                                    <sup>{value.paymentInfo.amountDueSpecialCharacter}</sup>
                                  </span>
                                </li>
                                <li>
                                  <span className="label">PAST DUE</span>
                                  <span className="value">{value.paymentInfo.pastDueAmount || '-'}</span>
                                  <span className="tooltip-break-special-chars"></span>
                                </li>
                                <li>
                                  <span className="label">ADJUSTMENT</span>
                                  <span className="value">{value.paymentInfo.adjustment || '-'}</span>
                                  <span className="tooltip-break-special-chars">{'\u00A0CR'}</span>
                                </li>
                                {convertStringToAmount(value.paymentInfo.billingCredit) !== 0 && (
                                  <li>
                                    <span className="label">Billing Credit</span>
                                    <span className="value">{value.paymentInfo.billingCredit || '-'}</span>
                                    <span className="tooltip-break-special-chars">
                                      {convertStringToAmount(value.paymentInfo.billingCredit) > 0 && '\u00A0CR'}
                                    </span>
                                  </li>
                                )}
                                <li>
                                  <span className="label">TOTAL AMOUNT DUE</span>
                                  <span className="value">{value.paymentInfo.totalAmountDue || '-'}</span>
                                  <span className="tooltip-break-special-chars"></span>
                                </li>
                                {value.paymentInfo.disclaimer && (
                                  <li>
                                    <span className="tooltip-breakdown-disclaimer">{value.paymentInfo.disclaimer}</span>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    )}
                    <li>
                      <span className="label">DUE DATE</span>
                      <span className="value">{value.dueDate}</span>
                    </li>
                  </ul>
                </div>
              </div>

              {getFooterBtns(value)}
            </div>
          </div>
        ))}

        <div className="footerTextPara">
          <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.AUTOPAY_MARKETTING] }}></div>
          <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.SITE_DISCLAIMER] }}></div>
          {getDisclaimers()}
          <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.TRANSACTION_DISCLAIMER] }}></div>
        </div>
      </div>
    </div>
  );
};

export default ProductsMobile;
