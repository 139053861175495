import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ARTICLES } from 'constants/constants';
import { IProduct } from 'interfaces/interfaces';

interface ActiveProductsProps {
  activeProducts: IProduct[];
  hasEAPlusProduct: string;
  globalWebContents: Record<string, string>;
}

const ActiveProducts = ({ activeProducts, hasEAPlusProduct, globalWebContents }: ActiveProductsProps) => {
  const [showClaimNow, setShowClaimNow] = useState<boolean>(false);

  useEffect(() => {
    const hasInsuredProduct = !!activeProducts.find((product) => product.isInsuranceProduct === 'Y');
    setShowClaimNow(hasInsuredProduct);
  }, [activeProducts]);

  return (
    <>
      <table
        className={
          (activeProducts.length === 0 ? 'productTableMarginBottom' : '') + ' table table-light borderless productTable'
        }
      >
        <thead>
          <tr>
            <th className="product-col" scope="col">
              PRODUCT
            </th>
            <th className="payment-frequency-col" scope="col">
              PAYMENT FREQUENCY
            </th>
            <th scope="col" className="amount amount-col">
              AMOUNT DUE
            </th>
            <th className="duedate-col" scope="col">
              DUE DATE
            </th>
          </tr>
        </thead>
        <tbody>
          {activeProducts.map((value) => (
            <tr key={value.policyNumber}>
              <td>
                <Link title={''} to={`/product-details/${value.policyNumber}`}>
                  <span className="anchorText">{value.productDesc}</span>
                </Link>
                <span className="smallTableText">{value.policyNumber}</span>
              </td>
              <td>{value.currentPaymentFrequecyType}</td>

              <td className="amount">
                {value.amountDue}
                <sup dangerouslySetInnerHTML={{ __html: value.amountDueSpecialCharacter }}></sup>
              </td>
              <td>
                <div className="display:flex;">
                  <span className="dateValue">{value.dueDate}</span>
                  <span className={'paymentStatusBadge ' + value.paymentStatusDisplayStyle}>{value.paymentStatus}</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="footerTextPara">
        {activeProducts.length !== 0 && (
          <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.AUTOPAY_MARKETTING] }}></div>
        )}
        {showClaimNow && <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.CLAIM_NOW] }}></div>}
        {hasEAPlusProduct === 'Y' && (
          <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.EA_PLUS_MEMBER_NOTE] }}></div>
        )}
        <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.SITE_DISCLAIMER] }}></div>
      </div>
    </>
  );
};

export default ActiveProducts;
