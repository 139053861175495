import { createContext, ReactNode, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import { CARDS_TYPE, NOTIFICATIONS } from 'constants/constants';
import sharedService from 'services/sharedService';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { CreditCard, GTMDataLayer, PageTitles, State, TitleContentPair } from 'interfaces/interfaces';

export interface GlobalContextType {
  globalContextState: GlobalContextState;
  getStatesList: () => void;
  setShowNavigationBar: (value: boolean) => void;
  validateCardDetails: (ccNum: string, ccType: string) => ValidateCardDetailsOutput;
  getCreditCardsRegex: () => void;
  setPagesTitle: (pageTitles: PageTitles) => void;
  triggerGTMDataLayer: (dataLayer: GTMDataLayer) => void;
}

export const GlobalContext = createContext<GlobalContextType | null>(null);

interface GlobalProviderProps {
  children: ReactNode;
}

interface GlobalContextState {
  states: State[];
  deviceHeight: number;
  deviceWidth: number;
  showNavigationBar: boolean;
  pagesTitle: PageTitles;
  creditCards: CreditCard[];
}

interface ValidateCardDetailsOutput {
  status: string;
  message: string;
}

const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const [state, setState] = useState<GlobalContextState>({
    states: [],
    deviceHeight: window.innerHeight,
    deviceWidth: window.innerWidth,
    showNavigationBar: true,
    pagesTitle: {},
    creditCards: [],
  });

  const { initData } = useInitStore((state: InitStore) => ({
    initData: state.initData,
  }));

  useEffect(() => {
    getStatesList();

    window.addEventListener('resize', () => {
      setState((prevState) => ({ ...prevState, deviceWidth: window.innerWidth, deviceHeight: window.innerHeight }));
    });
  }, []);

  const getStatesList = async () => {
    try {
      const response = await sharedService.getStatesList();
      if (response.status === 200) {
        setState((prevState) => ({ ...prevState, states: response.data.items }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setPagesTitle = (pageTitles: PageTitles) => {
    setState((prevState) => ({ ...prevState, pagesTitle: pageTitles }));
  };

  const getCreditCardsRegex = async () => {
    const titles: string[] = [];
    CARDS_TYPE.forEach((card) => {
      titles.push(card.regex_WC_key);
    });
    const params = {
      titles: titles,
    };

    try {
      const response = await sharedService.getContents(params);
      if (response.status === 200) {
        const creditCards: CreditCard[] = [];
        response?.data?.items?.forEach((element: TitleContentPair) => {
          const creditCard: CreditCard | undefined = CARDS_TYPE.find((card) => card.regex_WC_key === element.title);
          if (creditCard) {
            creditCard['regex'] = element.content;
            creditCards.push(creditCard);
          }
        });
        setState((prevState) => ({ ...prevState, creditCards: creditCards }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setShowNavigationBar = (value: boolean) => {
    setState((prevState) => ({ ...prevState, showNavigationBar: value }));
  };

  const validateCardDetails = (ccNum: string, ccType: string) => {
    const selectedCard = state.creditCards.find((card) => card.id === ccType);

    // remove html tags if any
    const tmp = document.createElement('DIV') as HTMLDivElement;
    if (selectedCard?.regex) {
      tmp.innerHTML = selectedCard.regex;
    }
    const regexStr = (tmp.textContent ?? tmp.innerText) || '';
    const regex = new RegExp(regexStr);
    if (regex.test(ccNum.replaceAll('-', ''))) {
      return { status: 'SUCCESS', message: '' };
    } else {
      return { status: 'FAILURE', message: NOTIFICATIONS.INVALID_CREDIT_CARD };
    }
  };

  const triggerGTMDataLayer = (dataLayer: GTMDataLayer) => {
    if (initData?.gtmId) {
      const tagManagerArgs = {
        dataLayer: dataLayer,
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        globalContextState: state,
        getStatesList: getStatesList,
        setShowNavigationBar: setShowNavigationBar,
        validateCardDetails: validateCardDetails,
        getCreditCardsRegex: getCreditCardsRegex,
        setPagesTitle: setPagesTitle,
        triggerGTMDataLayer: triggerGTMDataLayer,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
