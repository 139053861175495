import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';

import { ARTICLES } from 'constants/constants';
import { Product } from 'interfaces/interfaces';

interface ProductsMainMobileProps {
  products: Product[];
  inactiveProducts: Product[];
  globalWebContents: Record<string, string>;
}

const ProductsMainMobile: React.FC<ProductsMainMobileProps> = ({ products, inactiveProducts, globalWebContents }) => {
  const BATCH_SIZE_INACTIVE_PRODUCTS = 5;
  const [showInactiveProducts, setShowInactiveProducts] = useState(false);
  const [showMoreCounter, setShowMoreCounter] = useState(BATCH_SIZE_INACTIVE_PRODUCTS);
  const [inactiveProductsToShow, setInactiveProductsToShow] = useState(inactiveProducts.slice(0, showMoreCounter));

  const showMore = () => {
    const newCounter = showMoreCounter + BATCH_SIZE_INACTIVE_PRODUCTS;
    setInactiveProductsToShow(inactiveProducts.slice(0, newCounter));
    setShowMoreCounter(newCounter);
  };

  const getDisclaimers = () => {
    const disclaimerTypesMap = [
      { name: 'autopay', content: globalWebContents[ARTICLES.PRODUCT_DISCLAIMER] },
      { name: 'combinedbill', content: globalWebContents[ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING] },
      { name: 'nocosttoyou', content: globalWebContents[ARTICLES.PRODUCT_DISCLAIMER_NOCOST] },
    ];

    const components: JSX.Element[] = [];
    const iterated: string[] = [];

    products.forEach((product, index) => {
      const disclaimerObj = disclaimerTypesMap.find((item) => item.name === product.paymentStatusDisplayStyle);
      const hasAlreadyIterated = !iterated.includes(product.paymentStatusDisplayStyle || '');

      if (disclaimerObj && hasAlreadyIterated) {
        components.push(<div key={index} dangerouslySetInnerHTML={{ __html: disclaimerObj.content }} />);
        iterated.push(product.paymentStatusDisplayStyle || '');
      }
    });

    return components;
  };

  return (
    <div className="mobileView productDetailTemplate">
      <div className="singleCol">
        {products.length > 0 && (
          <>
            <h2 className="homeHederText">Active Products</h2>
            <div className="flexWhiteBox col-lg-12 col-md-12 float-left p-0">
              {products.map((value) => (
                <div
                  key={value.policyNumber}
                  className="leftColumn col-lg-12 col-md-12 col-xs-12 col-12 float-left p-0"
                >
                  <div className="headerColumn">
                    <h3>
                      <Link to={`/product-details/${value.policyNumber}`}>{value.productDesc}</Link>
                    </h3>
                  </div>
                  <div className="bodyRow">
                    <div className="flexBox">
                      <ul>
                        <li>
                          <span className="label">CERTIFICATE/PLAN NUMBER</span>
                          <span className="value">{value.policyNumber}</span>
                        </li>
                        <li>
                          <span className="label">COVERAGE TYPE</span>
                          <span className="value">{value.coverageType}</span>
                        </li>
                        <li>
                          <span className="label">COVERAGE EFFECTIVE DATE</span>
                          <span className="value">{value.coverageEffectiveDate}</span>
                        </li>
                        <li>
                          <span className="label">ANNUAL PRODUCT COST</span>
                          <span className="value">
                            {value.annualPremium}
                            {value.annualPremiumSpecialCharacter && (
                              <sup dangerouslySetInnerHTML={{ __html: value.annualPremiumSpecialCharacter }} />
                            )}
                          </span>
                        </li>
                        <li>
                          <span className="label">PAYMENT FREQUENCY</span>
                          <span className="value">{value.currentPaymentFrequecyType}</span>
                        </li>
                        <li>
                          <span className="label">DUE DATE</span>
                          <span className="value">{value.dueDate}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="footerTextPara">
              {getDisclaimers()}
              <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.SITE_DISCLAIMER] }} />
              <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.ANNUAL_PRODUCT_COST_DISCLAIMER] }} />
            </div>
          </>
        )}
      </div>

      {inactiveProducts.length > 0 && (
        <div className="inActiveTable singleCol">
          <div className="inavtiveHeader">
            <h2 className="homeHederText">Inactive Products</h2>
            <Link
              onClick={(e) => {
                e.preventDefault();
                setShowInactiveProducts(!showInactiveProducts);
              }}
              aria-controls="collapse-text"
              aria-expanded={showInactiveProducts}
              className="collapse-text"
              to="#"
            >
              <span className="leftArrow">Show</span>
              <span className="downArrow">Hide</span>
            </Link>
          </div>
          <div className="inActiveContent">
            <Collapse in={showInactiveProducts}>
              <div className="flexWhiteBox col-lg-12 col-md-12 float-left p-0">
                {inactiveProductsToShow.map((value, index) => (
                  <div key={index} className="leftColumn col-lg-12 col-md-12 col-xs-12 col-12 float-left p-0">
                    <div className="headerColumn">
                      <h3>
                        <Link to={`/product-details/${value.policyNumber}`}>{value.productDesc}</Link>
                      </h3>
                    </div>
                    <div className="bodyRow">
                      <div className="flexBox">
                        <ul>
                          <li>
                            <span className="label">CERTIFICATE/PLAN NUMBER</span>
                            <span className="value">{value.policyNumber}</span>
                          </li>
                          <li>
                            <span className="label">AMOUNT DUE</span>
                            <span className="value">{value.amountDue}</span>
                          </li>
                          <li>
                            <span className="label">DUE DATE</span>
                            <span className="value">{value.dueDate}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}

                {showMoreCounter < inactiveProducts.length && (
                  <button className="showMoreButton" onClick={showMore}>
                    Show {BATCH_SIZE_INACTIVE_PRODUCTS} more
                  </button>
                )}
              </div>
            </Collapse>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsMainMobile;
