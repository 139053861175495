import { IProduct } from 'interfaces/interfaces';
import ActiveProducts from './ActiveProducts';
import DuePaymentCard from './DuePaymentCard';
import NoDuePaymentCard from './NoDuePaymentCard';

interface SummaryHorizontalProps {
  dueProducts: IProduct[];
  clientName: string;
  activeProducts: IProduct[];
  hasEAPlusProduct: string;
  globalWebContents: Record<string, string>;
}

const SummaryHorizontal = ({
  dueProducts,
  clientName,
  activeProducts,
  hasEAPlusProduct,
  globalWebContents,
}: SummaryHorizontalProps) => {
  return (
    <div className="desktopView homePayDues singleColLayout">
      <div className="leftCol">
        <h2 className="homeHederText">Payments Due</h2>
        {!!dueProducts.length && <DuePaymentCard clientName={clientName} dueProduct={dueProducts[0]} />}
        {!dueProducts.length && <NoDuePaymentCard />}
      </div>
      <div className="rightCol">
        <h2 className="homeHederText">Your Active Products</h2>
        <ActiveProducts
          activeProducts={activeProducts}
          hasEAPlusProduct={hasEAPlusProduct}
          globalWebContents={globalWebContents}
        />
      </div>
    </div>
  );
};

export default SummaryHorizontal;
