import axios from 'axios';

import { byPassCsUserId } from 'constants/endpoints';

export default () => {
  const instance = axios.create();

  instance.interceptors.request.use(
    (config) => {
      const isCustomer =
        localStorage.getItem('init-store') &&
        JSON.parse(localStorage.getItem('init-store') as string)?.state?.initData?.isCustomerSite;
      const user = isCustomer ? localStorage.getItem('csuser-store') : localStorage.getItem('user-store');
      const accessToken = user ? JSON.parse(user)?.state?.user?.access_token : null;
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }

      config.headers['Content-Type'] = 'application/json';

      if (
        config.method === 'post' &&
        user &&
        JSON.parse(user)?.state?.user?.csUserid &&
        !byPassCsUserId.includes(config.url as string)
      ) {
        config.data['csUserid'] = JSON.parse(user)?.state?.user?.csUserid;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 403) {
        // Handle forbidden error e.g. show restricted access warning
      }

      if (error?.response?.status === 401) {
        // Handle unauthorized error e.g. refresh and set token in storage
      }
      throw error;
    },
  );
  return instance;
};
