import Axios from './Axios';

import { ENDPOINTS } from 'constants/endpoints';
import { GetDashboardDataParams } from 'interfaces/apiRequestInterfaces';

const dashboardService = {
  getDashboardData: (params: GetDashboardDataParams) => {
    return Axios().post(ENDPOINTS.dashboard, params);
  },
};

export default dashboardService;
