import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ARTICLES } from 'constants/constants';
import { convertStringToAmount } from 'utils/utils';
import { AdditionalInsured, OptionalRiders, ProductOverview } from '.';

export interface ProductDocument {
  documentCreatedDate: string;
  documentName: string;
  documentCategory: string;
  documentTypeId: string;
  showDocError: boolean;
  documentId: string;
  documentSource: string;
}

interface ProductDetailsDesktopProps {
  productOverview: ProductOverview;
  additionalInsured: Array<AdditionalInsured>;
  optionalRiders: Array<OptionalRiders>;
  productDocuments: Array<ProductDocument>;
  correspondences: Array<ProductDocument>;
  openDocument: (document: ProductDocument, type: string) => void;
  globalWebContents: Record<string, string>;
}

const ProductDetailsDesktop: React.FC<ProductDetailsDesktopProps> = ({
  productOverview,
  additionalInsured,
  optionalRiders,
  productDocuments,
  correspondences,
  openDocument,
  globalWebContents,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.PRODUCT_DETAILS_BENEFITS] }}></div>
      <h1 className="mainHeadingT desktopView">
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          className="materialicons"
        >
          arrow_back
        </Link>
        {productOverview.productDesc}
      </h1>
      <div className="desktopView productsDescTemplate">
        <div className="twoColLayout">
          <div className="leftCol">
            <h2 className="homeHederText">Product Overview</h2>
            <div className="flexWhiteBox">
              <div className="leftColumn">
                <div className="bodyRow">
                  <div className="flexBox">
                    <ul>
                      <li>
                        <span className="label">
                          {productOverview.isInsuranceProduct === 'Y' ? 'CERTIFICATE NUMBER' : 'PLAN NUMBER'}
                        </span>
                        <span className="value">{productOverview.policyNumber}</span>
                      </li>
                      <li>
                        <span className="label">
                          {productOverview.isInsuranceProduct === 'Y' ? 'COVERAGE TYPE' : 'PLAN TYPE'}
                        </span>
                        <span className="value">{productOverview.coverageType}</span>
                      </li>
                      <li>
                        <span className="label">STATUS</span>
                        <span className="value">{productOverview.policyStatus}</span>
                      </li>
                      <li>
                        <span className="label">COVERAGE EFFECTIVE DATE</span>
                        <span className="value">{productOverview.coverageEffectiveDate}</span>
                      </li>
                      {productOverview.suppressPaymentDueDate === 'N' && (
                        <li>
                          <span className="label">{productOverview.paymentDueDateLabel}</span>
                          <span className="value">{productOverview.paymentDueDate}</span>
                        </li>
                      )}
                      {convertStringToAmount(productOverview.benefitAmount) !== 1 && (
                        <li>
                          <span className="label">
                            {productOverview.isInsuranceProduct === 'Y'
                              ? productOverview.isCancerOrHipOrHap === 'Y'
                                ? 'DAILY HOSPITAL BENEFIT'
                                : 'BASE BENEFIT AMOUNT'
                              : 'MEMBERSHIP FEE'}
                          </span>
                          <span className="value">{productOverview.benefitAmount}</span>
                        </li>
                      )}
                      {productOverview.isInsuranceProduct === 'N' && (
                        <li>
                          <span className="label">OWNER</span>
                          <span className="value">{productOverview.owner}</span>
                        </li>
                      )}
                      <li>
                        <span className="label">
                          {productOverview.isInsuranceProduct === 'Y' ? 'ANNUAL PREMIUM' : 'ANNUAL PRODUCT COST'}
                        </span>
                        <span className="value">
                          {productOverview.annualPremium}
                          {productOverview.isInsuranceProduct === 'Y' && (
                            <sup>{productOverview.annualPremiumSpecialCharacter}</sup>
                          )}
                        </span>
                      </li>
                      <li>
                        <span className="label">PAYMENT FREQUENCY</span>
                        <span className="value">{productOverview.paymentFrequency}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="leftCol">
            {additionalInsured && additionalInsured.length > 0 && (
              <>
                <h2 className="homeHederText">Additional Insured</h2>
                <div className="flexWhiteBox tableForm">
                  <div className="leftColumn">
                    <div className="bodyRow">
                      <div className="flexBox">
                        <table className="table table-light borderless productTable">
                          <thead>
                            <tr>
                              <th scope="col">FULL NAME</th>
                              <th scope="col">RELATIONSHIP</th>
                            </tr>
                          </thead>
                          <tbody>
                            {additionalInsured.map((value, index) => (
                              <tr key={index}>
                                <td>{value.fullName}</td>
                                <td>{value.relationship}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {optionalRiders.length > 0 && (
          <div className="optionalRider">
            <h2 className="homeHederText">Additional Riders</h2>
            <table className="table table-light borderless productTable">
              <thead>
                <tr>
                  <th scope="col">PRODUCT</th>
                  <th scope="col">STATUS</th>
                  <th scope="col">COVERAGE EFFECTIVE DATE</th>
                  <th scope="col" className="amount">
                    BENEFIT AMOUNT
                  </th>
                  <th scope="col" className="amount">
                    ANNUAL PREMIUM
                  </th>
                </tr>
              </thead>
              <tbody>
                {optionalRiders.map((value, index) => (
                  <tr key={index}>
                    <td>
                      <span className="nonAnchorText">{value.productDesc}</span>
                      <span className="smallTableText">{value.policyNumber}</span>
                    </td>
                    <td>{value.policyStatus}</td>
                    <td>{value.coverageEffectiveDate}</td>
                    <td className="amount">{value.benefitAmount}</td>
                    <td className="amount">{value.annualPremium}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="twoColLayout">
          {productDocuments.length > 0 && (
            <div className="leftCol">
              <h2 className="homeHederText">Product Documents</h2>
              <div className="flexWhiteBox productDocs">
                <div className="leftColumn">
                  <div className="bodyRow">
                    <div className="flexBox">
                      <ul>
                        {productDocuments.map((correspondence, index) => (
                          <li key={index}>
                            <span className="label">
                              <span className="smallTableText">{correspondence.documentCreatedDate}</span>
                              <span className="nonAnchorText">{correspondence.documentName}</span>
                            </span>
                            <span className="value">
                              <Link
                                className={`ma-pd-${correspondence.documentCategory}-${correspondence.documentTypeId}-${productOverview.policyNumber}`}
                                title={correspondence.documentName}
                                to="/#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  openDocument(correspondence, 'PRODUCT_DOCS');
                                }}
                              >
                                View Document
                              </Link>
                              {correspondence.showDocError === true && (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: globalWebContents[ARTICLES.DOCUMENT_ERROR],
                                  }}
                                  className="document-error"
                                ></p>
                              )}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {correspondences.length > 0 && (
            <div className="leftCol">
              <h2 className="homeHederText">Correspondence</h2>
              <div className="flexWhiteBox productDocs">
                <div className="leftColumn">
                  <div className="bodyRow">
                    <div className="flexBox">
                      <ul>
                        {correspondences.map((correspondence, index) => (
                          <li key={index}>
                            <span className="label">
                              <span className="smallTableText">{correspondence.documentCreatedDate}</span>
                              <span className="nonAnchorText">{correspondence.documentName}</span>
                            </span>
                            <span className="value">
                              <Link
                                title={correspondence.documentName}
                                to="/#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  openDocument(correspondence, 'CORRESPONDENCE_DOCS');
                                }}
                              >
                                View Document
                              </Link>
                              {correspondence.showDocError === true && (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: globalWebContents[ARTICLES.DOCUMENT_ERROR],
                                  }}
                                  className="document-error"
                                ></p>
                              )}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="footerTextPara">
        <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.PRODUCT_DETAILS_MISMATCH_DATA] }}></div>
        {productOverview.isInsuranceProduct === 'Y' && (
          <p dangerouslySetInnerHTML={{ __html: productOverview.message as string }}></p>
        )}
        <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.SITE_DISCLAIMER] }}></div>
      </div>
    </div>
  );
};

export default ProductDetailsDesktop;
