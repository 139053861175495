import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './styles.scss';
import { ARTICLES, MONTHS_LONG, PAGE_TITLES } from 'constants/constants';
import {
  AxiosError,
  PaymentMethod,
  Product,
  TitleContentPair,
  Notification as INotification,
} from 'interfaces/interfaces';
import paymentCenterService from 'services/paymentCenterService';
import sharedService from 'services/sharedService';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { getCleanMessages, NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { UserStore, useUserStore } from 'stores/useUserStore';
import { PaymentCenterStore, usePaymentCenterStore } from 'stores/usePaymentCenterStore';
import { PaymentMethodOptions } from '../PaymentCenterProducts';
import { convertStringToAmount } from 'utils/utils';
import PaymentMethodModal from 'components/PaymentMethodModal';
import Notification from 'components/common/Notification';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import ConfirmPayNow from './ConfirmPayNow';
import Modal from 'components/common/Modal';
import PayNowDesktop from './PayNowDesktop';
import PayNowMobile from './PayNowMobile';

interface State {
  paymentMethods: (PaymentMethod | PaymentMethodOptions)[];
  product: Product | null;
  paymentMethodsLoaded: boolean;
  hasAnyPaymentMethods: boolean;
  globalWebContents: Record<string, string>;
  confirmPopupContent: string;
  payNowSuccessMsg: string;
  pageTitle: string;
  isFormValid: boolean;
  selectedPaymentMethodRecId: string | null;
  selectedPaymentMethod: PaymentMethod | PaymentMethodOptions | null;
  selectedPaymentMethodObj: PaymentMethod | PaymentMethodOptions | null;
}

const PayNow = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState<State>({
    paymentMethods: [],
    product: null,
    paymentMethodsLoaded: false,
    hasAnyPaymentMethods: false,
    selectedPaymentMethodRecId: null,
    selectedPaymentMethodObj: null,
    selectedPaymentMethod: null,
    globalWebContents: {},
    confirmPopupContent: '',
    payNowSuccessMsg: '',
    isFormValid: false,
    pageTitle: '',
  });

  const [showPaymentMethodPopup, setShowPaymentMethodPopup] = useState<boolean>(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [showPayNowSuccessPopup, setShowPayNowSuccessPopup] = useState<boolean>(false);

  const { globalContextState } = useContext(GlobalContext) as GlobalContextType;

  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { processMessages, notificationsData, pushNonInfoMsg, popNonInfoMsg } = useNotificationsStore(
    (state: NotificationsStore) => ({
      processMessages: state.processMessages,
      notificationsData: state.notificationsData,
      pushNonInfoMsg: state.pushNonInfoMsg,
      popNonInfoMsg: state.popNonInfoMsg,
    }),
  );

  const { storePaymentMethods, setPaymentMethods } = usePaymentCenterStore((store: PaymentCenterStore) => ({
    storePaymentMethods: store.paymentMethods,
    setPaymentMethods: store.setPaymentMethods,
  }));

  useEffect(() => {
    setState((prevState) => ({ ...prevState, pageTitle: initData?.client + PAGE_TITLES.PAY_NOW }));
  }, [initData?.client]);

  useEffect(() => {
    if (location.state.selectedProduct) {
      const product = location.state.selectedProduct;
      product['clientName'] = location.state.clientName;
      product['fromScreen'] = location.state.fromScreen;
      getPayNow(product.policyNumber, product.fromScreen);
    }
  }, [location.state.clientName, location.state.fromScreen, location.state.selectedProduct]);

  const getPayNow = async (policyNumber: string, type: string) => {
    const params = {
      client: initData?.client,
      accountNumber: user?.accountNumber,
      policyNumber: policyNumber,
      firstName: user?.firstName,
      lastName: user?.lastName,
      userid: user?.userid,
      isEmailSubscribed: user?.isEmailSubscribed,
      csUserid: '',
    } as Record<string, string>;

    try {
      setAppLoading(true);
      const response = await paymentCenterService.getPayNow(params, type);
      if (response.status === 200) {
        const product = response.data.activeProducts[0];
        product['clientName'] = response.data.clientName;
        setState((prevState) => ({ ...prevState, product: product }));

        if (product.paymentAction.payNowLink.message) {
          const messages = [
            {
              type: 'INFO',
              content: [product.paymentAction.payNowLink.message],
            },
          ];
          processMessages(messages);
        }

        getPaymentMethods(product);

        getContent([ARTICLES.PAYNOW_INSURANCE, ARTICLES.PAYNOW_NON_INSURANCE, ARTICLES.PAYNOW_INSURANCE_NOBILL]);
        processMessages(response.data.messages);
      } else {
        processMessages(response.data.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  };

  const getContent = async (titles: string[]) => {
    const params = {
      titles: titles,
    };
    try {
      const response = await sharedService.getContents(params);
      if (response.status === 200) {
        const webContents: { [key: string]: string } = {};
        response?.data?.items?.forEach((element: TitleContentPair) => {
          webContents[element.title] = element.content;
        });
        setState((prevState) => ({ ...prevState, globalWebContents: webContents }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPaymentMethods = (product: Product) => {
    const paymentMethods: (PaymentMethod | PaymentMethodOptions)[] = storePaymentMethods.length
      ? [...storePaymentMethods]
      : [];
    let selectedPaymentMethod: PaymentMethod | PaymentMethodOptions | null = null;
    const addOptionObj = {
      paymentRecordId: 'ADD_NEW_PAYMENT_METHOD',
      paymentMethodHeader: 'Add new payment method',
    };

    if (storePaymentMethods.length) {
      setState((prevState) => ({
        ...prevState,
        hasAnyPaymentMethods: !!storePaymentMethods.length,
      }));

      if (product?.currentPaymentMethodRecordId) {
        selectedPaymentMethod = storePaymentMethods.find(
          (item) => item.paymentRecordId === product.currentPaymentMethodRecordId,
        ) as PaymentMethod;
      } else {
        selectedPaymentMethod = storePaymentMethods[0];
      }
    }

    // Add 'Add new payment method'
    const found = paymentMethods.some((paymentMethod) => paymentMethod.paymentRecordId === 'ADD_NEW_PAYMENT_METHOD');
    if (!found) {
      paymentMethods.push(addOptionObj);
    }

    if (!selectedPaymentMethod) {
      selectedPaymentMethod = addOptionObj;
    }

    setState((prevState) => ({
      ...prevState,
      paymentMethods: paymentMethods,
      paymentMethodsLoaded: true,
      selectedPaymentMethod: selectedPaymentMethod,
      selectedPaymentMethodObj: selectedPaymentMethod,
      selectedPaymentMethodRecId: selectedPaymentMethod?.paymentRecordId as string,
    }));
    checkForExpiry(selectedPaymentMethod.paymentRecordId, paymentMethods);
  };

  const checkForExpiry = (value: string, paymentMethods: (PaymentMethod | PaymentMethodOptions)[]) => {
    const paymentMethodObj = paymentMethods.find((item) => item.paymentRecordId === value);
    const type = (paymentMethodObj as PaymentMethod)?.creditCard ? 'creditCard' : 'bankAccount';

    if (type === 'creditCard' && (paymentMethodObj as PaymentMethod)?.isCardExpired === 'Y') {
      const newMsg = [
        {
          messageId: 'EXPIRED_CARD',
          type: 'ERROR',
          content: [(paymentMethodObj as PaymentMethod)?.cardExpMessage],
        },
      ];
      pushNonInfoMsg(newMsg);

      setState((prevState) => ({ ...prevState, isFormValid: false }));
    } else {
      popNonInfoMsg('EXPIRED_CARD');
      setState((prevState) => ({ ...prevState, isFormValid: true }));
    }
  };

  const getAttestationContent = (rawContent: string) => {
    let content = rawContent;
    let dataMap, _dataMap;
    const product = state.product;

    const paymentMethod = state.paymentMethods.find(
      (item) => item.paymentRecordId === state.selectedPaymentMethodRecId,
    );

    const paymentMethodType = (paymentMethod as PaymentMethod)?.creditCard ? 'creditCard' : 'bankAccount';

    // Determine financial institution
    const accountStr = (paymentMethod as PaymentMethod)?.creditCard ? '' : ' account';
    const financialInstitutionName = (paymentMethod as PaymentMethod)[paymentMethodType]
      ? (paymentMethod as PaymentMethod)[paymentMethodType].financialInstitutionName + accountStr
      : '';

    dataMap = [
      {
        field: '[$CURRENT_DATE$]',
        value: `${MONTHS_LONG[new Date().getMonth()]} ${new Date().getDate()}, ${new Date().getFullYear()}`,
      }, // Month DD, YYYY
      { field: '[$FULL_NAME$]', value: `${user?.firstName} ${user?.lastName}` },
      { field: '[$PRODUCT_NAME$]', value: `${product?.productDesc}` },
      { field: '[$CLIENT_NAME$]', value: product?.clientName },
      { field: '[$TOLL_FREE$]', value: initData?.clientPhoneNumber },
      { field: '[$USER_NAME$]', value: `${user?.firstName} ${user?.lastName}` },
      { field: '[$DUE_DATE$]', value: `${product?.dueDate}` },
      { field: '[$FIN_INSTITUTION$]', value: `${financialInstitutionName}` },
      {
        field: '[$ACCOUNT_NUM$]',
        value: `${(paymentMethod as PaymentMethod)[paymentMethodType]?.accountNumberLast4Digit}`,
      },
    ];

    if (product?.billingFeeInd === 'Y') {
      const billingFeeObj = product?.billingFees?.find((item) => item.code === '04'); // Code for Direct Billing
      const amountDue = convertStringToAmount(product.amountDue);
      const billFee = convertStringToAmount(billingFeeObj?.value2 as string);

      _dataMap = [
        { field: '[$AMOUNT_DUE$]', value: `${product.amountDue}` },
        { field: '[$PREMIUM$]', value: `$${(amountDue - billFee).toFixed(2)}` },
        { field: '[$BILLING_FEE$]', value: `${billingFeeObj?.value2}` },
      ];
    } else {
      _dataMap = [{ field: '[$AMOUNT_DUE$]', value: `${product?.amountDue}` }];
    }

    dataMap = dataMap.concat(_dataMap);

    dataMap.forEach((item) => {
      content = content.replaceAll(item.field, item.value as string);
    });

    return content;
  };

  const handleOnEdit = (selectedPaymentMethod: PaymentMethod | null) => {
    setState((prevState) => ({ ...prevState, selectedPaymentMethod: selectedPaymentMethod }));

    setShowPaymentMethodPopup(true);
  };

  const handlePaymentMethodPopupClose = (data?: PaymentMethod[], messages?: INotification[]) => {
    setShowPaymentMethodPopup(false);
    if (data) {
      setPaymentMethods(data);
      if (messages) processMessages(messages);
      const updatedData: (PaymentMethod | PaymentMethodOptions)[] = [
        ...data,
        {
          paymentRecordId: 'ADD_NEW_PAYMENT_METHOD',
          paymentMethodHeader: 'Add new payment method',
        },
      ];
      setState((prevState) => ({
        ...prevState,
        paymentMethods: updatedData,
        selectedPaymentMethod: updatedData[0],
        selectedPaymentMethodObj: updatedData[0],
        selectedPaymentMethodRecId: updatedData[0].paymentRecordId,
        hasAnyPaymentMethods: true,
      }));
    }
  };

  const handleConfirmPaynowClose = (flag?: boolean) => {
    setShowConfirmPopup(false);
    if (flag) handleSubmit();
  };

  const confirmPaynow = () => {
    let content = '';
    const product = state.product;
    if (product?.isInsuranceProduct === 'Y') {
      content =
        product.billingFeeInd === 'Y'
          ? state.globalWebContents[ARTICLES.PAYNOW_INSURANCE]
          : state.globalWebContents[ARTICLES.PAYNOW_INSURANCE_NOBILL];
    } else {
      content = state.globalWebContents[ARTICLES.PAYNOW_NON_INSURANCE];
    }

    setState((prevState) => ({ ...prevState, confirmPopupContent: getAttestationContent(content) }));
    setShowConfirmPopup(true);
  };

  const handleDropDownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (value === 'ADD_NEW_PAYMENT_METHOD') {
      handleOnEdit(null);
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
        selectedPaymentMethodObj: state.paymentMethods.find((item) => item.paymentRecordId === value) as PaymentMethod,
      }));
      checkForExpiry(value, state.paymentMethods);
    }
  };

  const handleSuccessPayNowClose = () => {
    setShowPayNowSuccessPopup(false);
    navigate(-1);
  };

  const handleSubmit = async () => {
    const paymentMethodObj = state.paymentMethods.find(
      (item) => item.paymentRecordId === state.selectedPaymentMethodRecId,
    );
    const hostName = document.location.hostname;

    const params = {
      hostName: hostName,
      client: initData?.client,
      accountNumber: user?.accountNumber,
      policyNumber: state.product?.policyNumber,
      firstName: user?.firstName,
      lastName: user?.lastName,
      userid: user?.userid,
      isEmailSubscribed: user?.isEmailSubscribed,
      totalPaymentAmount: state.product?.amountDue,
      paymentMethod: paymentMethodObj?.paymentMethodHeader,
      productName: state.product?.productDesc,
      paymentType: (paymentMethodObj as PaymentMethod)?.bankAccount
        ? (paymentMethodObj as PaymentMethod).bankAccount.paymentType
        : (paymentMethodObj as PaymentMethod)?.creditCard.paymentType,
      lastFourDigit: (paymentMethodObj as PaymentMethod)?.bankAccount
        ? (paymentMethodObj as PaymentMethod).bankAccount.accountNumberLast4Digit
        : (paymentMethodObj as PaymentMethod)?.creditCard.accountNumberLast4Digit,
      paymentDetails: [
        {
          accountNumber: user?.accountNumber,
          policyNumber: state.product?.policyNumber,
          paymentAmount: state.product?.amountDue,
          paymentMethodRecordId: paymentMethodObj?.paymentRecordId,
        },
      ],
    };

    try {
      setAppLoading(true);
      const response = await paymentCenterService.submitPayNow(params);
      if (response.status === 200) {
        const messages = getCleanMessages(response.data.messages);
        let message = '';
        messages?.forEach((_message) => {
          message = message + _message.content[0] + '<br><br>';
        });
        setState((prevState) => ({ ...prevState, payNowSuccessMsg: message }));
        setShowPayNowSuccessPopup(true);
      } else {
        processMessages(response.data.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {
            // this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.PAY_NOW] ||
            state.pageTitle
          }
        </title>
      </Helmet>
      <div>
        {state.product && (
          <>
            {showPaymentMethodPopup && (
              <PaymentMethodModal
                selectedPaymentMethod={state.selectedPaymentMethod as PaymentMethod}
                show={showPaymentMethodPopup}
                hide={handlePaymentMethodPopupClose}
              />
            )}

            {showConfirmPopup && (
              <ConfirmPayNow
                show={showConfirmPopup}
                hide={handleConfirmPaynowClose}
                content={state.confirmPopupContent}
              />
            )}

            {showPayNowSuccessPopup && (
              <Modal title={'Payment Successful'} onClose={handleSuccessPayNowClose}>
                <p dangerouslySetInnerHTML={{ __html: state.payNowSuccessMsg }} />
              </Modal>
            )}

            <div className="paymentCenterContent payNowTemplate">
              <h1 className="mainHeadingT">Pay Now</h1>
              {notificationsData.nonInfoNotificaiton.map((notification, index) => (
                <Notification key={index} id={index} message={notification} />
              ))}
              {state.paymentMethodsLoaded && (
                <div>
                  {globalContextState.deviceWidth >= 768 && (
                    <PayNowDesktop
                      product={state.product}
                      paymentMethods={state.paymentMethods}
                      handleOnEdit={handleOnEdit}
                      confirmPaynow={confirmPaynow}
                      handleDropDownChange={handleDropDownChange}
                      selectedPaymentMethodRecId={state.selectedPaymentMethodRecId as string}
                      selectedPaymentMethodObj={state.selectedPaymentMethodObj as PaymentMethod}
                      isFormValid={state.isFormValid}
                      hasAnyPaymentMethods={state.hasAnyPaymentMethods}
                    />
                  )}

                  {globalContextState.deviceWidth <= 767 && (
                    <PayNowMobile
                      product={state.product}
                      paymentMethods={state.paymentMethods}
                      handleOnEdit={handleOnEdit}
                      confirmPaynow={confirmPaynow}
                      handleDropDownChange={handleDropDownChange}
                      selectedPaymentMethodRecId={state.selectedPaymentMethodRecId as string}
                      selectedPaymentMethodObj={state.selectedPaymentMethodObj as PaymentMethod}
                      isFormValid={state.isFormValid}
                      hasAnyPaymentMethods={state.hasAnyPaymentMethods}
                    />
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PayNow;
