import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import authService from 'services/authService';
import Modal from 'components/common/Modal';
import { ARTICLES } from 'constants/constants';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { AxiosError } from 'interfaces/interfaces';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { UserStore, useUserStore } from 'stores/useUserStore';
import { CSUserStore, useCSUserStore } from 'stores/useCSUserStore';

interface HomeNavigationBarProps {
  globalWebContents: Record<string, string>;
}

const HomeNavigationBar = ({ globalWebContents }: HomeNavigationBarProps) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { globalContextState } = useContext(GlobalContext) as GlobalContextType;

  const { user, setUser } = useUserStore((state: UserStore) => ({
    user: state.user,
    setUser: state.setUser,
  }));

  const { csuser, setCSUser } = useCSUserStore((state: CSUserStore) => ({
    csuser: state.user,
    setCSUser: state.setUser,
  }));

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { processMessages } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
  }));

  const logout = async () => {
    try {
      setAppLoading(true);
      const response = await authService.logout((user?.access_token ?? csuser?.access_token) as string);
      if (response.status === 200) {
        setUser(null);
        setCSUser(null);
        navigate('/');
      } else {
        processMessages(response.data.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  };

  const handleModalClose = () => setIsModalOpen(false);

  return (
    <>
      {isModalOpen && (
        <Modal
          title="Contact us"
          onClose={handleModalClose}
          footer={
            <Button variant="primary" className="btn agiaBlueButton" as="a" href={`tel:${initData?.clientPhoneNumber}`}>
              Call Now
            </Button>
          }
        >
          <p dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.HEADER_PHONE_INFO] }}></p>
        </Modal>
      )}
      {globalContextState.deviceWidth <= 767 && (
        <div className="navbarText mobileView">
          <ul>
            {initData?.isCustomerSite && authService.isUserLoggedIn() && (
              <li className="nav-item">
                <Link
                  title={''}
                  className="nav-link phoneIcon"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    logout();
                  }}
                >
                  <i className="materialicons logout">logout</i>
                </Link>
              </li>
            )}

            {!initData?.isCustomerSite && (
              <li className="nav-item">
                <Link
                  title={''}
                  className="nav-link phoneIcon"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                  }}
                >
                  <i className="materialicons phoneIcon">phone</i>
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}

      {authService.isUserLoggedIn() && globalContextState.deviceWidth >= 768 && (
        <div className="navigationHeader">
          <div className="navbarText desktopView">
            {!initData?.isCustomerSite && (
              <nav className="navbar navbar-expand-lg">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink id="ma-dt-nav-home" title={''} className="nav-link" to="/dashboard">
                      <i className="materialicons home">home</i>
                      <span className="navText">Home</span>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink id="ma-dt-nav-paymentCenter" title={''} className="nav-link" to="/payment-center">
                      <i className="materialicons attachMoney">attach_money</i>
                      <span className="navText">Payment Center</span>
                    </NavLink>
                  </li>

                  <li id="ma-dt-nav-products" className="nav-item">
                    <NavLink title={''} className="nav-link" to="/products-main">
                      <i className="materialicons description">description</i>
                      <span className="navText">Products</span>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            )}
            {!initData?.isCustomerSite
              ? authService.isUserLoggedIn() && (
                  <div className="loginProfile">
                    <span className="profile" style={{ marginTop: '2px' }}>
                      <NavLink id="ma-dt-nav-profile" className="header-nav-link" title={'Profile'} to="/my-profile">
                        Profile
                      </NavLink>
                    </span>
                    {!user?.csUserid && (
                      <>
                        <span className="divider">&nbsp;</span>
                        <button className="logOut" onClick={logout}>
                          <span id="ma-dt-nav-logout" className="logout-btn">
                            Logout
                          </span>
                        </button>
                      </>
                    )}
                  </div>
                )
              : authService.isUserLoggedIn() && (
                  <div className="loginProfile">
                    <button className="logOut" onClick={logout}>
                      <span id="ma-dt-nav-logout" className="logout-btn">
                        Logout
                      </span>
                    </button>
                  </div>
                )}
          </div>
        </div>
      )}
    </>
  );
};

export default HomeNavigationBar;
