import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { InitStore, useInitStore } from 'stores/useInitStore';
import Modal from 'components/common/Modal';
import { ENDPOINTS } from 'constants/endpoints';
import LogoSection from './LogoSection';
import './styles.scss';
import { ARTICLES } from 'constants/constants';
import sharedService from 'services/sharedService';
import { TitleContentPair } from 'interfaces/interfaces';
import { UserStore, useUserStore } from 'stores/useUserStore';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import HomeNavigationBar from './HomeNavigationBar';
import ImpersonateHeader from './ImpersonateHeader';

interface State {
  globalWebContents: Record<string, string>;
  staticContent: TitleContentPair;
}

const Header = () => {
  const [state, setState] = useState<State>({
    staticContent: {
      title: '',
      content: '',
    },
    globalWebContents: {},
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { globalContextState } = useContext(GlobalContext) as GlobalContextType;

  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));

  const { initData } = useInitStore((state: InitStore) => ({
    initData: state.initData,
  }));

  const primaryLogoUrl = `${ENDPOINTS.assetsBaseURL}${initData?.brandDetail.primaryLogo}`;

  const secondLogoUrl = initData?.brandDetail?.secondLogo
    ? `${ENDPOINTS.assetsBaseURL}${initData.brandDetail.secondLogo}`
    : '';

  useEffect(() => {
    const getContent = async (titles: string[]) => {
      const params = {
        titles: titles,
      };
      try {
        const response = await sharedService.getContents(params);
        if (response.status === 200) {
          const webContents: { [key: string]: string } = {};
          response?.data?.items?.forEach((element: TitleContentPair) => {
            // Set header accordingly for mobile and desktop
            const domObj = document.getElementById(`globalWC_${element.title}`);
            const headerCallText = document.querySelector('.headerCallText a') as HTMLAnchorElement;
            if (domObj) {
              domObj.innerHTML = element.content;
            }
            if (domObj && headerCallText) {
              headerCallText.style.pointerEvents = 'none';
            }
            webContents[element.title] = element.content;
          });
          setState((prevState) => ({ ...prevState, globalWebContents: webContents }));
        }
      } catch (error) {
        console.error(error);
      }
    };
    getContent([ARTICLES.HEADER_PHONE_INFO, ARTICLES.CONTACT_US, ARTICLES.ABOUT_US]);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      // Set header accordingly for mobile and desktop
      const domObj = document.getElementById(`globalWC_${ARTICLES.HEADER_PHONE_INFO}`);
      const headerCallText = document.querySelector('.headerCallText a') as HTMLAnchorElement;
      if (domObj) {
        domObj.innerHTML = state.globalWebContents[ARTICLES.HEADER_PHONE_INFO];
      }
      if (domObj && headerCallText) {
        headerCallText.style.pointerEvents = 'none';
      }
    });
  }, [state.globalWebContents]);

  const handleModalClose = () => setIsModalOpen(false);

  return (
    <>
      {user?.csUserid && <ImpersonateHeader />}
      <div className="mainHeader">
        <div className="innerHeaderBody">
          <LogoSection
            primaryLogoUrl={primaryLogoUrl}
            secondLogoUrl={secondLogoUrl}
            primaryLogoAnchorLink={initData?.brandDetail?.primaryLogoAnchorLink}
          />
          {globalContextState.deviceWidth >= 768 && (
            <div className="headerRightColumn desktopView">
              <div className="d-none d-sm-none d-md-block contactAbout">
                <span>
                  {initData?.showContactUs && (
                    <Link
                      title={'Contact Us'}
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setState((prevState) => ({
                          ...prevState,
                          staticContent: {
                            title: 'Contact Us',
                            content: prevState.globalWebContents[ARTICLES['CONTACT_US']],
                          },
                        }));
                        setIsModalOpen(true);
                      }}
                    >
                      Contact Us
                    </Link>
                  )}
                </span>
                {initData?.showAboutUs && initData?.showContactUs && <span className="divider"></span>}
                <span>
                  {initData?.showAboutUs && (
                    <Link
                      title={'About Us'}
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setState((prevState) => ({
                          ...prevState,
                          staticContent: {
                            title: 'About Us',
                            content: prevState.globalWebContents[ARTICLES['ABOUT_US']],
                          },
                        }));
                        setIsModalOpen(true);
                      }}
                    >
                      About Us
                    </Link>
                  )}
                </span>
              </div>
              {!initData?.isCustomerSite && (
                <div className="headerNavRight">
                  <span className="d-none d-sm-none d-md-block">
                    {globalContextState.deviceWidth >= 768 && (
                      <span id={`globalWC_${ARTICLES.HEADER_PHONE_INFO}`} className="headerCallText"></span>
                    )}
                    {secondLogoUrl && (
                      <span className="rightSecLogo">
                        <img alt="" src={secondLogoUrl} />
                      </span>
                    )}
                  </span>
                </div>
              )}
            </div>
          )}

          {globalContextState.showNavigationBar && <HomeNavigationBar globalWebContents={state.globalWebContents} />}
        </div>
      </div>
      {isModalOpen && (
        <Modal title={state.staticContent.title} onClose={handleModalClose}>
          <p dangerouslySetInnerHTML={{ __html: state.staticContent.content }}></p>
        </Modal>
      )}
    </>
  );
};

export default Header;
