import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { Overlay, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';

import { ARTICLES } from 'constants/constants';
import { Product } from 'interfaces/interfaces';
import { convertStringToAmount } from 'utils/utils';

interface ProductsDesktopProps {
  products: Product[];
  globalWebContents: Record<string, string>;
  selectedProduct: Product | null;
  radioBtnChange: (selectedProduct: Product) => void;
  handlePayNow: (selectedProduct: Product | null) => void;
  handleChangeFreqAutoPay: (selectedProduct: Product) => Promise<void>;
  getPaymentInfo: (policyNumber: string) => Promise<void>;
  getDisclaimers: () => ReactElement[];
}

interface State {
  showToolTip: boolean;
  toolTipTarget: HTMLElement | null;
}

const ProductsDesktop = ({
  products,
  globalWebContents,
  selectedProduct,
  radioBtnChange,
  handlePayNow,
  handleChangeFreqAutoPay,
  getPaymentInfo,
  getDisclaimers,
}: ProductsDesktopProps) => {
  const [state, setState] = useState<State>({
    showToolTip: false,
    toolTipTarget: null,
  });

  const getPayNowBtn = () => {
    const isAutoPay = selectedProduct && selectedProduct.paymentStatus === 'Auto Pay';
    const btnLabel = selectedProduct ? selectedProduct.paymentAction.payNowLink.label : 'Pay Now';
    if (!selectedProduct || isAutoPay) {
      return (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 150 }}
          overlay={
            <Tooltip>
              <div className="tooltipHover buttonTooltip">
                <div className="flexBox" id="globalWC_payNow">
                  {isAutoPay
                    ? globalWebContents[ARTICLES.PRODUCTS_AUTO_TOOLTIP]
                    : globalWebContents[ARTICLES.PRODUCTS_NO_RADIO]}
                </div>
              </div>
            </Tooltip>
          }
        >
          <span>
            <button className="btn agiaBlueButton disabled">{btnLabel}</button>
          </span>
        </OverlayTrigger>
      );
    } else {
      const enabled = selectedProduct && selectedProduct.paymentAction.payNowLink.disable !== 'Y';
      return (
        <button
          onClick={() => {
            handlePayNow(null);
          }}
          disabled={!enabled}
          className="ma-pc-payNow btn agiaBlueButton payNow"
          type="button"
          value="Submit"
        >
          {btnLabel}
        </button>
      );
    }
  };

  const getChangeFreqBtn = () => {
    const btnLabel = selectedProduct
      ? selectedProduct.paymentAction.changeAutoPayOrFrequency.label
      : 'Change Auto Pay or Frequency';
    let isPastDue;

    if (selectedProduct && selectedProduct.paymentStatusDisplayStyle === 'pastdue') {
      isPastDue = true;
    } else {
      isPastDue = false;
    }

    if (!selectedProduct) {
      return (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 150 }}
          overlay={
            <Tooltip>
              <div className="tooltipHover">
                <div className="flexBox" id="globalWC_changeFreqBtn">
                  {globalWebContents[ARTICLES.TOOLTIP_FREQUENCY_NO_SELECTION]}
                </div>
              </div>
            </Tooltip>
          }
        >
          <span>
            <button
              className="btn agiaBlueButton-outline disabled"
              type="button"
              value="Submit"
              data-toggle="modal"
              data-target="#contactInformation"
            >
              {btnLabel}
            </button>
          </span>
        </OverlayTrigger>
      );
    } else if (isPastDue) {
      const enabled = selectedProduct && selectedProduct.paymentAction.changeAutoPayOrFrequency.disable !== 'Y';
      return (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 150 }}
          overlay={
            <Tooltip>
              <div className="tooltipHover">
                <div className="flexBox">{globalWebContents[ARTICLES.TOOLTIP_DUE_FREQUENCY]}</div>
              </div>
            </Tooltip>
          }
        >
          <span>
            <button
              className={'btn agiaBlueButton-outline ' + (!enabled && 'disabled')}
              type="button"
              value="Submit"
              data-toggle="modal"
              data-target="#contactInformation"
            >
              {btnLabel}
            </button>
          </span>
        </OverlayTrigger>
      );
    } else {
      const enabled = selectedProduct && selectedProduct.paymentAction.changeAutoPayOrFrequency.disable !== 'Y';
      return (
        <button
          onClick={() => {
            if (enabled) handleChangeFreqAutoPay(selectedProduct);
          }}
          className={'ma-pc-autopayFrequency btn agiaBlueButton-outline ' + (!enabled && 'disabled')}
          type="button"
          value="Submit"
        >
          {btnLabel}
        </button>
      );
    }
  };

  const handleToolTip = async (event: React.MouseEvent<HTMLElement>, policyNumber: string) => {
    event.persist();
    setState((prevState) => ({ ...prevState, showToolTip: false }));
    if (!selectedProduct?.paymentInfoLoaded) {
      await getPaymentInfo(policyNumber);
    }
    setState((prevState) => ({
      showToolTip: !prevState.showToolTip,
      toolTipTarget: event.target as HTMLElement,
    }));
  };

  return (
    <div className="desktopView productsTemplate">
      {selectedProduct && selectedProduct.showPaymentAmountBreakdown && (
        <Overlay
          show={state.showToolTip}
          target={state.toolTipTarget}
          placement="auto"
          rootClose={true}
          rootCloseEvent={'click'}
          onHide={() => {
            setState((prevState) => ({ ...prevState, showToolTip: false }));
          }}
        >
          <Popover id="popover-contained">
            <div className="tooltipHover payment-amount-breakdown">
              <div className="flexBox">
                <ul>
                  <li>
                    <span className="label">AMOUNT DUE</span>
                    <span className="value">{selectedProduct.paymentInfo.amountDue || '-'}</span>
                    <span className="tooltip-break-special-chars">
                      <sup>{selectedProduct.paymentInfo.amountDueSpecialCharacter}</sup>
                    </span>
                  </li>
                  <li>
                    <span className="label">Past Due</span>
                    <span className="value">{selectedProduct.paymentInfo.pastDueAmount || '-'}</span>
                    <span className="tooltip-break-special-chars"></span>
                  </li>
                  <li>
                    <span className="label">Adjustment</span>
                    <span className="value">{selectedProduct.paymentInfo.adjustment || '-'}</span>
                    <span className="tooltip-break-special-chars">
                      {convertStringToAmount(selectedProduct.paymentInfo.adjustment) > 0 && '\u00A0CR'}
                    </span>
                  </li>
                  {convertStringToAmount(selectedProduct.paymentInfo.billingCredit) !== 0 && (
                    <li>
                      <span className="label">Billing Credit</span>
                      <span className="value">{selectedProduct.paymentInfo.billingCredit || '-'}</span>
                      <span className="tooltip-break-special-chars">
                        {convertStringToAmount(selectedProduct.paymentInfo.billingCredit) > 0 && '\u00A0CR'}
                      </span>
                    </li>
                  )}
                  <li>
                    <span className="label">TOTAL AMOUNT DUE</span>
                    <span className="value">{selectedProduct.paymentInfo.totalAmountDue || '-'}</span>
                    <span className="tooltip-break-special-chars"></span>
                  </li>
                  {selectedProduct.paymentInfo.disclaimer && (
                    <li>
                      <span className="tooltip-breakdown-disclaimer">{selectedProduct.paymentInfo.disclaimer}</span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </Popover>
        </Overlay>
      )}
      <h2 className="homeHederText">Active Products</h2>
      <table className="table table-light borderless productTable">
        <thead>
          <tr>
            <th scope="col">&nbsp;</th>
            <th className="product-col" scope="col">
              PRODUCT
            </th>
            <th className="payment-frequency-col" scope="col">
              PAYMENT FREQUENCY
            </th>
            <th className="auto-pay-method-col" scope="col">
              AUTO PAY METHOD
            </th>
            <th scope="col" className="amount amount-col">
              AMOUNT DUE
            </th>
            <th className="duedate-col" scope="col">
              DUE DATE
            </th>
          </tr>
        </thead>
        <tbody>
          {products.map((value) => (
            <tr
              tabIndex={0}
              key={value.policyNumber}
              onClick={() => {
                radioBtnChange(value);
              }}
              className={value.paymentStatus && value.paymentStatus.toLowerCase() === 'due now' ? 'selected' : ''}
            >
              <td>
                <div className="custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    checked={!!(selectedProduct && selectedProduct.policyNumber === value.policyNumber)}
                    id={value.policyNumber}
                    name={value.policyNumber}
                  />
                  <span className="materialicons uncheck">radio_button_unchecked</span>
                  <span className="materialicons check">radio_button_checked</span>
                </div>
              </td>
              <td>
                <Link title={''} to={`/product-details/${value.policyNumber}`}>
                  <span className="anchorText">{value.productDesc}</span>
                </Link>
                <span className="smallTableText">{value.policyNumber}</span>
              </td>
              <td>{value.currentPaymentFrequecyType}</td>
              <td>{value.currentPaymentMethod}</td>
              <td className="amount">
                {value.amountDue}
                <sup dangerouslySetInnerHTML={{ __html: value.amountDueSpecialCharacter }}></sup>
                {value.showPaymentAmountBreakdown && (
                  <button
                    onClick={(event) => {
                      handleToolTip(event, value.policyNumber);
                    }}
                    className="materialicons infoIcon"
                  >
                    info_outline
                  </button>
                )}
              </td>
              <td>
                <div className="displayFlex">
                  <span className="dateValue">{value.dueDate}</span>
                  <span className={'paymentStatusBadge ' + value.paymentStatusDisplayStyle}>{value.paymentStatus}</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="roundBottomButton">
        {getPayNowBtn()}
        {getChangeFreqBtn()}
      </div>
      <div className="footerTextPara">
        <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.AUTOPAY_MARKETTING] }}></div>
        <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.SITE_DISCLAIMER] }}></div>
        {getDisclaimers()}
        <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.TRANSACTION_DISCLAIMER] }}></div>
      </div>
    </div>
  );
};

export default ProductsDesktop;
