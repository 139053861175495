import React from 'react';
import { Link } from 'react-router-dom';
import { useTable, usePagination } from 'react-table';

import { Product } from 'interfaces/interfaces';

interface TableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
}

const Table: React.FC<TableProps> = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination,
  );

  return (
    <div className="inActiveContent">
      <table {...getTableProps()} className="table table-light borderless productTable">
        <thead>
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                <th className="product-col" {...headerGroup.headers[0].getHeaderProps()} scope="col">
                  {headerGroup.headers[0].render('Header')}
                </th>
                <th scope="col">&nbsp;</th>
                <th scope="col">&nbsp;</th>
                <th {...headerGroup.headers[1].getHeaderProps()} scope="col" className="amount amount-col">
                  {headerGroup.headers[1].render('Header')}
                </th>
                <th className="duedate-col" {...headerGroup.headers[2].getHeaderProps()} scope="col">
                  {headerGroup.headers[2].render('Header')}
                </th>
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            page.map((row: any) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.id}>
                  <td {...row.cells[0].getCellProps()}>
                    <Link to={`/product-details/${row.values.policyNumber}`}>
                      <span className="anchorText">{row.values.productDesc}</span>
                    </Link>
                    <span className="smallTableText">{row.values.policyNumber}</span>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td {...row.cells[1].getCellProps()} className="amount">
                    {row.values.amountDue}
                  </td>
                  <td {...row.cells[2].getCellProps()}>{row.values.dueDate}</td>
                </tr>
              );
            })
          }
        </tbody>
        {data.length >= 10 && (
          <tfoot>
            <tr>
              <td colSpan={5}>
                <div className="pagination">
                  <span className="rowPages">Rows per page: </span>
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                  <span className="middlePaginationContent">
                    {pageSize * (pageIndex + 1) - (pageSize - 1)} - {pageSize * (pageIndex + 1)} of {data.length}
                  </span>
                  <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <span className="materialicons">first_page</span>
                  </button>
                  <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <span className="materialicons">chevron_left</span>
                  </button>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    <span className="materialicons">chevron_right</span>
                  </button>
                  <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    <span className="materialicons">last_page</span>
                  </button>
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

interface InactiveProductsProps {
  inactiveProducts: Product[];
}

export const InactiveProducts: React.FC<InactiveProductsProps> = ({ inactiveProducts }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Product',
        accessor: 'productDesc',
      },
      {
        Header: 'Amount Paid',
        accessor: 'amountDue',
      },
      {
        Header: 'Paid Through',
        accessor: 'dueDate',
      },
      {
        Header: 'Policy Number',
        accessor: 'policyNumber',
      },
    ],
    [],
  );

  return <Table columns={columns} data={inactiveProducts} />;
};
