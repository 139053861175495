import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { IProduct } from 'interfaces/interfaces';
import DuePaymentCard from './DuePaymentCard';
import NoDuePaymentCard from './NoDuePaymentCard';
import { ARTICLES } from 'constants/constants';

interface SummaryMobileProps {
  dueProducts: IProduct[];
  clientName: string;
  activeProducts: IProduct[];
  hasEAPlusProduct: string;
  globalWebContents: Record<string, string>;
  getDisclaimers: () => ReactElement[];
}

const SummaryMobile = ({
  dueProducts,
  clientName,
  activeProducts,
  hasEAPlusProduct,
  globalWebContents,
  getDisclaimers,
}: SummaryMobileProps) => {
  const [showClaimNow, setShowClaimNow] = useState<boolean>(false);

  useEffect(() => {
    const hasInsuredProduct = !!activeProducts.find((product) => product.isInsuranceProduct === 'Y');
    setShowClaimNow(hasInsuredProduct);
  }, [activeProducts]);

  return (
    <div className="mobileView homePayDues">
      <div className="singleCol">
        <h2 className="homeHederText">Payments Due</h2>
        {dueProducts.length !== 0 &&
          dueProducts.map((value) => (
            <DuePaymentCard key={value.policyNumber} clientName={clientName} dueProduct={value} />
          ))}
        {dueProducts.length === 0 && <NoDuePaymentCard />}
      </div>

      <div className="singleCol activeRowTable">
        <h2 className="homeHederText">Your Active Products</h2>

        {activeProducts.map((value) => (
          <div key={value.policyNumber} className="flexWhiteBox col-lg-12 col-md-12 float-left p-0">
            <div className="leftColumn col-lg-12 col-md-12 col-xs-12 col-12 float-left p-0">
              <div className="headerColumn">
                <h3>
                  <Link title={''} to={`/product-details/${value.policyNumber}`}>
                    {value.productDesc}
                  </Link>
                </h3>
                <div className="btn-links">
                  <span className={'paymentStatusBadge ' + value.paymentStatusDisplayStyle}>{value.paymentStatus}</span>
                </div>
              </div>
              <div className="bodyRow">
                <div className="flexBox">
                  <ul>
                    <li>
                      <span className="label">CERTIFICATE/PLAN NUMBER</span>
                      <span className="value">{value.policyNumber}</span>
                    </li>
                    <li>
                      <span className="label">PAYMENT FREQUENCY</span>
                      <span className="value">{value.currentPaymentFrequecyType}</span>
                    </li>
                    <li>
                      <span className="label">AMOUNT DUE</span>
                      <span className="value">
                        {value.amountDue}
                        <sup dangerouslySetInnerHTML={{ __html: value.amountDueSpecialCharacter }}></sup>
                      </span>
                    </li>
                    <li>
                      <span className="label">DUE DATE</span>
                      <span className="value">{value.dueDate}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
        {activeProducts.length !== 0 && (
          <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.AUTOPAY_MARKETTING] }}></div>
        )}
        {showClaimNow && <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.CLAIM_NOW] }}></div>}
        {hasEAPlusProduct === 'Y' && (
          <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.EA_PLUS_MEMBER_NOTE] }}></div>
        )}
        <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.SITE_DISCLAIMER] }}></div>
        <br />
        <br />
        {getDisclaimers()}
        <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.TRANSACTION_DISCLAIMER] }}></div>
      </div>
    </div>
  );
};

export default SummaryMobile;
