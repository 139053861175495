import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  browserName,
  browserVersion,
  isMobile,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from 'react-device-detect';

import { LOCALIZATION } from 'constants/localization';
import { StepperTab } from './StepperTab';
import Modal from 'components/common/Modal';
import { ARTICLES, PAGE_TITLES } from 'constants/constants';
import { TitleContentPair } from 'interfaces/interfaces';
import sharedService from 'services/sharedService';
import { InitStore, useInitStore } from 'stores/useInitStore';
import authService from 'services/authService';
import { UserStore, useUserStore } from 'stores/useUserStore';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import Notification from 'components/common/Notification';

interface State {
  userName: string;
  password: string;
  confirmPassword: string;
  selectedQuestion: string;
  selectedAnswer: string;
  isAgree: boolean;
  hideNewPassword: boolean;
  hideConfirmPassword: boolean;
  pageTitle: string;
  isFormValid: boolean;
  isTermsAndConditionsModalOpen: boolean;
  isPrivacyPolicyModalOpen: boolean;
  globalWebContents: Record<string, string>;
  validations: {
    greaterThan8: boolean;
    hasLowerCase: boolean;
    hasUpperCase: boolean;
    hasNumber: boolean;
    newConfirmMatch: boolean;
  };
}

interface Question {
  question: string;
  questionId: number;
}

export const SignInCreds = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState<State>({
    userName: '',
    password: '',
    confirmPassword: '',
    selectedQuestion: '',
    selectedAnswer: '',
    isAgree: false,
    pageTitle: '',
    isFormValid: false,
    isTermsAndConditionsModalOpen: false,
    isPrivacyPolicyModalOpen: false,
    hideNewPassword: true,
    hideConfirmPassword: true,
    globalWebContents: {},
    validations: {
      greaterThan8: false,
      hasLowerCase: false,
      hasUpperCase: false,
      hasNumber: false,
      newConfirmMatch: false,
    },
  });
  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { processMessages, notificationsData } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
    notificationsData: state.notificationsData,
  }));

  const { setUser } = useUserStore((state: UserStore) => ({
    setUser: state.setUser,
  }));

  const handleTermsAndConditionsClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, isTermsAndConditionsModalOpen: true }));
  };

  const handlePrivacyPolicyClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, isPrivacyPolicyModalOpen: true }));
  };

  const checkRequiredFields = (state: State) => {
    if (/^\s*$/.test(state.userName)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    if (/^\s*$/.test(state.password)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    if (/^\s*$/.test(state.confirmPassword)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    if (/^\s*$/.test(state.selectedQuestion)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    if (/^\s*$/.test(state.selectedAnswer)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    if (!state.isAgree) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    setState((prevState) => ({ ...prevState, isFormValid: true }));
    return true;
  };

  const passwordValidation = (state: State) => {
    const greaterThan8 = state.password.length >= 8;
    const hasLowerCase = state.password.match(/^(.*[a-z].*)$/);
    const hasUpperCase = state.password.match(/^(.*[A-Z].*)$/);
    const hasNumber = !state.password.match(/^([^0-9]*)$/);
    const newConfirmMatch =
      state.password !== '' && state.confirmPassword !== '' && state.password === state.confirmPassword;

    setState((prevState) => ({
      ...prevState,
      validations: {
        greaterThan8: !!greaterThan8,
        hasLowerCase: !!hasLowerCase,
        hasUpperCase: !!hasUpperCase,
        hasNumber,
        newConfirmMatch,
      },
    }));

    if (greaterThan8 && hasLowerCase && hasUpperCase && hasNumber && newConfirmMatch) {
      setState((prevState) => ({ ...prevState, isFormValid: true }));
      return true;
    } else {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;

    setState((prevState) => {
      const updatedState = { ...prevState, [name]: value };
      const isValid = passwordValidation(updatedState);
      if (isValid) {
        checkRequiredFields(updatedState);
      }
      return updatedState;
    });
  };

  const handleRememberChkBox = () => {
    setState((prevState) => {
      const updatedState = { ...prevState, isAgree: !prevState.isAgree };
      const isValid = passwordValidation(updatedState);
      if (isValid) {
        checkRequiredFields(updatedState);
      }
      return updatedState;
    });
  };

  const handleSubmit = async () => {
    const isPasswordValid = passwordValidation(state);
    const isRequiredValid = checkRequiredFields(state);
    if (isPasswordValid && isRequiredValid && state.isAgree) {
      try {
        setAppLoading(true);
        const { client } = initData ?? {};
        const clientName = localStorage.getItem('clientName');
        const hostName = document.location.hostname;

        const params = {
          hostName: hostName,
          planNumber: location.state.planNumber,
          accountNumber: location.state.accountNumber,
          firstName: location.state.firstName,
          lastName: location.state.lastName,
          email: location.state.email,
          phoneNumber: location.state.phoneNumber,
          zipcode: location.state.zipcode,
          userName: state.userName,
          password: state.password,
          securityQuestion: state.selectedQuestion,
          securityAnswer: state.selectedAnswer,
          client: client as string,
          clientName: clientName as string,
          personNumber: location.state.personNumber,
        };

        const response = await authService.verifySignInCreds(params);
        if (response.status === 200) {
          const params = {
            username: state.userName,
            password: state.password,
            client: client as string,
            isCustomerUser: false,
          };
          try {
            const response = await authService.login(params);
            if (response.status === 200) {
              if (response.data.access_token) {
                setUser(response.data);

                const ipResponse = await authService.getPublicIP();
                const params = {
                  username: state.userName,
                  isCustomerUser: false,
                  client: client as string,
                  clientLoginIp: ipResponse.data?.ip,
                  userDeviceInfo: {
                    userOS: osName,
                    userOSVersion: osVersion,
                    browserName: browserName,
                    browserVersion: browserVersion,
                    isMobile: isMobile ? 'Y' : 'N',
                    mobileModel: mobileModel,
                    mobileVendor: mobileVendor,
                  },
                };
                try {
                  const response = await authService.updateUserInformation(params);
                  if (response.status !== 403) {
                    localStorage.setItem('hideWelcomeTxt', 'true');
                    navigate('/dashboard');
                  }
                } catch (error) {
                  console.error(error);
                }
              }
            } else {
              processMessages(response.data.messages);
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          processMessages(response.data.messages);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setAppLoading(false);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    const { clientName } = initData ?? {};
    setState((prevState) => ({ ...prevState, pageTitle: clientName + PAGE_TITLES.REGISTRATION_LOGIN }));
    const getContent = async (titles: string[]) => {
      try {
        setAppLoading(true);
        const params = {
          titles,
        };
        const response = await sharedService.getContents(params);
        if (response.status === 200) {
          const webContents: Record<string, string> = {};
          response.data.items.forEach((element: TitleContentPair) => {
            webContents[element.title] = element.content;
          });
          setState((prevState) => ({ ...prevState, globalWebContents: webContents }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setAppLoading(false);
      }
    };
    getContent([ARTICLES.TERMS_OF_SERVICE, ARTICLES.PRIVACY_POLICY]);
  }, [initData, setAppLoading]);

  useEffect(() => {
    if (!location.state) {
      navigate('/account-details');
    }
  }, [navigate, location.state]);

  if (!location.state) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>
          {
            // this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.REGISTRATION_PERSONAL] ||
            state.pageTitle
          }
        </title>
      </Helmet>
      <div className="registerWrapper ">
        <div className="registerHeader">
          <h1 className="mainHeadingT">{LOCALIZATION.EN.REGISTRATION_ALL_STEPS}</h1>
          {!!notificationsData.nonInfoNotificaiton.length &&
            notificationsData.nonInfoNotificaiton.map((notification, index) => (
              <Notification key={index} id={index} message={notification} />
            ))}
        </div>
        <StepperTab step={3} />

        <div className="usernameWrapper">
          <div className="lusernameColumn mobileButton loginCred">
            <div className="mt-3 mb-3 mobileView">
              <strong>Step 3: </strong> Login Credentials
            </div>
            <div className="backgroundSetColor">
              <div className="columnLayout">
                <div className="leftCol">
                  <div className="form-group">
                    <div className="username mt-3">
                      <label htmlFor="username"> Username</label>
                      <input
                        onKeyDown={handleKeyDown}
                        className="form-control"
                        type="text"
                        id="Username"
                        onChange={handleChange}
                        placeholder="Username"
                        name="userName"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="password mt-3">
                      <label htmlFor="password">Password</label>
                      <input
                        onKeyDown={handleKeyDown}
                        className="form-control password"
                        type={state.hideNewPassword ? 'password' : 'text'}
                        onChange={handleChange}
                        id="password"
                        placeholder="Password"
                        name="password"
                        required
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            hideNewPassword: !state.hideNewPassword,
                          }));
                        }}
                        className="showHide"
                      >
                        <i className={'far ' + (state.hideNewPassword ? 'fa-eye' : 'fa-eye-slash')}></i>
                      </button>
                    </div>
                  </div>
                  <div className="password-validation-ctnr mobileView">
                    <span>Password must have: </span> <br></br>
                    <span className={state.validations.greaterThan8 ? 'valid' : 'invalid'}>
                      at least 8 characters in length
                    </span>
                    <br></br>
                    <span className={state.validations.hasLowerCase ? 'valid' : 'invalid'}>
                      at least 1 lowercase letter (a-z)
                    </span>
                    <br></br>
                    <span className={state.validations.hasUpperCase ? 'valid' : 'invalid'}>
                      at least 1 uppercase letter (A-Z)
                    </span>
                    <br></br>
                    <span className={state.validations.hasNumber ? 'valid' : 'invalid'}>
                      at least 1 number (i.e. 0-9)
                    </span>
                    <br></br>
                    <span className={state.validations.newConfirmMatch ? 'valid' : 'invalid'}>
                      New and Confirm password must match
                    </span>
                    <br></br>
                  </div>
                  <div className="form-group">
                    <div className="confirmpassword mt-3">
                      <label htmlFor="confirmpassword">Confirm Password</label>
                      <input
                        onKeyDown={handleKeyDown}
                        className="form-control password"
                        onChange={handleChange}
                        type={state.hideConfirmPassword ? 'password' : 'text'}
                        id="confirmpassword"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        required
                      />
                      <button
                        type="button"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            hideConfirmPassword: !state.hideConfirmPassword,
                          }))
                        }
                        className="showHide"
                      >
                        <i className={'far ' + (state.hideConfirmPassword ? 'fa-eye' : 'fa-eye-slash')}></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="leftCol desktopView">
                  <div className="password-validation-ctnr">
                    <span>Password must have: </span> <br></br>
                    <span className={state.validations.greaterThan8 ? 'valid' : 'invalid'}>
                      at least 8 characters in length
                    </span>
                    <br></br>
                    <span className={state.validations.hasLowerCase ? 'valid' : 'invalid'}>
                      at least 1 lowercase letter (a-z)
                    </span>
                    <br></br>
                    <span className={state.validations.hasUpperCase ? 'valid' : 'invalid'}>
                      at least 1 uppercase letter (A-Z)
                    </span>
                    <br></br>
                    <span className={state.validations.hasNumber ? 'valid' : 'invalid'}>
                      at least 1 number (i.e. 0-9)
                    </span>
                    <br></br>
                    <span className={state.validations.newConfirmMatch ? 'valid' : 'invalid'}>
                      New and Confirm password must match
                    </span>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
            <div className="backgroundSetColor">
              <div className="formLayout">
                <h2 className="headingText">Security Question and Answer</h2>
                <div className="securityQuestion">
                  <div>Select a security question</div>
                  <select
                    value={state.selectedQuestion}
                    name="selectedQuestion"
                    onChange={handleChange}
                    className="questionAnswer"
                  >
                    <option value="">Please Select</option>
                    {location.state?.questions?.map((value: Question) => (
                      <option key={value.questionId} value={value.question}>
                        {value.question}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <div className="answer">
                    <label htmlFor="answer">Answer</label>
                    <input
                      onKeyDown={handleKeyDown}
                      className="form-control"
                      type="text"
                      id="answer"
                      onChange={handleChange}
                      name="selectedAnswer"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="privacyPolicy">
              <input
                onKeyDown={handleKeyDown}
                type="checkbox"
                name="iAgree"
                checked={state.isAgree}
                onChange={handleRememberChkBox}
              />
              <span className="material-icons blankCheckbox">check_box_outline_blank</span>
              <span className="material-icons selectedCheckbox">check_box</span>
              <span>
                I agree to the AGIA&nbsp;
                <Link title="Terms and Conditions" to="#" onClick={handleTermsAndConditionsClick}>
                  Terms of Service
                </Link>
                &nbsp;and&nbsp;
                <Link title="Privacy Policy" to="#" onClick={handlePrivacyPolicyClick}>
                  Privacy Policy
                </Link>
              </span>
            </div>
            <div className="roundBottomButton mt-3">
              <button
                id="ma-ra-step3"
                disabled={!(state.isFormValid && state.isAgree)}
                className="btn btn-primary agiaBlueButton"
                onClick={handleSubmit}
                value="Create Account"
              >
                Finish registering my account
              </button>
              <Link
                id="ma-ra-step3Login"
                state={location.state}
                to={{
                  pathname: `/personal-info`,
                }}
              >
                Back
              </Link>
            </div>
          </div>
        </div>
      </div>

      {state.isTermsAndConditionsModalOpen && (
        <Modal
          title="Terms and Conditions"
          onClose={() => setState((prevState) => ({ ...prevState, isTermsAndConditionsModalOpen: false }))}
        >
          <p
            dangerouslySetInnerHTML={{ __html: state.globalWebContents[ARTICLES.TERMS_OF_SERVICE] }}
            id={`globalWC_${ARTICLES.TERMS_OF_SERVICE}`}
            className="noteText"
          />
        </Modal>
      )}
      {state.isPrivacyPolicyModalOpen && (
        <Modal
          title="Privacy Policy"
          onClose={() => setState((prevState) => ({ ...prevState, isPrivacyPolicyModalOpen: false }))}
        >
          <p
            dangerouslySetInnerHTML={{ __html: state.globalWebContents[ARTICLES.PRIVACY_POLICY] }}
            id={`globalWC_${ARTICLES.PRIVACY_POLICY}`}
            className="noteText"
          />
        </Modal>
      )}
    </>
  );
};
