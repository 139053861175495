import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { LOCALIZATION } from 'constants/localization';
import { ARTICLES, PAGE_TITLES } from 'constants/constants';
import sharedService from 'services/sharedService';
import { TitleContentPair } from 'interfaces/interfaces';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import Notification from 'components/common/Notification';

interface State {
  pageTitle: string;
  globalWebContents: Record<string, string>;
}

const Header = () => {
  const [state, setState] = useState<State>({
    globalWebContents: {},
    pageTitle: '',
  });

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { notificationsData } = useNotificationsStore((state: NotificationsStore) => ({
    notificationsData: state.notificationsData,
  }));

  useEffect(() => {
    const { client } = initData ?? {};
    setState((prevState) => ({ ...prevState, pageTitle: client + PAGE_TITLES.FORGOT_PASSWORD }));
    const getContent = async (titles: string[]) => {
      try {
        setAppLoading(true);
        const params = {
          titles,
        };
        const response = await sharedService.getContents(params);
        if (response.status === 200) {
          const webContents: Record<string, string> = {};
          response.data.items.forEach((element: TitleContentPair) => {
            webContents[element.title] = element.content;
          });
          setState((prevState) => ({ ...prevState, globalWebContents: webContents }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setAppLoading(false);
      }
    };
    getContent([ARTICLES.FORGOT_PASSWORD_INFO]);
  }, [initData, setAppLoading]);

  return (
    <>
      <Helmet>
        <title>
          {
            // this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.FORGOT_PASSWORD] ||
            state.pageTitle
          }
        </title>
      </Helmet>
      <h1 className="mainHeadingT">{LOCALIZATION.EN.FORGOT_PASSWORD_1}</h1>
      {!!notificationsData.nonInfoNotificaiton.length &&
        notificationsData.nonInfoNotificaiton.map((notification, index) => (
          <Notification key={index} id={index} message={notification} />
        ))}
      <p
        dangerouslySetInnerHTML={{
          __html: state.globalWebContents[ARTICLES.FORGOT_PASSWORD_INFO],
        }}
      ></p>
    </>
  );
};

export default Header;
