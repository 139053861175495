import { Link, useNavigate } from 'react-router-dom';

import { IProduct } from 'interfaces/interfaces';

interface DuePaymentCardProps {
  dueProduct: IProduct;
  clientName: string;
}

const DuePaymentCard = ({ dueProduct, clientName }: DuePaymentCardProps) => {
  const navigate = useNavigate();

  const handlePayNow = () => {
    if (dueProduct.paymentAction.payNowLink.disable === 'N') {
      navigate('/payment-center/pay-now', {
        state: {
          selectedProduct: dueProduct,
          clientName: clientName,
          fromScreen: 'DASHBOARD',
        },
      });
    }
  };

  return (
    <div className="flexWhiteBox">
      <div className="leftColumn">
        <div className="headerColumn selected">
          <h3>
            <Link title={''} to={`/product-details/${dueProduct.policyNumber}`}>
              {dueProduct.productDesc}
            </Link>
          </h3>
          <div className="btn-links">
            <span className={'paymentStatusBadge ' + dueProduct.paymentStatusDisplayStyle}>
              {dueProduct.paymentStatus}
            </span>
          </div>
        </div>
        <div className="bodyRow">
          <div className="flexBox">
            <ul>
              <li>
                <span className="label">CERTIFICATE/PLAN NUMBER</span>
                <span className="value">{dueProduct.policyNumber}</span>
              </li>
              <li>
                <span className="label">PAYMENT FREQUENCY</span>
                <span className="value">{dueProduct.currentPaymentFrequecyType}</span>
              </li>
              <li>
                <span className="label">DUE DATE</span>
                <span className="value">{dueProduct.dueDate}</span>
              </li>
              <li>
                <span className="label">AMOUNT DUE</span>
                <span className="value">{dueProduct.amountDue}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="footerCol">
          <div className="bottomButton">
            <button
              className={
                'ma-hp-payNow btn agiaBlueButton payNow ' +
                (dueProduct.paymentAction.payNowLink.disable === 'Y' ? 'disabled' : '')
              }
              onClick={handlePayNow}
            >
              Pay now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DuePaymentCard;
