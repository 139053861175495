/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { InitStore, useInitStore } from 'stores/useInitStore';
import sharedService from 'services/sharedService';
import { ARTICLES } from 'constants/constants';
import { TitleContentPair } from 'interfaces/interfaces';
import { UserStore, useUserStore } from 'stores/useUserStore';

const InitOperations = () => {
  const location = useLocation();

  const { triggerGTMDataLayer, getCreditCardsRegex, setPagesTitle } = useContext(GlobalContext) as GlobalContextType;

  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));

  const { initData } = useInitStore((state: InitStore) => ({
    initData: state.initData,
  }));

  const { resetMessages } = useNotificationsStore((state: NotificationsStore) => ({
    resetMessages: state.resetMessages,
  }));

  useEffect(() => {
    if (initData?.gtmId) {
      const tagManagerArgs = { gtmId: initData?.gtmId };
      TagManager.initialize(tagManagerArgs);

      setTimeout(() => {
        triggerGTMDataLayer({
          event: 'MyAccountPageView',
          pagePath: location.pathname,
          pageTitle: `${user?.csUserid ? 'CS:' : ''} ${window.document.title}`,
        });
      }, 3000);
    }
  }, [initData?.gtmId]);

  useEffect(() => {
    getCreditCardsRegex();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    resetMessages();

    // Update GTM Page Views if we have GTM id
    setTimeout(() => {
      triggerGTMDataLayer({
        event: 'MyAccountPageView',
        pagePath: location.pathname,
        pageTitle: `${user?.csUserid ? 'CS:' : ''} ${window.document.title}`,
      });
    }, 500);
  }, [location.pathname]);

  useEffect(() => {
    // Set Header Tags from web content
    const getContent = async (titles: string[]) => {
      const params = {
        titles: titles,
      };
      try {
        const response = await sharedService.getContents(params);
        if (response.status === 200 && response.data.items) {
          const hasHeadersTags = response.data.items.find(
            (item: TitleContentPair) => item.title === ARTICLES.HEADER_TAGS,
          );
          const hasBodyStart = response.data.items.find(
            (item: TitleContentPair) => item.title === ARTICLES.CUSTOM_SCRIPTS_BODY_START,
          );
          const hasBodyEnd = response.data.items.find(
            (item: TitleContentPair) => item.title === ARTICLES.CUSTOM_SCRIPTS_BODY_END,
          );

          // Append tags in Head
          if (hasHeadersTags) {
            const tagStr = hasHeadersTags.content;
            document.getElementsByTagName('head')[0].insertAdjacentHTML('beforeend', tagStr);

            setTimeout(() => {
              // execute scirpt in head src
              const s = document.getElementsByTagName('head')[0].getElementsByTagName('script');
              for (const element of s) {
                const node = element,
                  parent = node.parentElement,
                  d = document.createElement('script');
                d.async = node.async;
                d.src = node.src;
                parent?.insertBefore(d, node);
                parent?.removeChild(node);
              }
            }, 500);
          }

          // Append Custom Scripts at body start
          if (hasBodyStart) {
            loadCustomScript(hasBodyStart);
          }

          // Append Custom Scripts at body end
          if (hasBodyEnd) {
            loadCustomScript(hasBodyEnd);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    getContent([ARTICLES.HEADER_TAGS, ARTICLES.CUSTOM_SCRIPTS_BODY_START, ARTICLES.CUSTOM_SCRIPTS_BODY_END]);
  }, []);

  useEffect(() => {
    const getPagesTitle = async (titles: string[]) => {
      const params = {
        titles: titles,
      };

      try {
        const response = await sharedService.getPagesTitle(params);
        if (response.status === 200) {
          setPagesTitle(JSON.parse(response.data.items[0].content));
        }
      } catch (error) {
        console.error(error);
      }
    };
    getPagesTitle([ARTICLES.PAGES_TITLE]);
  }, []);

  const loadCustomScript = (scriptsData: TitleContentPair) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(scriptsData.content, 'text/html');
    const body = document.getElementsByTagName('body')[0];
    const scriptTags = Array.from(doc.getElementsByTagName('script'));
    scriptTags.forEach((tag) => {
      const newScript = document.createElement('script');
      const inlineScript = document.createTextNode(tag.innerHTML);
      newScript.appendChild(inlineScript);
      if (scriptsData.title === ARTICLES.CUSTOM_SCRIPTS_BODY_START) {
        body.insertBefore(newScript, body.firstChild);
      } else {
        body.appendChild(newScript);
      }
    });
  };

  return null;
};

export default InitOperations;
