import { IProduct } from 'interfaces/interfaces';
import ActiveProducts from './ActiveProducts';
import DuePaymentCard from './DuePaymentCard';

interface SummaryVerticalProps {
  dueProducts: IProduct[];
  clientName: string;
  activeProducts: IProduct[];
  hasEAPlusProduct: string;
  globalWebContents: Record<string, string>;
}

const SummaryVertical = ({
  dueProducts,
  clientName,
  activeProducts,
  hasEAPlusProduct,
  globalWebContents,
}: SummaryVerticalProps) => {
  return (
    <div className="desktopView homePayDues twoColLayout">
      <h2 className="homeHederText">Payments Due</h2>
      {dueProducts.map((value) => (
        <div key={value.policyNumber} className="leftCol">
          <DuePaymentCard clientName={clientName} dueProduct={value} />
        </div>
      ))}

      <div className="rightCol">
        <h2 className="homeHederText">Your Active Products</h2>
        <ActiveProducts
          activeProducts={activeProducts}
          hasEAPlusProduct={hasEAPlusProduct}
          globalWebContents={globalWebContents}
        />
      </div>
    </div>
  );
};

export default SummaryVertical;
