import { Navigate, Outlet } from 'react-router-dom';

import Layout from 'components/Layout';
import authService from 'services/authService';
import PaymentCenterLayout from 'components/PaymentCenterLayout';

const PaymentCenterRoutes = () => {
  return authService.isUserLoggedIn() ? (
    <Layout>
      <PaymentCenterLayout>
        <Outlet />
      </PaymentCenterLayout>
    </Layout>
  ) : (
    <Navigate to="/" />
  );
};

export default PaymentCenterRoutes;
