import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

import { PaymentMethod, Product } from 'interfaces/interfaces';
import { PaymentMethodOptions } from '../../PaymentCenterProducts';
import { convertStringToAmount } from 'utils/utils';

interface PayNowMobileProps {
  product: Product;
  hasAnyPaymentMethods: boolean;
  selectedPaymentMethodRecId: string;
  handleDropDownChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  paymentMethods: (PaymentMethod | PaymentMethodOptions)[];
  handleOnEdit: (selectedPaymentMethod: PaymentMethod | null) => void;
  selectedPaymentMethodObj: PaymentMethod | null;
  confirmPaynow: () => void;
  isFormValid: boolean;
}

const PayNowMobile = ({
  product,
  hasAnyPaymentMethods,
  selectedPaymentMethodRecId,
  handleDropDownChange,
  paymentMethods,
  handleOnEdit,
  selectedPaymentMethodObj,
  confirmPaynow,
  isFormValid,
}: PayNowMobileProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [disableControls, setDisableControls] = useState<boolean>(true);

  useEffect(() => {
    const isSubmitDisabled = product.paymentAction.submitPayment && product.paymentAction.submitPayment.disable === 'Y';
    const hasMessage = !!product.paymentAction.payNowLink.message;
    const disableControls = isSubmitDisabled && hasMessage;
    setDisableControls(disableControls);
  }, [product.paymentAction.payNowLink.message, product.paymentAction.submitPayment]);

  return (
    <div className="mobileView payNowMobile">
      <div className="singleCol">
        <div className="flexWhiteBox">
          <h2 className="homeHederText">Product</h2>
          <div className="leftColumn">
            <div className="headerColumn">
              <h3>
                <Link title={''} to={`/product-details/${product.policyNumber}`}>
                  {product.productDesc}
                </Link>
              </h3>
              <div className="btn-links">
                <span className={'paymentStatusBadge ' + product.paymentStatusDisplayStyle}>
                  {product.paymentStatus}
                </span>
              </div>
            </div>
            <div className="bodyRow">
              <div className="flexBox">
                <ul>
                  <li>
                    <span className="label">CERTIFICATE/PLAN NUMBER</span>
                    <span className="value">{product.policyNumber}</span>
                  </li>
                  <li>
                    <span className="label">PAYMENT FREQUENCY</span>
                    <span className="value">{product.currentPaymentFrequecyType}</span>
                  </li>
                  <li>
                    <span className="label">DUE DATE</span>
                    <span className="value">{product.dueDate}</span>
                  </li>
                  <li>
                    <span className="label">AMOUNT DUE</span>
                    <span className="value">
                      {!product.paymentInfo && <span>{product.amountDue}</span>}
                      {product.paymentInfo && (
                        <span>
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              setOpen((prevState) => !prevState);
                            }}
                            aria-controls="payNow-collapse-text"
                            aria-expanded={open}
                            className="payNow-collapse-text"
                            to="#"
                          >
                            <span className="priceValue">{product.amountDue}</span>
                            <span className="materialicons leftArrow">chevron_left</span>
                            <span className="materialicons downArrow">expand_more</span>
                          </Link>
                        </span>
                      )}
                    </span>
                  </li>
                  {product.paymentInfo && (
                    <Collapse in={open}>
                      <div id="payNowCollapseText">
                        <div className="bodyRow">
                          <div className="flexBox">
                            <ul>
                              <li>
                                <span className="label">AMOUNT DUE</span>
                                <span className="value">{product.paymentInfo.amountDue || '-'}</span>
                                <span className="tooltip-break-special-chars">
                                  <sup>{product.paymentInfo.amountDueSpecialCharacter}</sup>
                                </span>
                              </li>
                              <li>
                                <span className="label">PAST DUE</span>
                                <span className="value">{product.paymentInfo.pastDueAmount || '-'}</span>
                                <span className="tooltip-break-special-chars"></span>
                              </li>
                              <li>
                                <span className="label">ADJUSTMENT</span>
                                <span className="value">{product.paymentInfo.adjustment || '-'} </span>
                                <span className="tooltip-break-special-chars">
                                  {convertStringToAmount(product.paymentInfo.adjustment) > 0 && '\u00A0CR'}
                                </span>
                              </li>
                              {convertStringToAmount(product.paymentInfo.billingCredit) !== 0 && (
                                <li>
                                  <span className="label">Billing Credit</span>
                                  <span className="value">{product.paymentInfo.billingCredit || '-'} </span>
                                  <span className="tooltip-break-special-chars">
                                    {convertStringToAmount(product.paymentInfo.billingCredit) > 0 && '\u00A0CR'}
                                  </span>
                                </li>
                              )}
                              <li>
                                <span className="label">TOTAL AMOUNT DUE</span>
                                <span className="value">{product.paymentInfo.totalAmountDue || '-'}</span>
                                <span className="tooltip-break-special-chars"></span>
                              </li>
                              {product.paymentInfo.disclaimer && (
                                <li>
                                  <span className="tooltip-breakdown-disclaimer">{product.paymentInfo.disclaimer}</span>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {hasAnyPaymentMethods && (
          <div className="flexWhiteBox">
            <h2 className="homeHederText">Payment Method</h2>
            <div className="rightColumn">
              <div className="headerColumn">
                <select
                  value={selectedPaymentMethodRecId}
                  onChange={handleDropDownChange}
                  name="selectedPaymentMethodRecId"
                  disabled={disableControls}
                >
                  {paymentMethods.map((value) => (
                    <option key={value.paymentRecordId} value={value.paymentRecordId}>
                      {value.paymentMethodHeader}
                    </option>
                  ))}
                </select>
                <div className="btn-links-edit">
                  <Link
                    //
                    title={''}
                    to="#"
                    className={disableControls ? 'disabled-link-btn' : ''}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!disableControls) handleOnEdit(selectedPaymentMethodObj);
                    }}
                  >
                    Edit
                  </Link>
                </div>
              </div>
              <div className="bodyRow">
                <div className="flexBox">
                  {selectedPaymentMethodObj?.bankAccount == null && (
                    <ul>
                      <li>
                        <span className="label">PAYMENT TYPE</span>
                        <span className="value">{selectedPaymentMethodObj?.paymentMethodHeader}</span>
                      </li>
                      <li>
                        <span className="label">CARDHOLDER NAME</span>
                        <span className="value single-line-ellipses" title={selectedPaymentMethodObj?.cardHolderName}>
                          {selectedPaymentMethodObj?.cardHolderName}
                        </span>
                      </li>
                      <li>
                        <span className="label">CARD NUMBER</span>
                        <span className="value">{selectedPaymentMethodObj?.creditCard.cardNumber}</span>
                      </li>
                      <li>
                        <span className="label">CARD EXPIRATION</span>
                        <span className="value">{selectedPaymentMethodObj?.creditCard.expirationDate}</span>
                      </li>
                    </ul>
                  )}
                  {selectedPaymentMethodObj?.creditCard == null && (
                    <ul>
                      <li>
                        <span className="label">PAYMENT TYPE</span>
                        <span className="value">{selectedPaymentMethodObj?.paymentMethodHeader}</span>
                      </li>
                      <li>
                        <span className="label">ACCOUNT-HOLDER NAME</span>
                        <span className="value">{selectedPaymentMethodObj?.cardHolderName}</span>
                      </li>
                      <li>
                        <span className="label">ACCOUNT NUMBER</span>
                        <span className="value">{selectedPaymentMethodObj?.bankAccount.accountNumber}</span>
                      </li>
                      <li>
                        <span className="label">ROUTING NUMBER</span>
                        <span className="value">{selectedPaymentMethodObj?.bankAccount.maskedRoutingNumber}</span>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {!hasAnyPaymentMethods && (
          <div className="flexWhiteBox payNowaddPaymentM">
            <div className="leftColumn">
              <div className="text">No payment methods found</div>

              <div className="roundBottomButton">
                <button
                  onClick={() => {
                    handleOnEdit(null);
                  }}
                  disabled={disableControls}
                  className="btn agiaBlueButton"
                  type="button"
                  value="Submit"
                >
                  <span className="materialicons">add</span>
                  <span className="navText">Add Payment Method</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="bottomButton equalWidth">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="btn agiaBlueButton-outline payNow"
          type="button"
          value="Submit"
        >
          Go back
        </button>
        <button
          id="ma-pn-submitPayment"
          onClick={() => {
            confirmPaynow();
          }}
          disabled={!isFormValid || !hasAnyPaymentMethods || disableControls}
          className="btn agiaBlueButton payNow"
          type="button"
          value="Submit"
        >
          Submit Payment
        </button>
      </div>
    </div>
  );
};

export default PayNowMobile;
