import React, { useCallback, useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from 'react-router-dom';

import Notification from 'components/common/Notification';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { UserStore, useUserStore } from 'stores/useUserStore';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { NOTIFICATIONS } from 'constants/constants';
import { phoneFormatting, extractDigits, numberAndDash } from 'utils/utils';
import profileService from 'services/profileService';
import { UpdateProfileParams } from 'interfaces/apiRequestInterfaces';
import { AxiosError, Notification as NotificationType, UserProfileType } from 'interfaces/interfaces';

interface ChangeContactInfoPopupProps {
  show: boolean;
  hide: (data?: { messages: NotificationType[]; userProfile: UserProfileType | null }) => void;
  userProfile: {
    phoneNumber: string;
    address: {
      addressLine1: string;
      addressLine2?: string;
      addressLine3?: string;
      stateCode: string;
      cityName: string;
      zipCode: string;
    };
    policyHolder: {
      emailAddress: string;
      personNumber: string;
    };
    editOption: {
      addressEdit: { disable?: string };
      phoneNumberEdit: { disable?: string };
      emailEdit: { disable?: string };
      save: { disable?: string };
    };
  };
}

const ChangeContactInfoPopup: React.FC<ChangeContactInfoPopupProps> = ({ show, hide, userProfile }) => {
  const location = useLocation();
  const [address1, setAddress1] = useState<string>(userProfile.address.addressLine1);
  const [address2, setAddress2] = useState<string>(userProfile.address.addressLine2 ?? '');
  const [address3, setAddress3] = useState<string>(userProfile.address.addressLine3 ?? '');
  const [stateCode, setStateCode] = useState<string>(userProfile.address.stateCode);
  const [city, setCity] = useState<string>(userProfile.address.cityName);
  const [zip, setZip] = useState<string>(userProfile.address.zipCode);
  const [phone, setPhone] = useState<string>(phoneFormatting(userProfile.phoneNumber));
  const [email, setEmail] = useState<string>(userProfile.policyHolder.emailAddress);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const { globalContextState, triggerGTMDataLayer } = useContext(GlobalContext) as GlobalContextType;
  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));
  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));
  const { setPopupNotifications, notificationsData, resetPopupNotifications } = useNotificationsStore(
    (state: NotificationsStore) => ({
      setPopupNotifications: state.setPopupNotifications,
      notificationsData: state.notificationsData,
      resetPopupNotifications: state.resetPopupNotifications,
    }),
  );

  const checkRequiredFields = useCallback(() => {
    if (
      /^\s*$/.test(address1) ||
      /^\s*$/.test(stateCode) ||
      /^\s*$/.test(city) ||
      /^\s*$/.test(zip) ||
      /^\s*$/.test(email)
    ) {
      setIsFormValid(false);
      return;
    }
    setIsFormValid(true);
  }, [address1, stateCode, city, zip, email]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === 'address1') setAddress1(value);
    if (name === 'address2') setAddress2(value);
    if (name === 'address3') setAddress3(value);
    if (name === 'stateCode') setStateCode(value);
    if (name === 'city') setCity(value);
    if (name === 'zip') setZip(value);
    if (name === 'phone') setPhone(value);
    if (name === 'email') setEmail(value);
    checkRequiredFields();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const checkValidation = (): string | null => {
    let errorString = '';

    // Validation for phone
    if (phone !== '') {
      const phoneDigits = extractDigits(phone);
      if (!/^\d+$/.test(phoneDigits) || phoneDigits?.length !== 10) {
        errorString += NOTIFICATIONS.INVALID_PHONE + '\n';
      }
    }

    // Validation for email
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(String(email.trim()).toLowerCase())) {
      errorString += NOTIFICATIONS.INVALID_EMAIL_FORMAT + '\n';
    }

    return errorString || null;
  };

  const formatPhone = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const normalized = phoneFormatting(value);
    setPhone(normalized);
    checkRequiredFields();
  };

  const handleSubmit = async () => {
    try {
      if (isFormValid) {
        const hasValidationError = checkValidation();
        if (hasValidationError === null) {
          const hostName = document.location.hostname;
          const params: UpdateProfileParams = {
            addressLine1: address1,
            addressLine2: address2,
            addressLine3: address3,
            cityName: city,
            stateCode: stateCode,
            zipCode: zip,
            emailAddress: email,
            phoneNumber: phone !== '' ? extractDigits(phone) : '',
            personNumber: userProfile.policyHolder.personNumber,
            userName: user?.userid as string,
            hostName: hostName,
          };
          setAppLoading(true);
          const response = await profileService.updateProfile(params);
          if (response.status === 200) {
            resetPopupNotifications();
            hide({
              messages: response.data.messages,
              userProfile: response.data,
            });
          } else {
            setPopupNotifications(response.data.messages);
          }
        } else {
          setPopupNotifications([
            {
              type: 'ERROR',
              content: [hasValidationError],
            },
          ]);
        }
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      setPopupNotifications(axiosError?.response?.data?.messages || ['An unexpected error occurred.']);
    } finally {
      setAppLoading(false);
    }
  };

  const checkZip = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (numberAndDash(e.target.value)) {
      handleChange(e);
    }
  };

  useEffect(() => {
    checkRequiredFields();
  }, [userProfile, checkRequiredFields]);

  useEffect(() => {
    triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: '/change-contact-info',
      pageTitle: `${user?.csUserid ? 'CS:' : ''} ${initData?.clientName} - Change Contact Information`,
    });

    return () => {
      triggerGTMDataLayer({
        event: 'MyAccountPageView',
        pagePath: window.location.pathname,
        pageTitle: `${user?.csUserid ? 'CS:' : ''} ${window.document.title}`,
      });
    };
  }, [initData?.clientName, triggerGTMDataLayer, location.pathname, user?.csUserid]);

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={() => {
        resetPopupNotifications();
        hide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Contact Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {notificationsData.popupNotifications?.map((notification, index) => (
          <Notification key={index} id={index} message={notification} />
        ))}
        <div className="formPanel">
          <div className="form-group">
            <div className="address">
              <label htmlFor="address1">Address 1</label>
              <input
                value={address1}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                maxLength={30}
                className="form-control"
                type="text"
                name="address1"
                disabled={userProfile.editOption.addressEdit?.disable === 'Y'}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="address2">
              <label htmlFor="address2">Address 2</label>
              <input
                value={address2}
                onChange={handleChange}
                maxLength={30}
                onKeyDown={handleKeyDown}
                className="form-control"
                type="text"
                name="address2"
                disabled={userProfile.editOption.addressEdit?.disable === 'Y'}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="city">
              <label htmlFor="address3">Address 3</label>
              <input
                value={address3}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                maxLength={30}
                className="form-control"
                type="text"
                name="address3"
                disabled={userProfile.editOption.addressEdit?.disable === 'Y'}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="city">City</label>
            <input
              value={city}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              className="form-control"
              type="text"
              name="city"
              maxLength={30}
              disabled={userProfile.editOption.addressEdit?.disable === 'Y'}
            />
          </div>

          <div className="form-group displayFlex">
            <div className="state">
              <label htmlFor="stateCode">State</label>
              <select
                value={stateCode}
                name="stateCode"
                onChange={handleChange}
                disabled={userProfile.editOption.addressEdit?.disable === 'Y'}
              >
                <option value="">Please Select</option>
                {globalContextState.states?.map((state) => (
                  <option key={state.stateCode} value={state.stateCode}>
                    {state.stateCode}
                  </option>
                ))}
              </select>
            </div>

            <div className="zipCode">
              <label htmlFor="zip">Zip Code</label>
              <input
                value={zip}
                onChange={checkZip}
                onKeyDown={handleKeyDown}
                className="form-control"
                type="text"
                name="zip"
                maxLength={10}
                disabled={userProfile.editOption.addressEdit?.disable === 'Y'}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="phoneNumber">
              <label htmlFor="phone">Phone Number</label>
              <input
                value={phone}
                onChange={formatPhone}
                onKeyDown={handleKeyDown}
                className="form-control"
                type="text"
                name="phone"
                disabled={userProfile.editOption.phoneNumberEdit?.disable === 'Y'}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="email">
              <label htmlFor="email">Email</label>
              <input
                value={email}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                className="form-control"
                type="text"
                name="email"
                disabled={userProfile.editOption.emailEdit?.disable === 'Y'}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn agiaBlueButton-outline"
          data-dismiss="modal"
          onClick={() => {
            resetPopupNotifications();
            hide();
          }}
        >
          Cancel
        </button>

        <button
          disabled={!isFormValid || (userProfile.editOption.save && userProfile.editOption.save.disable === 'Y')}
          type="button"
          className="btn agiaBlueButton"
          onClick={handleSubmit}
        >
          Save changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeContactInfoPopup;
