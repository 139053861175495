import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { User } from 'interfaces/interfaces';

export interface CSUserStore {
  user: User | null;
  setUser: (user: User | null) => void;
  resetCSUserStore: () => void;
}

const initialState = {
  user: null,
};

export const useCSUserStore = create<CSUserStore>()(
  persist(
    (set) => ({
      ...initialState,
      setUser: (user: User | null) => set(() => ({ user })),
      resetCSUserStore: () => set(() => ({ ...initialState })),
    }),
    {
      name: 'csuser-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
