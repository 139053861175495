import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import './styles.scss';
import { AxiosError, Notification as INotification, PaymentMethod } from 'interfaces/interfaces';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import Notification from 'components/common/Notification';
import CreditCardForm from './CreditCardForm';
import BankAccountForm from './BankAccountForm';
import { CARDS_TYPE, NOTIFICATIONS } from 'constants/constants';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { UserStore, useUserStore } from 'stores/useUserStore';
import { InitStore, useInitStore } from 'stores/useInitStore';
import paymentCenterService from 'services/paymentCenterService';
import { UpdatePaymentMethodParams } from 'interfaces/apiRequestInterfaces';

interface PaymentMethodModalProps {
  show: boolean;
  hide: (data?: PaymentMethod[], messages?: INotification[]) => void;
  selectedPaymentMethod: PaymentMethod | null;
}

interface State {
  creditCardForm: CreditCardForm;
  bankAccountForm: BankAccountForm;
  showRememberMe: boolean;
  rememberMe: boolean;
  selectedForm: string;
  isFormValid: boolean;
}

export interface CreditCardForm {
  selectedCardType: string;
  cardHolderName: string;
  cardNumber: string;
  expMonth: string;
  expYear: string;
  address1: string;
  address2: string;
  address3: string;
  selectedState: string;
  city: string;
  zip: string;
  editMode: boolean;
}

export interface BankAccountForm {
  type: string;
  accountName: string;
  originalRoutingNumber: string;
  maskedRoutingNumber: string;
  confirmRoutingNumber: string;
  accountNumber: string;
  confirmAccountNumber: string;
  address1: string;
  address2: string;
  address3: string;
  selectedState: string;
  city: string;
  zip: string;
  editMode: boolean;
}

const PaymentMethodModal = ({ show, hide, selectedPaymentMethod }: PaymentMethodModalProps) => {
  const location = useLocation();
  const showRememberCheckboxForPaths = ['/payment-center/pay-now'];
  const [state, setState] = useState<State>({
    creditCardForm: {
      selectedCardType: '',
      cardHolderName: '',
      cardNumber: '',
      expMonth: '',
      expYear: '',
      address1: '',
      address2: '',
      address3: '',
      selectedState: '',
      city: '',
      zip: '',
      editMode: false,
    },
    bankAccountForm: {
      type: 'C',
      accountName: '',
      originalRoutingNumber: '',
      maskedRoutingNumber: '',
      confirmRoutingNumber: '',
      accountNumber: '',
      confirmAccountNumber: '',
      address1: '',
      address2: '',
      address3: '',
      selectedState: '',
      city: '',
      zip: '',
      editMode: false,
    },
    showRememberMe: false,
    rememberMe: false,
    selectedForm: '',
    isFormValid: false,
  });

  const { triggerGTMDataLayer, validateCardDetails } = useContext(GlobalContext) as GlobalContextType;

  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { resetPopupNotifications, notificationsData, setPopupNotifications } = useNotificationsStore(
    (state: NotificationsStore) => ({
      resetPopupNotifications: state.resetPopupNotifications,
      notificationsData: state.notificationsData,
      setPopupNotifications: state.setPopupNotifications,
    }),
  );

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      showRememberMe: showRememberCheckboxForPaths.includes(location.pathname),
    }));

    if (selectedPaymentMethod) {
      if (selectedPaymentMethod.bankAccount) {
        const bankAccountFormData = {
          type: selectedPaymentMethod.bankAccount.paymentType === 'Checking' ? 'C' : 'S',
          accountName: selectedPaymentMethod.cardHolderName,
          originalRoutingNumber: selectedPaymentMethod.bankAccount.originalRoutingNumber,
          maskedRoutingNumber: selectedPaymentMethod.bankAccount.maskedRoutingNumber,
          confirmRoutingNumber: selectedPaymentMethod.bankAccount.originalRoutingNumber,
          accountNumber: selectedPaymentMethod.bankAccount.accountNumber,
          confirmAccountNumber: selectedPaymentMethod.bankAccount.accountNumber,
          address1: selectedPaymentMethod.address.addressLine1,
          address2: selectedPaymentMethod.address.addressLine2 || '',
          address3: selectedPaymentMethod.address.addressLine3 || '',
          selectedState: selectedPaymentMethod.address.stateCode,
          city: selectedPaymentMethod.address.cityName,
          zip: selectedPaymentMethod.address.zipCode,
          editMode: true,
        };

        setState((prevState) => ({ ...prevState, bankAccountForm: bankAccountFormData, selectedForm: 'BANK_ACCOUNT' }));
        checkRequiredFieldsForBankAcc();
      } else {
        const creditCardFormData = {
          selectedCardType:
            CARDS_TYPE[CARDS_TYPE.findIndex((x) => x.name === selectedPaymentMethod.creditCard.paymentType)].id,
          cardHolderName: selectedPaymentMethod.cardHolderName,
          cardNumber: selectedPaymentMethod.creditCard.cardNumber,
          expMonth: selectedPaymentMethod.creditCard.expirationDate.split('/')[0],
          expYear: selectedPaymentMethod.creditCard.expirationDate.split('/')[1],
          address1: selectedPaymentMethod.address.addressLine1,
          address2: selectedPaymentMethod.address.addressLine2 || '',
          address3: selectedPaymentMethod.address.addressLine3 || '',
          selectedState: selectedPaymentMethod.address.stateCode,
          city: selectedPaymentMethod.address.cityName,
          zip: selectedPaymentMethod.address.zipCode,
          editMode: true,
        };

        setState((prevState) => ({ ...prevState, creditCardForm: creditCardFormData, selectedForm: 'CREDIT_CARD' }));
        checkRequiredFieldsForCard();
      }
    } else {
      setState((prevState) => ({ ...prevState, selectedForm: 'CREDIT_CARD' }));
      checkRequiredFieldsForCard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethod]);

  useEffect(() => {
    setTimeout(() => {
      triggerGTMDataLayer({
        event: 'MyAccountPageView',
        pagePath: `/${selectedPaymentMethod ? 'edit' : 'add'}-payment-method`,
        pageTitle: `${user?.csUserid ? 'CS:' : ''} ${initData?.clientName} - ${selectedPaymentMethod ? 'Edit' : 'Add'} Payment Method`,
      });
    }, 1000);
    return () => {
      triggerGTMDataLayer({
        event: 'MyAccountPageView',
        pagePath: location.pathname,
        pageTitle: `${user?.csUserid ? 'CS:' : ''} ${window.document.title}`,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkRequiredFieldsForCard = () => {
    if (/^\s*$/.test(state.creditCardForm.selectedCardType)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.creditCardForm.cardHolderName)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.creditCardForm.cardNumber)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.creditCardForm.expMonth)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.creditCardForm.expYear)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.creditCardForm.address1)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.creditCardForm.selectedState)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.creditCardForm.city)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.creditCardForm.zip)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    setState((prevState) => ({ ...prevState, isFormValid: true }));
  };

  const submitCreditCard = async () => {
    const cardValidation = validateCardDetails(state.creditCardForm.cardNumber, state.creditCardForm.selectedCardType);

    if (selectedPaymentMethod || cardValidation.status === 'SUCCESS') {
      if (state.isFormValid) {
        const params = {
          userName: user?.userid,
          isCreditCard: state.selectedForm === 'CREDIT_CARD',
          isBankAccount: state.selectedForm === 'BANK_ACCOUNT',
          cardType: state.creditCardForm.selectedCardType,
          cardHolderName: state.creditCardForm.cardHolderName,
          cardNumber: state.creditCardForm.cardNumber,
          expirationDate: state.creditCardForm.expMonth + '/' + state.creditCardForm.expYear,
          addressLine1: state.creditCardForm.address1,
          addressLine2: state.creditCardForm.address2,
          addressLine3: state.creditCardForm.address3,
          city: state.creditCardForm.city,
          state: state.creditCardForm.selectedState,
          zipCode: state.creditCardForm.zip,
          methodType: CARDS_TYPE[CARDS_TYPE.findIndex((x) => x.id === state.creditCardForm.selectedCardType)].name,
          ...(selectedPaymentMethod && { paymentRecordId: selectedPaymentMethod.paymentRecordId }),
        } as UpdatePaymentMethodParams;

        if (selectedPaymentMethod) {
          updatePaymentMethod(params);
        } else {
          addPaymentMethod(params);
        }
      }
    } else {
      setPopupNotifications([
        {
          type: cardValidation.status,
          content: [cardValidation.message],
        },
      ]);
    }
  };

  const updatePaymentMethod = async (params: UpdatePaymentMethodParams) => {
    try {
      setAppLoading(true);
      const response = await paymentCenterService.updatePaymentMethod(params);
      if (response.status === 200) {
        resetPopupNotifications();
        hide(response.data.paymentMethods, response.data.messages);
      } else {
        setPopupNotifications(response.data.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      setPopupNotifications(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  };

  const addPaymentMethod = async (params: UpdatePaymentMethodParams) => {
    if (state.showRememberMe) {
      params['addPaymentMethodFrom'] = 'paynow';
      params['rememberPaymentMethod'] = state.rememberMe ? 'Y' : 'N';
    } else {
      params['addPaymentMethodFrom'] = null;
      params['rememberPaymentMethod'] = null;
    }

    try {
      setAppLoading(true);
      const response = await paymentCenterService.addPaymentMethod(params);
      if (response.status === 200 || response.status === 201) {
        resetPopupNotifications();
        hide(response.data.paymentMethods, response.data.messages);
      } else {
        setPopupNotifications(response.data.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      setPopupNotifications(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  };

  const validateBankAccountDetails = () => {
    let errorString = '';

    if (state.bankAccountForm.accountNumber !== state.bankAccountForm.confirmAccountNumber) {
      errorString = errorString + NOTIFICATIONS.ACCOUNT_NUM_NOT_MATCH + '\n';
    }

    if (state.bankAccountForm.originalRoutingNumber !== state.bankAccountForm.confirmRoutingNumber) {
      errorString = errorString + NOTIFICATIONS.ROUTE_NUM_NOT_MATCH + '\n';
    }

    return errorString;
  };

  const checkRequiredFieldsForBankAcc = () => {
    if (/^\s*$/.test(state.bankAccountForm.originalRoutingNumber)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.bankAccountForm.accountName)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.bankAccountForm.confirmRoutingNumber)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.bankAccountForm.accountNumber)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.bankAccountForm.confirmAccountNumber)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.bankAccountForm.address1)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.bankAccountForm.selectedState)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.bankAccountForm.city)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    if (/^\s*$/.test(state.bankAccountForm.zip)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return;
    }

    setState((prevState) => ({ ...prevState, isFormValid: true }));
  };

  const submitBankAccount = () => {
    if (state.isFormValid) {
      const validationError = validateBankAccountDetails();
      if (!validationError) {
        const params = {
          userName: user?.userid,
          isCreditCard: state.selectedForm === 'CREDIT_CARD',
          isBankAccount: state.selectedForm === 'BANK_ACCOUNT',
          isCheckingAccount: state.bankAccountForm.type === 'C',
          isSavingAccount: state.bankAccountForm.type === 'S',
          nameOnAccount: state.bankAccountForm.accountName,
          originalRoutingNumber: state.bankAccountForm.originalRoutingNumber,
          accountNumber: state.bankAccountForm.accountNumber,
          cardHolderName: state.bankAccountForm.accountName,
          paymentType: state.bankAccountForm.type,
          addressLine1: state.bankAccountForm.address1,
          addressLine2: state.bankAccountForm.address2,
          addressLine3: state.bankAccountForm.address3,
          city: state.bankAccountForm.city,
          state: state.bankAccountForm.selectedState,
          zipCode: state.bankAccountForm.zip,
          methodType: state.bankAccountForm.type === 'C' ? 'Checking' : 'Savings',
          ...(selectedPaymentMethod && { paymentRecordId: selectedPaymentMethod.paymentRecordId }),
        } as UpdatePaymentMethodParams;

        if (selectedPaymentMethod) {
          updatePaymentMethod(params);
        } else {
          addPaymentMethod(params);
        }
      } else {
        setPopupNotifications([
          {
            type: 'ERROR',
            content: [validationError],
          },
        ]);
      }
    }
  };

  const handleSubmit = () => {
    if (state.selectedForm === 'CREDIT_CARD') {
      submitCreditCard();
    } else {
      submitBankAccount();
    }
  };

  const handleCCInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    const creditCardFormData: CreditCardForm = { ...state.creditCardForm };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    creditCardFormData[name] = value;

    setState((prevState) => ({ ...prevState, creditCardForm: creditCardFormData }));
    checkRequiredFieldsForCard();
  };

  const handleBAInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    const bankAccountFormData: BankAccountForm = { ...state.bankAccountForm };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    bankAccountFormData[name] = value;

    setState((prevState) => ({ ...prevState, bankAccountForm: bankAccountFormData }));
    checkRequiredFieldsForBankAcc();
  };

  const toggleTabs = (type: string) => {
    setPopupNotifications([]);
    setState((prevState) => ({ ...prevState, selectedForm: type }));

    if (type === 'CREDIT_CARD') {
      checkRequiredFieldsForCard();
    } else {
      checkRequiredFieldsForBankAcc();
    }
  };

  const handleKeyDownCards = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      submitCreditCard();
    }
  };

  const handleKeyDownBankAccount = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      submitBankAccount();
    }
  };

  const handleRememberChkBox = () => {
    setState((prevState) => ({ ...prevState, rememberMe: !prevState.rememberMe }));
  };

  return (
    <Modal
      className="addPaymentMethodPopup"
      backdrop="static"
      show={show}
      onHide={() => {
        resetPopupNotifications();
        hide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{selectedPaymentMethod ? 'Edit' : 'Add'} Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!selectedPaymentMethod && (
          <ul className="nav">
            <li className="nav-item">
              <div className="custom-control-add custom-radio">
                <input
                  type="radio"
                  id="defaultUnchecked1"
                  name="defaultExampleRadios"
                  checked={state.selectedForm === 'CREDIT_CARD'}
                  value="CREDIT_CARD"
                  onChange={() => {
                    toggleTabs('CREDIT_CARD');
                  }}
                  className={'custom-control-input nav-link' + (state.selectedForm === 'CREDIT_CARD' && 'active')}
                />
                <span className="materialicons uncheck">radio_button_unchecked</span>
                <span className="materialicons check">radio_button_checked</span>
                <label
                  htmlFor="defaultUnchecked1"
                  className={'accountInfoTab ' + (state.selectedForm === 'CREDIT_CARD' && 'active')}
                >
                  Credit Card
                </label>
              </div>
            </li>
            <li className="nav-item">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="defaultUnchecked"
                  name="defaultExampleRadios"
                  checked={state.selectedForm === 'BANK_ACCOUNT'}
                  value="BANK_ACCOUNT"
                  onChange={() => {
                    toggleTabs('BANK_ACCOUNT');
                  }}
                  className={'custom-control-input nav-link ' + (state.selectedForm === 'BANK_ACCOUNT' && 'active')}
                />
                <span className="materialicons uncheck">radio_button_unchecked</span>
                <span className="materialicons check">radio_button_checked</span>
                <label
                  htmlFor="defaultUnchecked"
                  className={'accountInfoTab ' + (state.selectedForm === 'BANK_ACCOUNT' && 'active')}
                >
                  Bank Account
                </label>
              </div>
            </li>
          </ul>
        )}
        {notificationsData.popupNotifications.map((notification, index) => (
          <Notification key={index} id={index} message={notification} />
        ))}
        {state.selectedForm === 'CREDIT_CARD' && (
          <CreditCardForm
            form={state.creditCardForm}
            inputChange={handleCCInputChange}
            handleKeyDown={handleKeyDownCards}
          />
        )}
        {state.selectedForm === 'BANK_ACCOUNT' && (
          <BankAccountForm
            form={state.bankAccountForm}
            inputChange={handleBAInputChange}
            handleKeyDown={handleKeyDownBankAccount}
          />
        )}

        {!selectedPaymentMethod && state.showRememberMe && (
          <div className="rememberMethod">
            <input type="checkbox" name="remember" checked={state.rememberMe} onChange={handleRememberChkBox} />
            <span className="material-icons blankCheckbox">check_box_outline_blank</span>
            <span className="material-icons selectedCheckbox">check_box</span>
            <span>Remember Payment Method</span>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn agiaBlueButton-outline"
          data-dismiss="modal"
          onClick={() => {
            resetPopupNotifications();
            hide();
          }}
        >
          Cancel
        </button>
        <button
          id="ma-pm-saveChanges"
          disabled={
            !state.isFormValid ||
            (selectedPaymentMethod && selectedPaymentMethod.managePaymentMethodOption.saveChanges.disable === 'Y') ||
            !!user?.csUserid
          }
          type="button"
          className="btn agiaBlueButton"
          data-dismiss="modal"
          onClick={handleSubmit}
        >
          Save changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentMethodModal;
