import { useContext, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';

import './styles.scss';
import Modal from 'components/common/Modal';
import { ARTICLES } from 'constants/constants';
import { InitStore, useInitStore } from 'stores/useInitStore';
import sharedService from 'services/sharedService';
import { AxiosError, TitleContentPair } from 'interfaces/interfaces';
import { UserStore, useUserStore } from 'stores/useUserStore';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import authService from 'services/authService';
import { ENDPOINTS } from 'constants/endpoints';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { CSUserStore, useCSUserStore } from 'stores/useCSUserStore';

const Footer = () => {
  const navigate = useNavigate();
  const [globalWebContents, setGlobalWebContents] = useState({});
  const [staticContent, setStaticContent] = useState<Record<string, string>>({
    title: '',
    content: '',
  });
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [openPanel, setOpenPanel] = useState<boolean>(false);

  const { globalContextState } = useContext(GlobalContext) as GlobalContextType;

  const { user, setUser } = useUserStore((state: UserStore) => ({
    user: state.user,
    setUser: state.setUser,
  }));

  const { csuser, setCSUser } = useCSUserStore((state: CSUserStore) => ({
    csuser: state.user,
    setCSUser: state.setUser,
  }));

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { processMessages } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
  }));

  useEffect(() => {
    const getContent = async (titles: string[]) => {
      const params = {
        titles: titles,
      };

      try {
        const response = await sharedService.getContents(params);
        if (response.status === 200) {
          const webContents: { [key: string]: string } = {};
          response?.data?.items?.forEach((element: TitleContentPair) => {
            webContents[element.title] = element.content;
          });
          setGlobalWebContents(webContents);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getContent([ARTICLES.CONTACT_US, ARTICLES.ABOUT_US, ARTICLES.TERMS_OF_SERVICE, ARTICLES.PRIVACY_POLICY]);
  }, [initData]);

  const handleTermsAndConditionsClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setStaticContent({
      title: 'Terms and Conditions',
      content: globalWebContents[ARTICLES['TERMS_OF_SERVICE'] as keyof typeof globalWebContents],
    });
    setShowPopup(true);
  };

  const handlePrivacyPolicyClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setStaticContent({
      title: 'Privacy Policy',
      content: globalWebContents[ARTICLES['PRIVACY_POLICY'] as keyof typeof globalWebContents],
    });
    setShowPopup(true);
  };

  const handleCloseModal = () => {
    setShowPopup(false);
  };

  const logout = async () => {
    try {
      setAppLoading(true);
      const response = await authService.logout((user?.access_token ?? csuser?.access_token) as string);
      if (response.status === 200) {
        setUser(null);
        setCSUser(null);
        navigate('/');
      } else {
        processMessages(response.data.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  };

  const handleClosePanel = () => {
    setOpenPanel(false);
  };

  return (
    <>
      {!initData?.isCustomerSite && globalContextState.deviceWidth >= 768 && (
        <div className="footerWrapper float-left d-none d-sm-none d-md-flex">
          <div className="innerFooter row">
            <div className="footerColumn-left col-md-6 col-sm-12 col-xs-12 float-left p-0">
              <span> &copy; {new Date().getFullYear()}. All rights reserved</span>
            </div>
            <div className="footerColumn-right col-md-6 col-sm-12 col-xs-12 float-left p-0">
              <Link
                title="Terms and Conditions"
                id="ma-db-nav-termsOfService"
                to="#"
                onClick={handleTermsAndConditionsClick}
              >
                Terms of Service
              </Link>
              <span>|</span>
              <Link title="Privacy Policy" id="ma-db-nav-privacyPolicy" to="#" onClick={handlePrivacyPolicyClick}>
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <Modal title={staticContent.title} onClose={handleCloseModal}>
          <p dangerouslySetInnerHTML={{ __html: staticContent.content }} />
        </Modal>
      )}
      {!initData?.isCustomerSite &&
        authService.isUserLoggedIn() &&
        globalContextState.deviceWidth <= 767 &&
        globalContextState.showNavigationBar && (
          <div className="mobileFooterNav mobileView">
            <div className="innerfootWapper">
              <div className="navbarText">
                <nav className="navbar navbar-expand-lg">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink id="ma-mb-nav-home" title={''} className="nav-link" to="/dashboard">
                        <span className="materialicons">home</span>
                        <span className="navText">Home</span>
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink id="ma-mb-nav-paymentCenter" title={''} className="nav-link " to="/payment-center">
                        <span className="materialicons">attach_money</span>
                        <span className="navText">Payment Center</span>
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink id="ma-mb-nav-products" title={''} className="nav-link " to="/products-main">
                        <span className="materialicons">description</span>
                        <span className="navText">Products</span>
                      </NavLink>
                    </li>
                    <li className="nav-item notifiacations">
                      <div>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                        <div
                          onClick={() => {
                            setOpenPanel(true);
                          }}
                        >
                          <span className="ax_default materialicons">more_horiz</span>
                          <span className="navText">More</span>
                        </div>
                        <Offcanvas
                          show={openPanel}
                          onHide={handleClosePanel}
                          scroll={true}
                          backdrop={true}
                          placement="end"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                              <div className="logo d-lg-none">
                                <img
                                  alt={''}
                                  className="logoImg"
                                  src={ENDPOINTS.assetsBaseURL + initData?.brandDetail.logoImageUrl}
                                />
                              </div>
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            {/* <div className="headerPanelMore">
                              <div className="logo d-lg-none">
                                <img
                                  alt={''}
                                  className="logoImg"
                                  src={ENDPOINTS.assetsBaseURL + initData?.brandDetail.logoImageUrl}
                                />
                              </div>
                              <button
                                className="close"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenPanel(false);
                                }}
                              >
                                x
                              </button>
                            </div> */}
                            <div className="bodyPanelMore">
                              <div className="callText">
                                <div>For questions or customer service</div>
                                <div>
                                  call{' '}
                                  <Link
                                    title={''}
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setOpenPanel(false);
                                    }}
                                  >
                                    800-888-8888
                                  </Link>
                                </div>
                              </div>
                              <div className="callText">
                                <div>
                                  Trying to file a claim?&nbsp;
                                  <Link
                                    title={''}
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setOpenPanel(false);
                                    }}
                                  >
                                    Click here
                                  </Link>
                                </div>
                              </div>
                              <hr className="horizonLine" />
                              <div className="navbarText">
                                <nav className="navbar navbar-expand-lg">
                                  <ul className="navbar-nav">
                                    <li className="nav-item">
                                      <NavLink
                                        id="ma-ms-nav-home"
                                        title={''}
                                        className="nav-link "
                                        to="/dashboard"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setOpenPanel(false);
                                          navigate('/dashboard');
                                        }}
                                      >
                                        <span className="materialicons">home</span>
                                        <span className="navText">Home</span>
                                      </NavLink>
                                    </li>

                                    <li className="nav-item">
                                      <NavLink
                                        id="ma-ms-nav-paymentCenter"
                                        title={''}
                                        className="nav-link "
                                        to="/payment-center"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setOpenPanel(false);
                                          navigate('/payment-center');
                                        }}
                                      >
                                        <span className="materialicons">attach_money</span>
                                        <span className="navText">Payment Center</span>
                                      </NavLink>
                                    </li>

                                    <li className="nav-item">
                                      <NavLink
                                        id="ma-ms-nav-products"
                                        title={''}
                                        className="nav-link "
                                        to="/products-main"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setOpenPanel(false);
                                          navigate('/products-main');
                                        }}
                                      >
                                        <span className="materialicons">description</span>
                                        <span className="navText">Products</span>
                                      </NavLink>
                                    </li>
                                    <li className="nav-item notifiacations">
                                      <NavLink
                                        id="ma-ms-nav-profile"
                                        title={''}
                                        className="nav-link "
                                        to="my-profile"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setOpenPanel(false);
                                          navigate('/my-profile');
                                        }}
                                      >
                                        <span className="materialicons">person</span>
                                        <span className="navText">Profile</span>
                                      </NavLink>
                                    </li>

                                    {initData?.showAboutUs && (
                                      <li className="nav-item notifiacations">
                                        <NavLink
                                          title={''}
                                          className="nav-link "
                                          to="aboutUs"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setOpenPanel(false);
                                            setStaticContent({
                                              title: 'About Us',
                                              content:
                                                globalWebContents[
                                                  ARTICLES['ABOUT_US'] as keyof typeof globalWebContents
                                                ],
                                            });
                                            setShowPopup(true);
                                          }}
                                        >
                                          <span className="materialicons">people</span>
                                          <span className="navText">About Us</span>
                                        </NavLink>
                                      </li>
                                    )}

                                    {initData?.showContactUs && (
                                      <li className="nav-item notifiacations">
                                        <NavLink
                                          title={''}
                                          className="nav-link "
                                          to="contactUs"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setOpenPanel(false);
                                            setStaticContent({
                                              title: 'Contact Us',
                                              content:
                                                globalWebContents[
                                                  ARTICLES['CONTACT_US'] as keyof typeof globalWebContents
                                                ],
                                            });
                                            setShowPopup(true);
                                          }}
                                        >
                                          <span className="materialicons">contact_phone</span>
                                          <span className="navText">Contact Us</span>
                                        </NavLink>
                                        <hr className="horizonLine bottom" />
                                      </li>
                                    )}

                                    {!user?.csUserid && (
                                      <li className="nav-item notifiacations">
                                        <Link
                                          title={''}
                                          id="ma-ms-nav-logout"
                                          className="nav-link "
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setOpenPanel(false);
                                            logout();
                                          }}
                                        >
                                          <span className="materialicons">logout</span>
                                          <span className="navText">Logout</span>
                                        </Link>
                                      </li>
                                    )}
                                  </ul>
                                </nav>
                              </div>
                              <div className="footerPanelMore">
                                <Link
                                  title={'Privacy Policy'}
                                  id="ma-ms-nav-privacyPolicy"
                                  to="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setOpenPanel(false);
                                    setStaticContent({
                                      title: 'Privacy Policy',
                                      content:
                                        globalWebContents[ARTICLES['PRIVACY_POLICY'] as keyof typeof globalWebContents],
                                    });
                                    setShowPopup(true);
                                  }}
                                >
                                  Privacy Policy
                                </Link>
                                <Link
                                  title={'Terms and Conditions'}
                                  id="ma-ms-nav-termsOfService"
                                  to="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setOpenPanel(false);
                                    setStaticContent({
                                      title: 'Terms and Conditions',
                                      content:
                                        globalWebContents[
                                          ARTICLES['TERMS_OF_SERVICE'] as keyof typeof globalWebContents
                                        ],
                                    });
                                    setShowPopup(true);
                                  }}
                                >
                                  Terms and Conditions
                                </Link>
                              </div>
                            </div>
                          </Offcanvas.Body>
                        </Offcanvas>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default Footer;
