import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import authService from 'services/authService';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { UserStore, useUserStore } from 'stores/useUserStore';
import { AxiosError } from 'interfaces/interfaces';
import { CSUserStore, useCSUserStore } from 'stores/useCSUserStore';

const SessionManager = () => {
  const navigate = useNavigate();
  const originalTimer = 900000;
  const originalTimeout = useRef(0);

  const { user, setUser } = useUserStore((state: UserStore) => ({
    user: state.user,
    setUser: state.setUser,
  }));

  const { csuser, setCSUser } = useCSUserStore((state: CSUserStore) => ({
    csuser: state.user,
    setCSUser: state.setUser,
  }));

  const { setAppLoading } = useInitStore((state: InitStore) => ({
    setAppLoading: state.setAppLoading,
  }));

  const { processMessages } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
  }));

  useEffect(() => {
    originalTimeout.current = setTimeout(() => {
      handleIdleTimeout();
    }, originalTimer);

    window.addEventListener('mousemove', () => {
      resetOriginalTimer();
    });

    window.addEventListener('keypress', () => {
      resetOriginalTimer();
    });

    return () => {
      clearTimeout(originalTimeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetOriginalTimer = () => {
    clearTimeout(originalTimeout.current);

    originalTimeout.current = setTimeout(() => {
      handleIdleTimeout();
    }, originalTimer);
  };

  const handleIdleTimeout = () => {
    if (authService.isUserLoggedIn()) {
      logout();
    }
  };

  const logout = async () => {
    try {
      setAppLoading(true);
      const response = await authService.logout((user?.access_token ?? csuser?.access_token) as string);
      if (response.status === 200) {
        setUser(null);
        setCSUser(null);
        navigate('/');
      } else {
        processMessages(response.data.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  };

  return null;
};

export default SessionManager;
