import { useEffect, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { PaymentMethod, Product } from 'interfaces/interfaces';
import { convertStringToAmount } from 'utils/utils';
import { PaymentMethodOptions } from '../../PaymentCenterProducts';

interface PayNowDesktopProps {
  product: Product;
  hasAnyPaymentMethods: boolean;
  selectedPaymentMethodRecId: string;
  handleDropDownChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  paymentMethods: (PaymentMethod | PaymentMethodOptions)[];
  handleOnEdit: (selectedPaymentMethod: PaymentMethod | null) => void;
  selectedPaymentMethodObj: PaymentMethod | null;
  confirmPaynow: () => void;
  isFormValid: boolean;
}

interface State {
  showToolTip: boolean;
  toolTipTarget: HTMLElement | null;
  disableControls: boolean;
}

const PayNowDesktop = ({
  product,
  hasAnyPaymentMethods,
  selectedPaymentMethodRecId,
  handleDropDownChange,
  paymentMethods,
  handleOnEdit,
  selectedPaymentMethodObj,
  confirmPaynow,
  isFormValid,
}: PayNowDesktopProps) => {
  const navigate = useNavigate();
  const [state, setState] = useState<State>({
    showToolTip: false,
    toolTipTarget: null,
    disableControls: true,
  });

  useEffect(() => {
    const isSubmitDisabled = product.paymentAction.submitPayment && product.paymentAction.submitPayment.disable === 'Y';
    const hasMessage = !!product.paymentAction.payNowLink.message;
    const disableControls = isSubmitDisabled && hasMessage;
    setState((prevState) => ({ ...prevState, disableControls: disableControls }));
  }, [product.paymentAction.payNowLink.message, product.paymentAction.submitPayment]);

  const handleToolTip = async (event: React.MouseEvent<HTMLElement>) => {
    event.persist();
    setState((prevState) => ({ ...prevState, showToolTip: false }));
    setState((prevState) => ({
      ...prevState,
      showToolTip: !prevState.showToolTip,
      toolTipTarget: event.target as HTMLElement,
    }));
  };

  return (
    <div className="desktopView">
      {product && product.showPaymentAmountBreakdown && (
        <Overlay
          show={state.showToolTip}
          target={state.toolTipTarget}
          placement="auto"
          rootClose={true}
          rootCloseEvent={'click'}
          onHide={() => {
            setState((prevState) => ({ ...prevState, showToolTip: false }));
          }}
        >
          <Popover id="popover-contained">
            <div className="tooltipHover payment-amount-breakdown">
              <div className="flexBox">
                <ul>
                  <li>
                    <span className="label">AMOUNT DUE</span>
                    <span className="value">{product.paymentInfo.amountDue || '-'}</span>
                    <span className="tooltip-break-special-chars">
                      <sup>{product.paymentInfo.amountDueSpecialCharacter}</sup>
                    </span>
                  </li>
                  <li>
                    <span className="label">Past Due</span>
                    <span className="value">{product.paymentInfo.pastDueAmount || '-'}</span>
                    <span className="tooltip-break-special-chars"></span>
                  </li>
                  <li>
                    <span className="label">Adjustment</span>
                    <span className="value">{product.paymentInfo.adjustment || '-'}</span>
                    <span className="tooltip-break-special-chars">
                      {convertStringToAmount(product.paymentInfo.adjustment) > 0 && '\u00A0CR'}
                    </span>
                  </li>
                  {convertStringToAmount(product.paymentInfo.billingCredit) !== 0 && (
                    <li>
                      <span className="label">Billing Credit</span>
                      <span className="value">{product.paymentInfo.billingCredit || '-'}</span>
                      <span className="tooltip-break-special-chars">
                        {convertStringToAmount(product.paymentInfo.billingCredit) > 0 && '\u00A0CR'}
                      </span>
                    </li>
                  )}
                  <li>
                    <span className="label">TOTAL AMOUNT DUE</span>
                    <span className="value">{product.paymentInfo.totalAmountDue || '-'}</span>
                    <span className="tooltip-break-special-chars"></span>
                  </li>
                  {product.paymentInfo.disclaimer && (
                    <li>
                      <span className="tooltip-breakdown-disclaimer">{product.paymentInfo.disclaimer}</span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </Popover>
        </Overlay>
      )}
      <div className="payNowWrapper">
        <div className="flexWhiteBox leftCol">
          <h2 className="homeHederText">Product</h2>
          <div className="leftColumn">
            <div className="headerColumn">
              <h3>
                <Link title={''} to={`/product-details/${product.policyNumber}`}>
                  {product.productDesc}{' '}
                </Link>
              </h3>
            </div>
            <div className="bodyRow">
              <div className="flexBox">
                <ul>
                  <li>
                    <span className="label">CERTIFICATE/PLAN NUMBER</span>
                    <span className="value">{product.policyNumber}</span>
                  </li>
                  <li>
                    <span className="label">PAYMENT FREQUENCY</span>
                    <span className="value">{product.currentPaymentFrequecyType}</span>
                  </li>
                  <li>
                    <span className="label">DUE DATE</span>
                    <span className="value">{product.dueDate}</span>
                  </li>
                  <li>
                    <span className="label">AMOUNT DUE</span>
                    <span className="value payNowTooltipLocation">
                      {product.amountDue}
                      {product.showPaymentAmountBreakdown && (
                        <button
                          onClick={($event) => {
                            handleToolTip($event);
                          }}
                          className="materialicons infoIcon"
                        >
                          info_outline
                        </button>
                      )}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {hasAnyPaymentMethods && (
          <div className="flexWhiteBox rightCol">
            <h2 className="homeHederText">Payment Method</h2>
            <div className="leftColumn">
              <div className="headerColumn">
                <select
                  value={selectedPaymentMethodRecId}
                  onChange={handleDropDownChange}
                  name="selectedPaymentMethodRecId"
                  disabled={state.disableControls}
                >
                  {paymentMethods.map((value) => (
                    <option key={value.paymentRecordId} value={value.paymentRecordId}>
                      {value.paymentMethodHeader}
                    </option>
                  ))}
                </select>
                <div className="btn-links-edit">
                  <Link
                    className={state.disableControls ? 'disabled-link-btn' : ''}
                    title={''}
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!state.disableControls) handleOnEdit(selectedPaymentMethodObj);
                    }}
                  >
                    Edit method
                  </Link>
                </div>
              </div>
              <div className="bodyRow">
                <div className="flexBox">
                  {selectedPaymentMethodObj?.bankAccount == null && (
                    <ul>
                      <li>
                        <span className="label">PAYMENT TYPE</span>
                        <span className="value">{selectedPaymentMethodObj?.paymentMethodHeader}</span>
                      </li>
                      <li>
                        <span className="label">CARDHOLDER NAME</span>
                        <span className="value single-line-ellipses" title={selectedPaymentMethodObj?.cardHolderName}>
                          {selectedPaymentMethodObj?.cardHolderName}
                        </span>
                      </li>
                      <li>
                        <span className="label">CARD NUMBER</span>
                        <span className="value">{selectedPaymentMethodObj?.creditCard?.cardNumber}</span>
                      </li>
                      <li>
                        <span className="label">CARD EXPIRATION</span>
                        <span className="value">{selectedPaymentMethodObj?.creditCard?.expirationDate}</span>
                      </li>
                    </ul>
                  )}
                  {selectedPaymentMethodObj?.creditCard == null && (
                    <ul>
                      <li>
                        <span className="label">PAYMENT TYPE</span>
                        <span className="value">{selectedPaymentMethodObj?.paymentMethodHeader}</span>
                      </li>
                      <li>
                        <span className="label">ACCOUNT-HOLDER NAME</span>
                        <span className="value">{selectedPaymentMethodObj?.cardHolderName}</span>
                      </li>
                      <li>
                        <span className="label">ACCOUNT NUMBER</span>
                        <span className="value">{selectedPaymentMethodObj?.bankAccount?.accountNumber}</span>
                      </li>
                      <li>
                        <span className="label">ROUTING NUMBER</span>
                        <span className="value">{selectedPaymentMethodObj?.bankAccount?.maskedRoutingNumber}</span>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* change block */}
        {!hasAnyPaymentMethods && (
          <div className="flexWhiteBox payNowaddPaymentM rightCol">
            <div className="leftColumn">
              <div className="text">No payment methods found</div>

              <div className="roundBottomButton">
                <button
                  onClick={() => {
                    handleOnEdit(null);
                  }}
                  disabled={state.disableControls}
                  className="btn agiaBlueButton"
                  type="button"
                  value="Submit"
                >
                  <span className="materialicons">add</span>
                  <span className="navText">Add Payment Method</span>
                </button>
              </div>
            </div>
          </div>
        )}

        {/* change block */}
      </div>
      <div className="bottomButton">
        <button
          id="ma-pn-submitPayment"
          onClick={() => {
            confirmPaynow();
          }}
          className="btn agiaBlueButton payNow"
          type="button"
          disabled={!isFormValid || !hasAnyPaymentMethods || state.disableControls}
          value="Submit"
        >
          Submit Payment
        </button>

        <button
          onClick={() => {
            navigate(-1);
          }}
          className="btn agiaBlueButton-outline payNow"
          type="button"
          value="Submit"
        >
          Go back
        </button>
      </div>
    </div>
  );
};

export default PayNowDesktop;
