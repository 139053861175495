import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { User } from 'interfaces/interfaces';

export interface UserStore {
  user: User | null;
  setUser: (user: User | null) => void;
  resetUserStore: () => void;
}

const initialState = {
  user: null,
};

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      ...initialState,
      setUser: (user: User | null) => set(() => ({ user })),
      resetUserStore: () => set(() => ({ ...initialState })),
    }),
    {
      name: 'user-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
