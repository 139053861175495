import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { LOCALIZATION } from 'constants/localization';
import authService from 'services/authService';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { ARTICLES, NOTIFICATIONS, PAGE_TITLES } from 'constants/constants';
import { StepperTab } from './StepperTab';
import Modal from 'components/common/Modal';
import sharedService from 'services/sharedService';
import { AxiosError, TitleContentPair } from 'interfaces/interfaces';
import { VerifyPolicyNumberParams } from 'interfaces/apiRequestInterfaces';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import Notification from 'components/common/Notification';
import { getCookie, setCookie } from 'services/cookieService';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';

interface State {
  planNumber: string;
  pageTitle: string;
  isFormValid: boolean;
  showInfoPopup: boolean;
  globalWebContents: Record<string, string>;
  isBot: boolean;
}

export const AccountDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState<State>({
    planNumber: location?.state?.planNumber || '',
    pageTitle: '',
    isFormValid: false,
    showInfoPopup: false,
    globalWebContents: {},
    isBot: true,
  });
  const { triggerGTMDataLayer } = useContext(GlobalContext) as GlobalContextType;

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));
  const { processMessages, notificationsData } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
    notificationsData: state.notificationsData,
  }));

  const checkRequiredFields = (value: string): boolean => {
    const isValid = !/^\s*$/.test(value);
    setState((prevState) => ({ ...prevState, isFormValid: isValid }));
    return isValid;
  };

  const checkValidPlanNumber = (): string | null => {
    let errorString = '';
    if (/^\s*$/.test(state.planNumber)) {
      // Handle empty plan number
    }

    if (!/^\d+$/.test(state.planNumber)) {
      errorString += NOTIFICATIONS.PLAN_NUM_NUMERIC + '\n';
    }

    if (state.planNumber.length < 10) {
      errorString += NOTIFICATIONS.PLAN_NUM_10_LEN + '\n';
    }

    return errorString !== '' ? errorString : null;
  };

  const handleSubmit = async () => {
    try {
      setAppLoading(true);
      if (!state.isBot) {
        if (checkRequiredFields(state.planNumber)) {
          const hasValidationError = checkValidPlanNumber();
          if (hasValidationError === null) {
            const planNumber = state.planNumber.slice(-10);
            const { client } = initData ?? {};
            const params: VerifyPolicyNumberParams = {
              planNumber,
              client: client as string,
            };
            const response = await authService.verifyPolicyNumber(params);

            if (response.status === 200) {
              navigate('/personal-info', { state: { planNumber: planNumber.slice(-10) } });
            } else {
              processMessages(response.data.messages);
              const updatedAttempts = (getCookie('Attempt') === '' ? 0 : parseInt(getCookie('Attempt'))) + 1;
              setCookie('Attempt', updatedAttempts.toString(), 0);
            }
          } else {
            processMessages([
              {
                type: 'ERROR',
                content: [hasValidationError],
              },
            ]);
          }
        }
      } else {
        alert(NOTIFICATIONS.BOT_SUSPECTED);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setState((prevState) => ({ ...prevState, planNumber: value }));
    checkRequiredFields(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    const { clientName } = initData ?? {};
    setState((prevState) => ({ ...prevState, pageTitle: clientName + PAGE_TITLES.REGISTRATION_IDENTIFICATION }));
    const getContent = async (titles: string[]) => {
      try {
        setAppLoading(true);
        const params = {
          titles,
        };
        const response = await sharedService.getContents(params);
        if (response.status === 200) {
          const webContents: Record<string, string> = {};
          response.data.items.forEach((element: TitleContentPair) => {
            webContents[element.title] = element.content;
          });
          setState((prevState) => ({ ...prevState, globalWebContents: webContents }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setAppLoading(false);
      }
    };
    getContent([ARTICLES.REG_ACCOUNT_DETAILS_NOTE, ARTICLES.CERTI_NUM_INFO]);
    checkRequiredFields(state.planNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData, setAppLoading]);

  const verifyRecaptcha = useCallback(
    async (recaptchaToken: string) => {
      if (recaptchaToken) {
        const params = {
          token: recaptchaToken,
        };

        setAppLoading(true);
        try {
          const response = await sharedService.verifyCaptcha(params);
          if (response.status === 200) {
            setState((prevState) => ({ ...prevState, isBot: response.data.isBot }));
          }
        } catch (error) {
          console.error(error);
        } finally {
          setAppLoading(false);
        }
      }
    },
    [setAppLoading],
  );

  return (
    <>
      <Helmet>
        <title>
          {
            // globalContext.state.pagesTitle[PAGES_TITLE_KEYS.REGISTRATION_IDENTIFICATION] ||
            state.pageTitle
          }
        </title>
      </Helmet>
      <div className="registerWrapper register-height-wrapper">
        <div className="registerHeader">
          <h1 className="mainHeadingT">{LOCALIZATION.EN.REGISTRATION_ALL_STEPS}</h1>
          {!!notificationsData.nonInfoNotificaiton.length &&
            notificationsData.nonInfoNotificaiton.map((notification, index) => (
              <Notification key={index} id={index} message={notification} />
            ))}
        </div>
        <StepperTab step={1} />
        <div className="usernameWrapper">
          <div className="lusernameColumn mobileButton">
            <div className="mt-3 mb-3 mobileView">
              <strong>Step 1: </strong> Identification Information
            </div>
            <div className="backgroundSetColor">
              <div className="columnLayout">
                <div className="leftCol">
                  <div className="planNumber mt-3">
                    <label htmlFor="planNumber">Certificate/Plan Number</label>
                    <input
                      value={state.planNumber}
                      onKeyDown={handleKeyDown}
                      className="form-control"
                      type="text"
                      id="planNumber"
                      onChange={handleChange}
                      name="planNumber"
                      required
                    />
                    <Link
                      title={''}
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setState((prevState) => ({ ...prevState, showInfoPopup: true }));
                        triggerGTMDataLayer({
                          event: 'MyAccountPageView',
                          pagePath: '/find-plan-number',
                          pageTitle: `${initData?.clientName} - Where to find your Certificate/Plan Number?`,
                        });
                      }}
                      className="findPlanText"
                    >
                      Where to find your Certificate/Plan Number?
                    </Link>
                    <div className="invalid-feedback">Please fill out this field.</div>
                  </div>
                </div>
                <div className="leftCol widthCol desktopView">
                  <p
                    dangerouslySetInnerHTML={{ __html: state.globalWebContents[ARTICLES.REG_ACCOUNT_DETAILS_NOTE] }}
                    id={`globalWC_${ARTICLES.REG_ACCOUNT_DETAILS_NOTE}`}
                    className="noteText"
                  />
                </div>
              </div>
              <GoogleReCaptcha onVerify={verifyRecaptcha} />
            </div>
            <div className="noteMobileText mobileView">
              <p
                className="noteText"
                dangerouslySetInnerHTML={{
                  __html: state.globalWebContents[ARTICLES.REG_ACCOUNT_DETAILS_NOTE],
                }}
              />
            </div>
            <div className="roundBottomButton mt-3">
              <button
                id="ma-ra-step1"
                className={`btn btn-primary agiaBlueButton ${!state.isFormValid && 'disabled'}`}
                type="button"
                onClick={handleSubmit}
              >
                Next: Personal information
              </button>
              <Link id="ma-ra-step1Login" to="/">
                Back to login
              </Link>
            </div>
          </div>
        </div>
      </div>
      {state.showInfoPopup && (
        <Modal
          title="Where to find your Certificate/Plan Number?"
          onClose={() => setState((prevState) => ({ ...prevState, showInfoPopup: false }))}
        >
          <p dangerouslySetInnerHTML={{ __html: state.globalWebContents[ARTICLES.CERTI_NUM_INFO] }} />
        </Modal>
      )}
    </>
  );
};
