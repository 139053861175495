import { create } from 'zustand';

import { NotificationsData, Notification } from 'interfaces/interfaces';

export interface NotificationsStore {
  notificationsData: NotificationsData;
  processMessages: (messages: Notification[]) => void;
  resetMessages: () => void;
  setInfoNotification: (notification: Notification[]) => void;
  setInterScreenNotification: (notification: Notification[]) => void;
  setPopupNotifications: (messages: Notification[]) => void;
  resetPopupNotifications: () => void;
  resetInfoNotification: () => void;
  pushNonInfoMsg: (message: Notification[]) => void;
  popNonInfoMsg: (messageID: string) => void;
  resetNotificationsStore: () => void;
}

const initialState = {
  notificationsData: {
    infoNotificationOriginal: [],
    infoNotification: [],
    infoNotificationCount: 0,
    nonInfoNotificaiton: [],
    interScreenNotification: [],
    popupNotifications: [],
  },
};

export const getCleanMessages = (messages: Notification[]) => {
  const _messages: Notification[] = [];

  if (messages) {
    messages.forEach((message) => {
      if (message?.type && message.content[0] !== '') {
        _messages.push(message);
      }
    });

    return _messages;
  } else {
    return null;
  }
};

export const useNotificationsStore = create<NotificationsStore>()((set) => ({
  ...initialState,
  setInfoNotification: (infoNotification: Notification[]) =>
    set((state) => {
      window.scrollTo(0, 0);
      return { ...state, notificationsData: { ...state.notificationsData, infoNotification } };
    }),
  setInterScreenNotification: (interScreenNotification: Notification[]) =>
    set((state) => {
      window.scrollTo(0, 0);
      return { ...state, notificationsData: { ...state.notificationsData, interScreenNotification } };
    }),
  setPopupNotifications: (messages: Notification[]) =>
    set((state) => {
      if (document.getElementsByClassName('modal-body')[0]) {
        document.getElementsByClassName('modal-body')[0].scrollTop = 0;
      }

      const _messages = getCleanMessages(messages); // clean messages

      if (_messages) {
        const popupNotifications: Notification[] = [];

        _messages.forEach((msg) => {
          popupNotifications.push(msg);
        });

        return { ...state, notificationsData: { ...state.notificationsData, popupNotifications: popupNotifications } };
      }
      return state;
    }),
  resetInfoNotification: () =>
    set((state) => {
      return { ...state, notificationsData: { ...state.notificationsData, infoNotification: [] } };
    }),
  resetPopupNotifications: () =>
    set((state) => {
      return { ...state, notificationsData: { ...state.notificationsData, popupNotifications: [] } };
    }),
  processMessages: (messages: Notification[]) =>
    set((state) => {
      window.scrollTo(0, 0);
      const _messages = getCleanMessages(messages);

      if (_messages) {
        const infoMessages: Notification[] = [];
        const nonInfoMessages: Notification[] = [];

        _messages.forEach((msg) => {
          if (msg.type === 'INFO') {
            infoMessages.push(msg);
          } else {
            nonInfoMessages.push(msg);
          }
        });
        return {
          ...state,
          notificationsData: {
            ...state.notificationsData,
            infoNotification: infoMessages,
            infoNotificationOriginal: infoMessages,
            infoNotificationCount: infoMessages.length,
            nonInfoNotificaiton: nonInfoMessages,
          },
        };
      }
      return state;
    }),
  resetMessages: () =>
    set((state) => {
      return {
        ...state,
        notificationsData: {
          ...state.notificationsData,
          infoNotification: [],
          nonInfoNotificaiton: [],
          infoNotificationOriginal: [],
          infoNotificationCount: 0,
        },
      };
    }),
  pushNonInfoMsg: (msg: Notification[]) =>
    set((state) => {
      const nonInfoNotificaiton = state.notificationsData.nonInfoNotificaiton;
      const result = nonInfoNotificaiton.concat(msg);
      return {
        ...state,
        notificationsData: {
          ...state.notificationsData,
          nonInfoNotificaiton: result,
        },
      };
    }),
  popNonInfoMsg: (msgId: string) =>
    set((state) => {
      const nonInfoNotificaiton = state.notificationsData.nonInfoNotificaiton;
      const index = nonInfoNotificaiton.findIndex((item) => item.messageId === msgId);
      if (index !== -1) {
        nonInfoNotificaiton.splice(index, 1);
        return {
          ...state,
          notificationsData: { ...state.notificationsData, nonInfoNotificaiton: nonInfoNotificaiton },
        };
      }
      return state;
    }),
  resetNotificationsStore: () => set(() => ({ ...initialState })),
}));
