import './styles.scss';
import AGIA_LOGO from 'assets/images/agia-logo.jpg';

const NotFound = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="error-template">
            <img alt={''} className="logo" src={AGIA_LOGO}></img>
            <h1>Oops, page not found</h1>
            <h3>Sorry, the page you are looking for is not here.</h3>
            <div className="error-details">Error 404</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
