import axios from 'axios';
import Axios from './Axios';

import { ENDPOINTS } from 'constants/endpoints';
import {
  ForgotPasswordPostParams,
  ForgotUsernameParams,
  LoginParams,
  UpdatePasswordParams,
  UpdateUserInformationParams,
  VerifyPersonalInfoParams,
  VerifyPolicyNumberParams,
  VerifySignInCredsParams,
} from 'interfaces/apiRequestInterfaces';

const authService = {
  getInitData: (hostName: string) => {
    return Axios().get(ENDPOINTS.initData(hostName));
  },
  login: (params: LoginParams) => {
    return Axios().post(ENDPOINTS.login, params);
  },
  getPublicIP: () => {
    return axios.get(ENDPOINTS.publicIP);
  },
  updateUserInformation: (params: UpdateUserInformationParams) => {
    return Axios().put(ENDPOINTS.updateUserInformation, params);
  },
  isUserLoggedIn: () => {
    const activeSession = document.cookie.replace(/(?:(?:^|.*;\s*)activeSession\s*=\s*([^;]*).*$)|^.*$/, '$1');
    const isCustomer =
      localStorage.getItem('init-store') &&
      JSON.parse(localStorage.getItem('init-store') as string)?.state?.initData?.isCustomerSite;
    if (activeSession === '') {
      localStorage.removeItem('csuser-store');
      localStorage.removeItem('user-store');
      document.cookie = 'activeSession=true';
    } else {
      const user = isCustomer ? localStorage.getItem('csuser-store') : localStorage.getItem('user-store');
      const accessToken = user ? JSON.parse(user)?.state?.user?.access_token : null;

      return !!accessToken;
    }
  },
  forgotPassword1: (params: string) => {
    return Axios().get(ENDPOINTS.forgotPassword + params);
  },
  forgotPassword2: (params: ForgotPasswordPostParams) => {
    return Axios().post(ENDPOINTS.forgotPassword, params);
  },
  forgotUsername: (params: ForgotUsernameParams) => {
    return Axios().post(ENDPOINTS.forgotUsername, params);
  },
  verifyPolicyNumber: (params: VerifyPolicyNumberParams) => {
    return Axios().post(ENDPOINTS.verifyPolicyNumber, params);
  },
  verifyPersonalInfo: (params: VerifyPersonalInfoParams) => {
    return Axios().post(ENDPOINTS.verifyPersonalInfo, params);
  },
  verifySignInCreds: (params: VerifySignInCredsParams) => {
    return Axios().post(ENDPOINTS.verifySignInCreds, params);
  },
  logout: (params: string) => {
    return Axios().get(ENDPOINTS.logout(params));
  },
  changePassword: (params: UpdatePasswordParams) => {
    return Axios().put(ENDPOINTS.changePassword, params);
  },
  resetPassword: (params: Record<string, string>) => {
    return Axios().post(ENDPOINTS.resetPassword, params);
  },
  impersonateUser: (params: Record<string, string>) => {
    return Axios().get(ENDPOINTS.impersonateUser(params.userId));
  },
  deleteUser: (params: Record<string, string>) => {
    return Axios().delete(ENDPOINTS.deleteUser(params.userId));
  },
  resetUserPassword: (params: Record<string, string | boolean>) => {
    return Axios().post(ENDPOINTS.forgotPassword, params);
  },
};

export default authService;
