import { Navigate, Outlet } from 'react-router-dom';

import Layout from 'components/Layout';
import authService from 'services/authService';
import { InitStore, useInitStore } from 'stores/useInitStore';

const AuthRoutes = () => {
  const { initData } = useInitStore((state: InitStore) => ({
    initData: state.initData,
  }));

  return !authService.isUserLoggedIn() ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={!initData?.isCustomerSite ? '/dashboard' : '/customer-home'} />
  );
};

export default AuthRoutes;
