import Axios from './Axios';

import { ENDPOINTS } from 'constants/endpoints';

const searchService = {
  searchClient: (params: Record<string, string>) => {
    let url = ENDPOINTS.searchClient(params.search, params.value, params.userId);

    if (params.search === 'account-number' || params.search === 'user-name') {
      url = ENDPOINTS.searchClient(params.search, params.value);
    }

    return Axios().get(url);
  },
};

export default searchService;
