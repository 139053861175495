import { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import AllRoutes from 'routes/index';
import authService from 'services/authService';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { API_KEYS } from 'constants/constants';
import GlobalProvider from 'context/GlobalContext';
import BackendServicesInfo from 'components/BackendServicesInfo';

function App() {
  const { setAppLoaded, setHostFound, setInitData } = useInitStore((state: InitStore) => ({
    setAppLoaded: state.setAppLoaded,
    setHostFound: state.setHostFound,
    setInitData: state.setInitData,
  }));

  useEffect(() => {
    const getInitData = async () => {
      const hostName = document.location.hostname;
      try {
        const response = await authService.getInitData(hostName);
        if (response.status === 200) {
          setAppLoaded(true);
          setHostFound(true);
          setInitData(response.data);
        } else if (response.status === 404) {
          setAppLoaded(true);
        } else {
          alert('Not able to fetch global ids');
        }
      } catch (error) {
        console.error(error);
        alert('Not able to fetch global ids');
      }
    };
    getInitData();
  }, [setAppLoaded, setHostFound, setInitData]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={API_KEYS.RECAPTCHA_SITE_KEY}>
      <GlobalProvider>
        <BackendServicesInfo />
        <AllRoutes />
      </GlobalProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
