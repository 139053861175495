import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';

import { ARTICLES } from 'constants/constants';
import { convertStringToAmount } from 'utils/utils';
import { AdditionalInsured, ProductOverview } from '.';
import { ProductDocument } from './ProductDetailsDesktop';

interface OptionalRider {
  productDesc: string;
  annualPremium: string;
  policyNumber: string;
  policyStatus: string;
  benefitAmount: string;
}

interface ProductDetailsMobileProps {
  globalWebContents: Record<string, string>;
  productOverview: ProductOverview;
  additionalInsured: AdditionalInsured[];
  optionalRiders: OptionalRider[];
  productDocuments: ProductDocument[];
  correspondences: ProductDocument[];
  openDocument: (document: ProductDocument, type: string) => void;
}

const ProductDetailsMobile: React.FC<ProductDetailsMobileProps> = (props) => {
  const BATCH_SIZE_OPTIONAL_RIDER = 5;
  const [open, setOpen] = useState<Record<number, boolean>>({});
  const [showMoreOptionalCounter, setShowMoreOptionalCounter] = useState(BATCH_SIZE_OPTIONAL_RIDER);
  const [, setOptionalRiders] = useState<OptionalRider[]>(props.optionalRiders.slice(0, showMoreOptionalCounter));
  const navigate = useNavigate();

  const toggleOpen = (id: number) => {
    setOpen((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const showMoreOptionalRider = () => {
    const newCounter = showMoreOptionalCounter + BATCH_SIZE_OPTIONAL_RIDER;
    setOptionalRiders(props.optionalRiders.slice(0, newCounter));
    setShowMoreOptionalCounter(newCounter);
  };

  const renderProductOverview = () => {
    const { productOverview } = props;
    return (
      <div className="flexWhiteBox">
        <ul>
          <li>
            <span className="label">
              {productOverview.isInsuranceProduct === 'Y' ? 'CERTIFICATE NUMBER' : 'PLAN NUMBER'}
            </span>
            <span className="value">{productOverview.policyNumber}</span>
          </li>
          <li>
            <span className="label">{productOverview.isInsuranceProduct === 'Y' ? 'COVERAGE TYPE' : 'PLAN TYPE'}</span>
            <span className="value">{productOverview.coverageType}</span>
          </li>
          <li>
            <span className="label">STATUS</span>
            <span className="value">{productOverview.policyStatus}</span>
          </li>
          <li>
            <span className="label">COVERAGE EFFECTIVE DATE</span>
            <span className="value">{productOverview.coverageEffectiveDate}</span>
          </li>
          {productOverview.suppressPaymentDueDate === 'N' && (
            <li>
              <span className="label">{productOverview.paymentDueDateLabel}</span>
              <span className="value">{productOverview.paymentDueDate}</span>
            </li>
          )}
          {convertStringToAmount(productOverview.benefitAmount) !== 1 && (
            <li>
              <span className="label">
                {productOverview.isInsuranceProduct === 'Y'
                  ? productOverview.isCancerOrHipOrHap === 'Y'
                    ? 'DAILY HOSPITAL BENEFIT'
                    : 'BASE BENEFIT AMOUNT'
                  : 'MEMBERSHIP FEE'}
              </span>
              <span className="value">{productOverview.benefitAmount}</span>
            </li>
          )}
          {productOverview.isInsuranceProduct === 'N' && (
            <li>
              <span className="label">OWNER</span>
              <span className="value">{productOverview.owner}</span>
            </li>
          )}
          <li>
            <span className="label">
              {productOverview.isInsuranceProduct === 'Y' ? 'ANNUAL PREMIUM' : 'ANNUAL PRODUCT COST'}
            </span>
            <span className="value">
              {productOverview.annualPremium}
              {productOverview.isInsuranceProduct === 'Y' && <sup>{productOverview.annualPremiumSpecialCharacter}</sup>}
            </span>
          </li>
          <li>
            <span className="label">PAYMENT FREQUENCY</span>
            <span className="value">{productOverview.paymentFrequency}</span>
          </li>
        </ul>
      </div>
    );
  };

  const renderAdditionalInsured = () => {
    const { additionalInsured } = props;
    if (!additionalInsured.length) return null;

    return (
      <div className="leftCol">
        <h2 className="homeHederText">Additional Insured</h2>
        <div className="flexWhiteBox tableForm">
          <table className="table table-light borderless productTable">
            <thead>
              <tr>
                <th scope="col">FULL NAME</th>
                <th scope="col">RELATIONSHIP</th>
              </tr>
            </thead>
            <tbody>
              {additionalInsured.map((value, index) => (
                <tr key={index}>
                  <td>{value.fullName}</td>
                  <td>{value.relationship}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderOptionalRiders = () => {
    const { optionalRiders } = props;

    if (optionalRiders.length === 0) return null;

    return (
      <div className="optionalRider singleCol">
        <h2 className="homeHederText">Additional Riders</h2>
        <div className="flexWhiteBox productDocs">
          <ul>
            {optionalRiders.map((value, index) => (
              <div key={index}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/role-supports-aria-props */}
                <li
                  onClick={() => toggleOpen(index)}
                  aria-controls="collapse-text"
                  aria-expanded={open[index] || false}
                  className="collapse-text"
                >
                  <span className="label">{value.productDesc}</span>
                  <span className="value">
                    <span className="priceValue">{value.annualPremium}</span>
                    <span className="materialicons leftArrow">chevron_left</span>
                    <span className="materialicons downArrow">expand_more</span>
                  </span>
                </li>
                <Collapse in={open[index]}>
                  <div id="collapseText" className="collapseText">
                    <ul>
                      <li>
                        <span className="label">CERTIFICATE/PLAN NUMBER</span>
                        <span className="value">{value.policyNumber}</span>
                      </li>
                      <li>
                        <span className="label">STATUS</span>
                        <span className="value">{value.policyStatus}</span>
                      </li>
                      <li>
                        <span className="label">BENEFIT AMOUNT</span>
                        <span className="value">{value.benefitAmount}</span>
                      </li>
                      <li>
                        <span className="label">RIDER PREMIUM</span>
                        <span className="value">{value.annualPremium}</span>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
            ))}
            {showMoreOptionalCounter < optionalRiders.length && (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
              <li onClick={showMoreOptionalRider} className="footPagination">
                Show {BATCH_SIZE_OPTIONAL_RIDER} more
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  };

  const renderDocuments = (documents: ProductDocument[], title: string, type: string) => {
    if (documents.length === 0) return null;

    return (
      <div className="leftCol">
        <h2 className="homeHederText">{title}</h2>
        <div className="flexWhiteBox productDocs">
          <ul>
            {documents.map((doc, index) => (
              <li key={index}>
                <span className="label">
                  <span className="smallTableText">{doc.documentCreatedDate}</span>
                  <span className="nonAnchorText">{doc.documentName}</span>
                </span>
                <span className="value">
                  <Link
                    className={`ma-pd-${doc.documentCategory}-${doc.documentTypeId}-${props.productOverview.policyNumber}`}
                    title={doc.documentName}
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      props.openDocument(doc, type);
                    }}
                  >
                    View Document
                  </Link>
                  {doc.showDocError && (
                    <p
                      dangerouslySetInnerHTML={{ __html: props.globalWebContents[ARTICLES.DOCUMENT_ERROR] }}
                      className="document-error"
                    />
                  )}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: props.globalWebContents[ARTICLES.PRODUCT_DETAILS_BENEFITS] }} />
      <h1 className="mainHeadingT mobileView">
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          className="materialicons"
        >
          chevron_left
        </Link>
        {props.productOverview.productDesc}
      </h1>
      <div className="mobileView productsDescTemplate">
        <div className="singleCol">
          <div className="leftCol">
            <h2 className="homeHederText">Product Overview</h2>
            {renderProductOverview()}
          </div>
          {renderAdditionalInsured()}
        </div>

        {renderOptionalRiders()}

        <div className="singleCol">
          {renderDocuments(props.productDocuments, 'Product Documents', 'PRODUCT_DOCS')}
          {renderDocuments(props.correspondences, 'Correspondence', 'CORRESPONDENCE_DOCS')}
        </div>
      </div>
      <div className="footerTextPara">
        <div dangerouslySetInnerHTML={{ __html: props.globalWebContents[ARTICLES.PRODUCT_DETAILS_MISMATCH_DATA] }} />
        {props.productOverview.isInsuranceProduct === 'Y' && (
          <p dangerouslySetInnerHTML={{ __html: props.productOverview.message as string }} />
        )}
        <div dangerouslySetInnerHTML={{ __html: props.globalWebContents[ARTICLES.SITE_DISCLAIMER] }} />
      </div>
    </div>
  );
};

export default ProductDetailsMobile;
