import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { InitData } from 'interfaces/interfaces';

export interface InitStore {
  appLoading: boolean;
  setAppLoading: (appLoading: boolean) => void;
  appLoaded: boolean;
  setAppLoaded: (appLoaded: boolean) => void;
  hostFound: boolean;
  setHostFound: (hostFound: boolean) => void;
  initData: InitData | null;
  setInitData: (initData: InitData | null) => void;
  resetInitStore: () => void;
}

const initialState = {
  appLoading: false,
  appLoaded: false,
  hostFound: false,
  initData: null,
};

export const useInitStore = create<InitStore>()(
  persist(
    (set) => ({
      ...initialState,
      setAppLoading: (appLoading: boolean) => set(() => ({ appLoading })),
      setAppLoaded: (appLoaded: boolean) => set(() => ({ appLoaded })),
      setHostFound: (hostFound: boolean) => set(() => ({ hostFound })),
      setInitData: (initData: InitData | null) => set(() => ({ initData })),
      resetInitStore: () => set(() => ({ ...initialState })),
    }),
    {
      name: 'init-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
