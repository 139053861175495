import Axios from './Axios';

import { GetProductDetailsParams, GetProductsParams } from 'src/interfaces/apiRequestInterfaces';
import { ENDPOINTS } from 'constants/endpoints';

const productsService = {
  getProducts: (params: GetProductsParams) => {
    return Axios().post(ENDPOINTS.getProductsMain, params);
  },
  getProductDetails: (params: GetProductDetailsParams) => {
    return Axios().post(ENDPOINTS.getProductDetails, params);
  },
  getDocument: (documentId: string, documentType: string, documentCategory: string) => {
    return Axios().get(ENDPOINTS.viewDocument(documentId, documentType, documentCategory));
  },
};

export default productsService;
