export const phoneFormatting = (value: string) => {
  if (!value) return value;

  const currentValue = value.replace(/[^\d]/g, '');
  if (currentValue.length <= 3) return currentValue;
  if (currentValue.length === 3) return `(${currentValue})`;
  if (currentValue.length <= 6) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  if (currentValue.length === 6) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}-`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
};

export const extractDigits = (string: string) => {
  const numb = string.match(/\d/g);
  return numb?.join('') as string;
};

export const numberAndDash = (value: string) => {
  return /^[0-9-]*$/.test(value);
};

export const formatRoutingNumber = (value: string) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  if (currentValue.length <= 3) return currentValue;
  if (currentValue.length === 3) return `${currentValue}-`;
  if (currentValue.length <= 5) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
  if (currentValue.length === 5) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}-`;
  return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}-${currentValue.slice(5, 9)}`;
};

export const formatAccountNumber = (value: string) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  if (currentValue.length <= 4) return currentValue;
  if (currentValue.length === 4) return `${currentValue}-`;
  if (currentValue.length <= 8) return `${currentValue.slice(0, 4)}-${currentValue.slice(4)}`;
  if (currentValue.length === 8) return `${currentValue.slice(0, 4)}-${currentValue.slice(4)}-`;
  if (currentValue.length <= 13)
    return `${currentValue.slice(0, 4)}-${currentValue.slice(4, 8)}-${currentValue.slice(8)}`;
  if (currentValue.length === 13)
    return `${currentValue.slice(0, 4)}-${currentValue.slice(4, 8)}-${currentValue.slice(8)}-`;
  return `${currentValue.slice(0, 4)}-${currentValue.slice(4, 8)}-${currentValue.slice(8, 13)}-${currentValue.slice(13, 17)}`;
};

export const getCardYears = () => {
  const years = [];
  let currentYear = new Date().getFullYear();
  const max = currentYear + 50;
  for (let i = currentYear; i <= max; i++) {
    years.push(currentYear++);
  }
  return years;
};

export const formatCard = (value: string) => {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,16}/g);
  const match = matches ? matches[0] : '';
  const parts = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  if (parts.length) {
    return parts.join('-');
  } else {
    return value;
  }
};

export const convertStringToAmount = (amountString: string) => {
  if (amountString) {
    return Number(amountString.replace(/[^0-9.-]+/g, ''));
  } else {
    return 0;
  }
};
