import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
  browserName,
  browserVersion,
  isMobile,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from 'react-device-detect';

import { PAGE_TITLES } from 'constants/constants';
import { LOCALIZATION } from 'constants/localization';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import Notification from '../common/Notification';
import authService from 'services/authService';
import { CSUserStore, useCSUserStore } from 'stores/useCSUserStore';
import { AxiosError } from 'interfaces/interfaces';

interface State {
  username: string;
  password: string;
  isFormValid: boolean;
  hidePassword: boolean;
  rememberMe: boolean;
  pageTitle: string;
}

const CustomerServiceLogin = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<State>({
    username: '',
    password: '',
    isFormValid: false,
    hidePassword: true,
    rememberMe: false,
    pageTitle: '',
  });

  const { setUser } = useCSUserStore((state: CSUserStore) => ({
    setUser: state.setUser,
  }));

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { processMessages, notificationsData } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
    notificationsData: state.notificationsData,
  }));

  useEffect(() => {
    setState((prevState) => ({ ...prevState, pageTitle: initData?.clientName + PAGE_TITLES.LOGIN }));
  }, [initData?.clientName]);

  useEffect(() => {
    const rememberedUsername = localStorage.getItem('rememberedUsername');

    if (rememberedUsername) {
      setState((prevState) => ({ ...prevState, username: rememberedUsername, rememberMe: true }));
    }
  }, []);

  const validate = useCallback(() => {
    if (/^\s*$/.test(state.username)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    if (/^\s*$/.test(state.password)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    setState((prevState) => ({ ...prevState, isFormValid: true }));
    return true;
  }, [state.username, state.password]);

  useEffect(() => {
    validate();
  }, [state.username, state.password, validate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const handleRememberMe = () => {
    setState((prevState) => ({ ...prevState, rememberMe: !prevState.rememberMe }));
  };

  const login = async () => {
    if (validate()) {
      const { client } = initData ?? {};
      const params = {
        username: state.username,
        password: state.password,
        isCustomerUser: true,
      };
      setAppLoading(true);
      try {
        const response = await authService.login(params);
        if (response.status === 200) {
          if (response.data.access_token) {
            setUser(response.data);

            const ipResponse = await authService.getPublicIP();
            const params = {
              username: state.username,
              isCustomerUser: true,
              client: client as string,
              clientLoginIp: ipResponse.data?.ip,
              userDeviceInfo: {
                userOS: osName,
                userOSVersion: osVersion,
                browserName: browserName,
                browserVersion: browserVersion,
                isMobile: isMobile ? 'Y' : 'N',
                mobileModel: mobileModel,
                mobileVendor: mobileVendor,
              },
            };
            try {
              const response = await authService.updateUserInformation(params);
              if (response.status !== 403) {
                const { rememberMe, username } = state;
                if (rememberMe) {
                  localStorage.setItem('rememberedUsername', username);
                } else {
                  localStorage.removeItem('rememberedUsername');
                }
                navigate('/customer-home');
              }
            } catch (error) {
              console.error(error);
            }
          }
        } else {
          processMessages(response.data.messages);
        }
      } catch (error) {
        console.error(error);
        const axiosError = error as AxiosError;
        processMessages(axiosError?.response?.data?.messages);
      } finally {
        setAppLoading(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {
            // this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.CUSTOMER_SUPPORT_LOGIN] ||
            state.pageTitle
          }
        </title>
      </Helmet>
      <div className="loginWrapper">
        <div className="loginWrapperInner">
          <div className="loginBodyRight">
            <div className="loginColumn">
              <form action="/" className="was-validation" noValidate>
                <h1 className="commonHeadText">{LOCALIZATION.EN.LOGIN_TITLE}</h1>
                {notificationsData.infoNotification.map((notification, index) => (
                  <Notification key={index} id={index} message={notification} />
                ))}
                {notificationsData.nonInfoNotificaiton.map((notification, index) => (
                  <Notification key={index} id={index} message={notification} />
                ))}
                <div className="form-group">
                  <div className="username">
                    <label htmlFor="user-email">Username</label>
                    <input
                      className="form-control"
                      onKeyDown={handleKeyDown}
                      type="text"
                      name="username"
                      value={state.username}
                      onChange={handleChange}
                    />
                    <div className="invalid-feedback">Please fill out this field.</div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="password">
                    <label htmlFor="user-password">Password</label>
                    <input
                      className="form-control password"
                      type={state.hidePassword ? 'password' : 'text'}
                      name="password"
                      onKeyDown={handleKeyDown}
                      onChange={handleChange}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setState((prevState) => ({ ...prevState, hidePassword: !prevState.hidePassword }));
                      }}
                      className="showHide"
                    >
                      <i className={'far ' + (state.hidePassword ? 'fa-eye' : 'fa-eye-slash')}></i>
                    </button>
                    <div className="invalid-feedback">Please fill out this field.</div>
                  </div>
                </div>
                <div className="checkRemember">
                  <input
                    type="checkbox"
                    checked={state.rememberMe}
                    onChange={handleRememberMe}
                    id="rememberCheck"
                    name="Remember me"
                  />
                  <span className="material-icons blankCheckbox">check_box_outline_blank</span>
                  <span className="material-icons selectedCheckbox">check_box</span>
                  <label className="rememberCheck" htmlFor="rememberCheck">
                    Remember my username
                  </label>
                </div>
                <div className="roundBottomButton">
                  <button
                    className={
                      'btn btn-primary agiaBlueButton mt-3 btn-width-110 ' + (!state.isFormValid && 'disabled')
                    }
                    type="button"
                    value="Log In"
                    onClick={login}
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerServiceLogin;
