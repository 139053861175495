import Axios from './Axios';

import { ENDPOINTS } from 'constants/endpoints';
import { GetPaymentMethodsParams, UpdatePaymentMethodParams } from 'interfaces/apiRequestInterfaces';

const paymentCenterService = {
  getPaymentMethods: (params: GetPaymentMethodsParams) => {
    return Axios().post(ENDPOINTS.getPaymentMethods, params);
  },
  updatePaymentMethod: (params: UpdatePaymentMethodParams) => {
    return Axios().put(ENDPOINTS.updatePaymentMethod, params);
  },
  addPaymentMethod: (params: UpdatePaymentMethodParams) => {
    return Axios().post(ENDPOINTS.updatePaymentMethod, params);
  },
  deletePaymentMethod: (params: Record<string, string>) => {
    return Axios().post(ENDPOINTS.deletePaymentMethod, params);
  },
  getProducts: (params: Record<string, string>) => {
    return Axios().post(ENDPOINTS.getProducts, params);
  },
  getUpdatedFrequency: (params: Record<string, string>) => {
    return Axios().post(ENDPOINTS.getUpdatedFrequency, params);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeFrequencyAutopay: (params: any) => {
    return Axios().put(ENDPOINTS.getUpdatedFrequency, params);
  },
  getPaymentInfo: (params: Record<string, string>) => {
    return Axios().post(ENDPOINTS.getPaymentInfo, params);
  },
  getPayNow: (params: Record<string, string>, type: string) => {
    const url = type === 'DASHBOARD' ? ENDPOINTS.getPayNowHomePage : ENDPOINTS.getPayNow;
    return Axios().post(url, params);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitPayNow(params: any) {
    return Axios().post(ENDPOINTS.submitPayNow, params);
  },
};

export default paymentCenterService;
