import { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { InitStore, useInitStore } from 'stores/useInitStore';
import { PAGE_TITLES } from 'constants/constants';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import Notification from 'components/common/Notification';
import { UserStore, useUserStore } from 'stores/useUserStore';
import paymentCenterService from 'services/paymentCenterService';
import { GetPaymentMethodsParams } from 'interfaces/apiRequestInterfaces';
import { AxiosError } from 'interfaces/interfaces';
import { PaymentCenterStore, usePaymentCenterStore } from 'stores/usePaymentCenterStore';

interface PaymentCenterLayoutProps {
  children: React.ReactNode;
}

const PaymentCenterLayout = ({ children }: PaymentCenterLayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const hideTabsForRoutes = ['/payment-center/pay-now'];
  const [pageTitle, setPageTitle] = useState('');

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));

  const { setPaymentMethods } = usePaymentCenterStore((state: PaymentCenterStore) => ({
    setPaymentMethods: state.setPaymentMethods,
  }));

  const { notificationsData, processMessages } = useNotificationsStore((state: NotificationsStore) => ({
    notificationsData: state.notificationsData,
    processMessages: state.processMessages,
  }));

  useEffect(() => {
    setPageTitle(initData?.clientName + PAGE_TITLES.PAYMENT_CENTER);
  }, [initData?.clientName]);

  useEffect(() => {
    if (location.pathname === '/payment-center') {
      navigate('/payment-center/products');
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    const getPaymentMethods = async () => {
      const params = {
        client: initData?.client,
        accountNumber: user?.accountNumber,
        policyNumber: user?.policyNumber,
        firstName: user?.firstName,
        lastName: user?.lastName,
        userid: user?.userid,
        isEmailSubscribed: user?.isEmailSubscribed,
      } as GetPaymentMethodsParams;

      try {
        setAppLoading(true);
        const response = await paymentCenterService.getPaymentMethods(params);
        if (response.status === 200) {
          setPaymentMethods(response.data.paymentMethods || []);
        } else {
          processMessages(response.data.messages);
        }
      } catch (error) {
        console.error(error);
        const axiosError = error as AxiosError;
        processMessages(axiosError?.response?.data?.messages);
      } finally {
        setAppLoading(false);
      }
    };
    getPaymentMethods();
  }, [
    initData?.client,
    processMessages,
    setAppLoading,
    setPaymentMethods,
    user?.accountNumber,
    user?.firstName,
    user?.lastName,
    user?.policyNumber,
    user?.userid,
    user?.isEmailSubscribed,
  ]);

  return (
    <>
      <Helmet>
        <title>
          {
            //   this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.PAYMENT_CENTER] ||
            pageTitle
          }
        </title>
      </Helmet>
      <div className="innerBodyWrapper">
        {!!notificationsData.infoNotification.length &&
          notificationsData.infoNotification.map((notification, index) => (
            <Notification key={index} id={index} message={notification} />
          ))}
        <div className="paymentCenterContent">
          {!hideTabsForRoutes.includes(location.pathname) && (
            <>
              <h1 className="mainHeadingT">Payment Center</h1>
              <div className="col-md-12 float-left p-0">
                <div className="innerNavHeader col-lg-8 col-md-8 col-xs-12 col-12 float-left p-0">
                  <div className="navbarText">
                    <nav className="navbar navbar-expand-lg">
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <NavLink className="nav-link payment-center-header" to="/payment-center/products">
                            <span className="navText">Products</span>
                          </NavLink>
                        </li>
                        <li className="nav-item ">
                          <NavLink className="nav-link payment-center-header" to="/payment-center/payment-methods">
                            <span className="navText">Payment Methods</span>
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </>
          )}
          {children}
        </div>
      </div>
    </>
  );
};

export default PaymentCenterLayout;
