import { useContext } from 'react';

import bankAccountImage from 'assets/images/bank-account.jpg';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { BankAccountForm as IBankAccountForm } from 'components/PaymentMethodModal';
import { numberAndDash, formatRoutingNumber, formatAccountNumber } from 'utils/utils';

interface BankAccountFormProps {
  form: IBankAccountForm;
  inputChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const BankAccountForm = ({ form, inputChange, handleKeyDown }: BankAccountFormProps) => {
  const { globalContextState } = useContext(GlobalContext) as GlobalContextType;

  const checkZip = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isValid = numberAndDash(value);
    if (isValid) {
      inputChange(e);
    }
  };

  const formatRouting = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = numberAndDash(e.target.value);
    if (isValid) {
      const caret = e.target.selectionStart;
      const element = e.target;
      const formattedNum = formatRoutingNumber(e.target.value);

      e.target.value = formattedNum;
      inputChange(e);

      window.requestAnimationFrame(() => {
        const _caret = caret ? caret + 1 : 1;
        element.selectionStart = _caret;
        element.selectionEnd = _caret;
      });
    }
  };

  const formatAccount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isValid = numberAndDash(value);
    if (isValid) {
      const formattedNum = formatAccountNumber(value);
      e.target.value = formattedNum;
      inputChange(e);
    }
  };

  return (
    <div className="formPanel mainPopupWraper">
      <div className="leftPanel">
        <h2 className="bInfoText">Payment Information</h2>
        <img alt={''} src={bankAccountImage} width="100%" height="auto" />
        <div className="rememberPay mt-4 mb-3">
          <div className="mb-3">
            <div className="custom-control-add custom-radio">
              <input
                type="radio"
                className="custom-control-input mr-1"
                id="Checking"
                checked={form.type === 'C'}
                value="C"
                onChange={inputChange}
                name="type"
              />
              <span className="materialicons uncheck">radio_button_unchecked</span>
              <span className="materialicons check">radio_button_checked</span>
              <span>Checking</span>
            </div>
          </div>
          <div className="mb-3">
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="Savings"
                checked={form.type === 'S'}
                value="S"
                onChange={inputChange}
                name="type"
                className="custom-control-input mr-1"
              />
              <span className="materialicons uncheck">radio_button_unchecked</span>
              <span className="materialicons check">radio_button_checked</span> <span>Savings</span>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="oldPassword">
            <label htmlFor="oldPassword">Account-holder Name</label>
            <input
              className="form-control"
              type="text"
              value={form.accountName}
              onChange={inputChange}
              onKeyDown={handleKeyDown}
              name="accountName"
              disabled={form.editMode}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="newPassword">
            <label htmlFor="newPassword">Bank Routing Number</label>
            <input
              className="form-control"
              type="text"
              value={form.originalRoutingNumber}
              onChange={formatRouting}
              onKeyDown={handleKeyDown}
              name="originalRoutingNumber"
            />
          </div>
        </div>
        <div className="form-group">
          <div className="confirmPwd">
            <label htmlFor="confirmPwd">Confirm Bank Routing Number</label>
            <input
              className="form-control"
              type="text"
              value={form.confirmRoutingNumber}
              onChange={formatRouting}
              onKeyDown={handleKeyDown}
              name="confirmRoutingNumber"
            />
          </div>
        </div>

        <div className="form-group">
          <div className="answer">
            <label htmlFor="answer">Account Number</label>
            <input
              className="form-control"
              type="text"
              value={form.accountNumber}
              onChange={formatAccount}
              onKeyDown={handleKeyDown}
              name="accountNumber"
              disabled={form.editMode}
            />
          </div>
        </div>
        {!form.editMode && (
          <div className="form-group">
            <div className="answer">
              <label htmlFor="answer">Confirm Account Number</label>
              <input
                className="form-control"
                type="text"
                value={form.confirmAccountNumber}
                onChange={formatAccount}
                onKeyDown={handleKeyDown}
                name="confirmAccountNumber"
                disabled={form.editMode}
              />
            </div>
          </div>
        )}
      </div>
      <div className="rightPanel">
        <h2 className="bInfoText">Billing Information</h2>
        <div className="form-group">
          <div className="address">
            <label htmlFor="address">Address 1</label>
            <input
              className="form-control"
              type="text"
              maxLength={30}
              name="address1"
              value={form.address1}
              onChange={inputChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="address">
            <label htmlFor="address">Address 2</label>
            <input
              className="form-control"
              type="text"
              maxLength={30}
              name="address2"
              value={form.address2}
              onChange={inputChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="address">
            <label htmlFor="address">Address 3</label>
            <input
              className="form-control"
              type="text"
              maxLength={30}
              name="address3"
              value={form.address3}
              onChange={inputChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="city">
            <label htmlFor="city">City</label>
            <input
              className="form-control"
              type="text"
              name="city"
              value={form.city}
              onChange={inputChange}
              onKeyDown={handleKeyDown}
              maxLength={30}
            />
          </div>
        </div>

        <div className="form-group displayFlex">
          <div className="state">
            <label htmlFor="state">State</label>
            <select name="selectedState" value={form.selectedState} onChange={inputChange}>
              <option value="">Please Select</option>
              {globalContextState.states.map((value) => (
                <option key={value.stateCode} value={value.stateCode}>
                  {value.stateCode}
                </option>
              ))}
            </select>
          </div>

          <div className="zipCode">
            <label htmlFor="zipCode">Zip</label>
            <input
              className="form-control"
              type="text"
              name="zip"
              value={form.zip}
              onChange={checkZip}
              onKeyDown={handleKeyDown}
              maxLength={10}
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankAccountForm;
