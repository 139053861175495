import Axios from './Axios';

import { ENDPOINTS } from 'constants/endpoints';
import { GetContentsParams, VerifyCaptchaParams } from 'interfaces/apiRequestInterfaces';

const sharedService = {
  insproService: () => {
    return Axios().get(ENDPOINTS.insproService);
  },
  getContents: (params: GetContentsParams) => {
    const hostName = document.location.hostname;
    return Axios().post(ENDPOINTS.getContents(hostName), params);
  },
  verifyCaptcha: (params: VerifyCaptchaParams) => {
    return Axios().post(ENDPOINTS.verifyCaptcha, params);
  },
  getStatesList: () => {
    return Axios().get(ENDPOINTS.getStatesList);
  },
  getPagesTitle: (params: GetContentsParams) => {
    return Axios().post(ENDPOINTS.getPagesTitle, params);
  },
  getSecurityQuestions: () => {
    return Axios().get(ENDPOINTS.getSecurityQuestions);
  },
  resubscribe: (params: string) => {
    return Axios().put(ENDPOINTS.resubscribe(params));
  },
  unsubscribe: (params: string) => {
    return Axios().put(ENDPOINTS.unsubscribe(params));
  },
  backendServices: () => {
    return Axios().get(ENDPOINTS.backendServices);
  },
};

export default sharedService;
