import React, { useCallback, useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from 'react-router-dom';

import Notification from 'components/common/Notification';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { UpdatePasswordParams } from 'interfaces/apiRequestInterfaces';
import authService from 'services/authService';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { UserStore, useUserStore } from 'stores/useUserStore';
import { AxiosError, Notification as NotificationType } from 'interfaces/interfaces';

interface ChangePasswordProps {
  show: boolean;
  hide: (data?: { messages: NotificationType[] }) => void;
}

const ChangePasswordPopup: React.FC<ChangePasswordProps> = ({ show, hide }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hideOldPassword, setHideOldPassword] = useState(true);
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [validations, setValidations] = useState({
    greaterThan8: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    newConfirmMatch: false,
    oldPasswordProvided: false,
  });
  const location = useLocation();
  const { triggerGTMDataLayer } = useContext(GlobalContext) as GlobalContextType;
  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { setPopupNotifications, notificationsData, resetPopupNotifications } = useNotificationsStore(
    (state: NotificationsStore) => ({
      setPopupNotifications: state.setPopupNotifications,
      notificationsData: state.notificationsData,
      resetPopupNotifications: state.resetPopupNotifications,
    }),
  );

  const checkValidation = useCallback(() => {
    const newValidations = {
      greaterThan8: newPassword.length >= 8,
      hasLowerCase: /[a-z]/.test(newPassword),
      hasUpperCase: /[A-Z]/.test(newPassword),
      hasNumber: /\d/.test(newPassword),
      newConfirmMatch: newPassword !== '' && confirmPassword !== '' && newPassword === confirmPassword,
      oldPasswordProvided: oldPassword.trim() !== '',
    };

    setValidations(newValidations);
    const isValid =
      newValidations.greaterThan8 &&
      newValidations.hasLowerCase &&
      newValidations.hasUpperCase &&
      newValidations.hasNumber &&
      newValidations.newConfirmMatch &&
      newValidations.oldPasswordProvided;

    setIsFormValid(isValid);
    return isValid;
  }, [newPassword, confirmPassword, oldPassword]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'oldPassword') setOldPassword(value);
    if (name === 'newPassword') setNewPassword(value);
    if (name === 'confirmPassword') setConfirmPassword(value);
    checkValidation();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const onSubmit = async () => {
    try {
      const isValid = checkValidation();

      if (isValid) {
        const hostName = document.location.hostname;
        const params: UpdatePasswordParams = {
          username: user?.userid as string,
          currentPassword: oldPassword,
          password: newPassword,
          hostName: hostName,
        };

        setAppLoading(true);
        const response = await authService.changePassword(params);

        if (response.status === 200) {
          resetPopupNotifications();
          hide({ messages: response.data.messages });
        } else {
          setPopupNotifications(response.data.messages);
        }
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      setPopupNotifications(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
    }
  };

  useEffect(() => {
    checkValidation();
  }, [newPassword, confirmPassword, checkValidation]);

  useEffect(() => {
    triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: '/change-password',
      pageTitle: `${user?.csUserid ? 'CS:' : ''} ${initData?.clientName} - Change Password`,
    });

    return () => {
      triggerGTMDataLayer({
        event: 'MyAccountPageView',
        pagePath: window.location.pathname,
        pageTitle: `${user?.csUserid ? 'CS:' : ''} ${window.document.title}`,
      });
    };
  }, [initData?.clientName, triggerGTMDataLayer, location.pathname, user?.csUserid]);

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={() => {
        resetPopupNotifications();
        hide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {notificationsData?.popupNotifications?.map((notification, index) => (
          <Notification key={index} id={index} message={notification} />
        ))}
        <div className="formPanel">
          <div className="form-group">
            <div className="oldPassword">
              <label htmlFor="oldpassword">Old Password</label>
              <input
                className="form-control password"
                type={hideOldPassword ? 'password' : 'text'}
                onKeyDown={handleKeyDown}
                name="oldPassword"
                onChange={handleChange}
              />
              <button onClick={() => setHideOldPassword(!hideOldPassword)} className="showHide">
                <i className={'far ' + (hideOldPassword ? 'fa-eye' : 'fa-eye-slash')}></i>
              </button>
            </div>
          </div>
          <div className="form-group">
            <div className="npassword">
              <label htmlFor="npassword">New Password</label>
              <input
                className="form-control password"
                type={hideNewPassword ? 'password' : 'text'}
                onKeyDown={handleKeyDown}
                name="newPassword"
                onChange={handleChange}
              />
              <button onClick={() => setHideNewPassword(!hideNewPassword)} className="showHide">
                <i className={'far ' + (hideNewPassword ? 'fa-eye' : 'fa-eye-slash')}></i>
              </button>
            </div>
          </div>
          <div className="form-group">
            <div className="passwordPolicy">
              <p className="heading">Password must have</p>
              <span className={validations.greaterThan8 ? 'valid' : 'invalid'}>at least 8 characters in length</span>
              <br />
              <span className={validations.hasLowerCase ? 'valid' : 'invalid'}>at least 1 lowercase letter (a-z)</span>
              <br />
              <span className={validations.hasUpperCase ? 'valid' : 'invalid'}>at least 1 uppercase letter (A-Z)</span>
              <br />
              <span className={validations.hasNumber ? 'valid' : 'invalid'}>at least 1 number (i.e. 0-9)</span>
              <br />
              <span className={validations.newConfirmMatch ? 'valid' : 'invalid'}>
                New and Confirm password must match
              </span>
              <br />
            </div>
          </div>

          <div className="form-group">
            <div className="email">
              <label htmlFor="confirmPassword">Confirm New Password</label>
              <input
                className="form-control password"
                type={hideConfirmPassword ? 'password' : 'text'}
                name="confirmPassword"
                onKeyDown={handleKeyDown}
                onChange={handleChange}
              />
              <button onClick={() => setHideConfirmPassword(!hideConfirmPassword)} className="showHide">
                <i className={'far ' + (hideConfirmPassword ? 'fa-eye' : 'fa-eye-slash')}></i>
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn agiaBlueButton-outline"
          data-dismiss="modal"
          onClick={() => {
            resetPopupNotifications();
            hide();
          }}
        >
          Cancel
        </button>

        <button
          type="button"
          disabled={!isFormValid}
          className="btn agiaBlueButton"
          data-dismiss="modal"
          onClick={onSubmit}
        >
          Save Password changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordPopup;
