import { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

import { LOCALIZATION } from 'constants/localization';
import { ARTICLES, NOTIFICATIONS, PAGE_TITLES } from 'constants/constants';
import './styles.scss';
import { InitStore, useInitStore } from 'stores/useInitStore';
import sharedService from 'services/sharedService';
import { TitleContentPair } from 'interfaces/interfaces';
import authService from 'services/authService';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import Notification from 'components/common/Notification';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { UserStore, useUserStore } from 'stores/useUserStore';

interface State {
  planNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  isBot: boolean;
  showPopup: boolean;
  isFormValid: boolean;
  globalWebContents: Record<string, string>;
  message: {
    type: string;
    text: string;
  };
  infoMessage: {
    type: string;
    content: string[];
  };
  pageTitle: string;
}

const ForgotUserName = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState<State>({
    planNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    isBot: true,
    showPopup: false,
    isFormValid: false,
    globalWebContents: {},
    message: {
      type: '',
      text: '',
    },
    infoMessage: {
      type: '',
      content: [''],
    },
    pageTitle: '',
  });

  const { triggerGTMDataLayer } = useContext(GlobalContext) as GlobalContextType;

  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { processMessages, notificationsData, setInterScreenNotification } = useNotificationsStore(
    (state: NotificationsStore) => ({
      processMessages: state.processMessages,
      notificationsData: state.notificationsData,
      setInterScreenNotification: state.setInterScreenNotification,
    }),
  );

  useEffect(() => {
    const { client } = initData ?? {};
    setState((prevState) => ({ ...prevState, pageTitle: client + PAGE_TITLES.FORGOT_USERNAME }));
    setAppLoading(true);
    const getContent = async (titles: string[]) => {
      const params = {
        titles: titles,
      };
      try {
        const response = await sharedService.getContents(params);
        if (response.status === 200) {
          const webContents: { [key: string]: string } = {};
          response?.data?.items?.forEach((element: TitleContentPair) => {
            webContents[element.title] = element.content;
          });

          setState((prevState) => ({ ...prevState, globalWebContents: webContents }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setAppLoading(false);
      }
    };
    getContent([ARTICLES.CERTI_NUM_INFO, ARTICLES.FORGOT_USERNAME_FORM_INFO]);
  }, [initData, setAppLoading]);

  const verifyRecaptcha = useCallback(
    async (recaptchaToken: string) => {
      if (recaptchaToken) {
        const params = {
          token: recaptchaToken,
        };

        setAppLoading(true);
        try {
          const response = await sharedService.verifyCaptcha(params);
          if (response.status === 200) {
            setState((prevState) => ({ ...prevState, isBot: response.data.isBot }));
          }
        } catch (error) {
          console.error(error);
        } finally {
          setAppLoading(false);
        }
      }
    },
    [setAppLoading],
  );

  const validate = () => {
    let errorString = '';

    if (!/^\d+$/.test(state.planNumber)) {
      errorString = errorString + NOTIFICATIONS.PLAN_NUM_NUMERIC + '\n';
    }

    if (state.planNumber.length !== 10) {
      errorString = errorString + NOTIFICATIONS.PLAN_NUM_10_LEN + '\n';
    }

    // Email validation
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(state.email.trim()).toLowerCase())) {
      errorString = errorString + NOTIFICATIONS.INVALID_EMAIL_FORMAT + '\n';
    }

    if (errorString) {
      return errorString;
    } else {
      return null;
    }
  };

  const checkRequiredFields = () => {
    if (/^\s*$/.test(state.planNumber)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    if (/^\s*$/.test(state.firstName)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    if (/^\s*$/.test(state.lastName)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    if (/^\s*$/.test(state.email)) {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
      return false;
    }

    setState((prevState) => ({ ...prevState, isFormValid: true }));
    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: name === 'planNumber' ? value.replace(/\s/g, '') : value }));
    checkRequiredFields();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (!state.isBot) {
      if (state.isFormValid) {
        const hasValidationError = validate();
        if (!hasValidationError) {
          const { client } = initData ?? {};
          const params = {
            client: client as string,
            certificateNo: state.planNumber,
            firstName: state.firstName,
            lastName: state.lastName,
            emailAddress: state.email,
            hostName: document.location.hostname,
          };
          setAppLoading(true);
          try {
            const response = await authService.forgotUsername(params);
            if (response.status === 200) {
              setInterScreenNotification(response.data.messages);
              navigate('/');
            } else {
              processMessages(response.data.messages);
            }
          } catch (error) {
            console.error(error);
          } finally {
            setAppLoading(false);
          }
        } else if (hasValidationError) {
          processMessages([
            {
              type: 'ERROR',
              content: [hasValidationError],
            },
          ]);
        }
      }
    } else {
      alert(NOTIFICATIONS.BOT_SUSPECTED);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {
            // this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.FORGOT_USERNAME] ||
            state.pageTitle
          }
        </title>
      </Helmet>

      <div className="usernameWrapper">
        <div className="lusernameColumn">
          <h1 className="mainHeadingT">{LOCALIZATION.EN.FORGOT_USERNAME}</h1>
          {!!notificationsData.nonInfoNotificaiton.length &&
            notificationsData.nonInfoNotificaiton.map((notification, index) => (
              <Notification key={index} id={index} message={notification} />
            ))}
          <p
            dangerouslySetInnerHTML={{
              __html: state.globalWebContents[ARTICLES.FORGOT_USERNAME_FORM_INFO],
            }}
          ></p>
          <div className="backgroundSetColor">
            <div className="formLayout">
              <div className="form-group">
                <div className="planNumber mt-3">
                  <label htmlFor="uname">Certificate/Plan Number</label>
                  <input
                    value={state.planNumber}
                    onKeyDown={handleKeyDown}
                    className="form-control"
                    type="text"
                    id="planNumber"
                    onChange={handleChange}
                    name="planNumber"
                    required
                  />

                  <Link
                    title={''}
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      setState((prevState) => ({ ...prevState, showPopup: true }));
                      triggerGTMDataLayer({
                        event: 'MyAccountPageView',
                        pagePath: '/find-plan-number',
                        pageTitle: `${user?.csUserid ? 'CS:' : ''} ${initData?.client} - Where to find your Certificate/Plan Number?`,
                      });
                    }}
                    className="findPlanText"
                  >
                    Where to find your Certificate/Plan Number?
                  </Link>
                  <div className="invalid-feedback">Please fill out this field.</div>
                </div>
              </div>
              <div className="form-group">
                <div className="lastName mt-3">
                  <label htmlFor="pwd">First Name</label>
                  <input
                    onKeyDown={handleKeyDown}
                    className="form-control"
                    type="text"
                    onChange={handleChange}
                    name="firstName"
                    required
                  />
                  <div className="invalid-feedback">Please fill out this field.</div>
                </div>
              </div>
              <div className="form-group">
                <div className="lastName mt-3">
                  <label htmlFor="pwd">Last Name</label>
                  <input
                    onKeyDown={handleKeyDown}
                    className="form-control"
                    type="text"
                    onChange={handleChange}
                    name="lastName"
                    required
                  />
                  <div className="invalid-feedback">Please fill out this field.</div>
                </div>
              </div>
              <div className="form-group">
                <div className="lastName mt-3">
                  <label htmlFor="pwd">Email</label>
                  <input
                    onKeyDown={handleKeyDown}
                    className="form-control"
                    type="text"
                    onChange={handleChange}
                    name="email"
                    required
                  />
                  <div className="invalid-feedback">Please fill out this field.</div>
                </div>
              </div>
            </div>
            <GoogleReCaptcha onVerify={verifyRecaptcha} />
          </div>
          <div className="roundBottomButton">
            <button
              className={'btn btn-primary agiaBlueButton ' + (!state.isFormValid && 'disabled')}
              type="button"
              onClick={handleSubmit}
              value="Submit"
            >
              Send recovery email
            </button>
            <Link to="/">Back to login</Link>
          </div>
        </div>
        <Modal
          backdrop="static"
          className="registerPopup"
          show={state.showPopup}
          onHide={() => {
            setState((prevState) => ({ ...prevState, showPopup: false }));
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Where to find your Certificate/Plan Number?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p
              dangerouslySetInnerHTML={{
                __html: state.globalWebContents[ARTICLES.CERTI_NUM_INFO],
              }}
            ></p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={'btn agiaBlueButton-outline grayButton'}
              type="button"
              onClick={() => {
                triggerGTMDataLayer({
                  event: 'MyAccountPageView',
                  pagePath: location.pathname,
                  pageTitle: `${user?.csUserid ? 'CS:' : ''} ${window.document.title}`,
                });
                setState((prevState) => ({ ...prevState, showPopup: false }));
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ForgotUserName;
