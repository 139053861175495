import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import AuthRoutes from 'routes/AuthRoutes';
import PrivateRoutes from 'routes/PrivateRoutes';
import PaymentCenterRoutes from 'routes/PaymentCenterRoutes';
import Home from 'pages/Home';
import Login from 'components/Login';
import Dashboard from 'pages/Dashboard';
import ForgotUserName from 'pages/ForgotUserName';
import { ForgotPassword, ForgotPasswordSecurityQuestion } from 'pages/ForgotPassword';
import { AccountDetails, PersonalInformation, SignInCreds } from 'pages/AccountDetails';
import MyProfile from 'pages/MyProfile';
import PaymentCenterProducts from 'pages/PaymentCenter/PaymentCenterProducts';
import PaymentMethods from 'pages/PaymentCenter/PaymentMethods';
import PayNow from 'pages/PaymentCenter/PayNow';
import ProductsMain from 'pages/ProductsMain';
import ProductDetails from 'pages/ProductDetails';
import ResetPassword from 'pages/ResetPassword';
import Unsubscribe from 'pages/Unsubscribe';
import CustomerServiceHome from 'pages/CustomerServiceHome';

const AllRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route element={<AuthRoutes />}>
          <Route path="/" element={<Home />} />
          <Route path="/forgot-username" element={<ForgotUserName />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-password-security-question" element={<ForgotPasswordSecurityQuestion />} />
          <Route path="/account-details" element={<AccountDetails />} />
          <Route path="/personal-info" element={<PersonalInformation />} />
          <Route path="/sign-in-creds" element={<SignInCreds />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/products-main" element={<ProductsMain />} />
          <Route path="/product-details/:id" element={<ProductDetails />} />
          <Route path="/customer-home" element={<CustomerServiceHome />} />
        </Route>
        <Route path="/payment-center" element={<PaymentCenterRoutes />}>
          <Route path="/payment-center/products" element={<PaymentCenterProducts />} />
          <Route path="/payment-center/payment-methods" element={<PaymentMethods />} />
          <Route path="/payment-center/pay-now" element={<PayNow />} />
        </Route>
        <Route path="/logging-in/:token" element={<Login />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/unsubscribe/:token" element={<Unsubscribe />} />
      </Routes>
    </Router>
  );
};

export default AllRoutes;
