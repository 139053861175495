import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ARTICLES } from 'constants/constants';
import { InactiveProducts } from './InactiveProducts';
import { Product } from 'interfaces/interfaces';

interface ProductsMainDesktopProps {
  products: Product[];
  inactiveProducts: Product[];
  globalWebContents: Record<string, string>;
}

const ProductsMainDesktop: React.FC<ProductsMainDesktopProps> = ({ products, inactiveProducts, globalWebContents }) => {
  const [showInactiveProducts, setShowInactiveProducts] = useState(false);

  const getDisclaimers = () => {
    const disclaimerTypesMap = [
      { name: 'autopay', content: globalWebContents[ARTICLES.PRODUCT_DISCLAIMER] },
      { name: 'combinedbill', content: globalWebContents[ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING] },
      { name: 'nocosttoyou', content: globalWebContents[ARTICLES.PRODUCT_DISCLAIMER_NOCOST] },
    ];

    const components: JSX.Element[] = [];
    const iterated: string[] = [];

    products.forEach((product, index) => {
      const disclaimerObj = disclaimerTypesMap.find((item) => item.name === product.paymentStatusDisplayStyle);
      const hasAlreadyIterated = !iterated.includes(product.paymentStatusDisplayStyle);

      if (disclaimerObj && hasAlreadyIterated) {
        components.push(<div key={index} dangerouslySetInnerHTML={{ __html: disclaimerObj.content }} />);
        iterated.push(product.paymentStatusDisplayStyle);
      }
    });

    return components;
  };

  return (
    <div className="desktopView productDetailTemplate">
      {products.length > 0 && (
        <>
          <h2 className="homeHederText">Active Products</h2>
          <table className="table table-light borderless productTable">
            <thead>
              <tr>
                <th className="product-col" scope="col">
                  PRODUCT
                </th>
                <th className="coverage-type-col" scope="col">
                  COVERAGE TYPE
                </th>
                <th className="coverage-eff-date-col" scope="col">
                  COVERAGE EFFECTIVE DATE
                </th>
                <th className="annual-product-cost-col amount" scope="col">
                  ANNUAL PRODUCT COST
                </th>
                <th className="payment-frequency-col" scope="col">
                  PAYMENT FREQUENCY
                </th>
                <th className="duedate-col" scope="col">
                  DUE DATE
                </th>
              </tr>
            </thead>
            <tbody>
              {products.map((value) => (
                <tr key={value.policyNumber} tabIndex={0}>
                  <td>
                    <Link title={''} to={`/product-details/${value.policyNumber}`}>
                      <span className="anchorText">{value.productDesc}</span>
                    </Link>
                    <span className="smallTableText">{value.policyNumber}</span>
                  </td>
                  <td>{value.coverageType}</td>
                  <td>{value.coverageEffectiveDate}</td>
                  <td className="amount">
                    {value.annualPremium}
                    {value.annualPremiumSpecialCharacter && (
                      <sup dangerouslySetInnerHTML={{ __html: value.annualPremiumSpecialCharacter }}></sup>
                    )}
                  </td>
                  <td>{value.currentPaymentFrequecyType}</td>
                  <td>
                    <span className="dateValue">{value.dueDate}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="footerTextPara">
            {getDisclaimers()}
            <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.SITE_DISCLAIMER] }}></div>
            <div dangerouslySetInnerHTML={{ __html: globalWebContents[ARTICLES.ANNUAL_PRODUCT_COST_DISCLAIMER] }}></div>
          </div>
        </>
      )}

      {inactiveProducts.length > 0 && (
        <div className="inActiveTable">
          <div className="inavtiveHeader">
            <h2 className="homeHederText">Inactive Products</h2>
            <Link
              onClick={(e) => {
                e.preventDefault();
                setShowInactiveProducts(!showInactiveProducts);
              }}
              aria-controls="collapse-tex"
              aria-expanded={showInactiveProducts}
              className="collapse-text"
              to="#"
            >
              <span className="leftArrow">Show</span>
              <span className="downArrow">Hide</span>
            </Link>
          </div>
          {showInactiveProducts && <InactiveProducts inactiveProducts={inactiveProducts} />}
        </div>
      )}
    </div>
  );
};

export default ProductsMainDesktop;
