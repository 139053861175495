interface LogoSectionProps {
  primaryLogoUrl: string;
  secondLogoUrl?: string;
  primaryLogoAnchorLink?: string;
}

const LogoSection = ({ primaryLogoUrl, secondLogoUrl, primaryLogoAnchorLink }: LogoSectionProps) => {
  return (
    <a target="_blank" rel="noreferrer" href={primaryLogoAnchorLink} className="logo d-block">
      <img alt="logo" src={primaryLogoUrl} />
      {secondLogoUrl && (
        <>
          <span className="divider divider-header-logos d-md-none"></span>
          <img className="secondary-logo-mobile d-md-none" alt="secondary-logo" src={secondLogoUrl} />
        </>
      )}
    </a>
  );
};

export default LogoSection;
