import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import { PaymentFrequency, PaymentMethod, Product } from 'interfaces/interfaces';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { UserStore, useUserStore } from 'stores/useUserStore';
import { InitStore, useInitStore } from 'stores/useInitStore';
import Notification from 'components/common/Notification';
import { convertStringToAmount } from 'utils/utils';

interface ChangeFrequencyAutopayModalProps {
  product: Product;
  paymentMethods: PaymentMethod[];
  selectedPaymentFrequecy: string;
  selectedPaymentMethod: string | null;
  show: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hide: (data?: string, params?: any) => void;
}

interface State {
  paymentFrequencies: PaymentFrequency[];
  selectedPaymentFrequecy: string;
  selectedPaymentMethod: string | null;
  amountDue: string;
  isFormValid: boolean;
}

const ChangeFrequencyAutopayModal = ({
  product,
  paymentMethods,
  selectedPaymentFrequecy,
  selectedPaymentMethod,
  show,
  hide,
}: ChangeFrequencyAutopayModalProps) => {
  const location = useLocation();
  const [state, setState] = useState<State>({
    paymentFrequencies: product.availablePaymentFrequencyList,
    selectedPaymentFrequecy: selectedPaymentFrequecy,
    selectedPaymentMethod: selectedPaymentMethod,
    amountDue: product.amountDue,
    isFormValid: true,
  });

  const { triggerGTMDataLayer } = useContext(GlobalContext) as GlobalContextType;

  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));

  const { initData } = useInitStore((state: InitStore) => ({
    initData: state.initData,
  }));

  const { notificationsData, setPopupNotifications, resetPopupNotifications } = useNotificationsStore(
    (state: NotificationsStore) => ({
      notificationsData: state.notificationsData,
      setPopupNotifications: state.setPopupNotifications,
      resetPopupNotifications: state.resetPopupNotifications,
    }),
  );

  useEffect(() => {
    if (product.paymentAction.changeAutoPayOrFrequency.message) {
      setPopupNotifications([
        {
          type: 'INFO',
          content: [product.paymentAction.changeAutoPayOrFrequency.message],
        },
      ]);
    }

    const paymentMethodObj = paymentMethods.find((item) => item.paymentRecordId === state.selectedPaymentMethod);
    const type = paymentMethodObj?.creditCard ? 'creditCard' : 'bankAccount';
    if (type === 'creditCard' && paymentMethodObj?.isCardExpired === 'Y') {
      const popupNotifications = notificationsData.popupNotifications;
      const newMsg = [
        {
          messageId: 'EXPIRED_CARD',
          type: 'ERROR',
          content: [paymentMethodObj.cardExpMessage],
        },
      ];
      const result = popupNotifications.concat(newMsg);
      setPopupNotifications(result);

      setState((prevState) => ({ ...prevState, isFormValid: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      triggerGTMDataLayer({
        event: 'MyAccountPageView',
        pagePath: '/change-payment-frequency-autopay',
        pageTitle: `${user?.csUserid ? 'CS:' : ''} ${initData?.clientName} - Change Auto Pay or Frequency`,
      });
    }, 1000);

    return () => {
      triggerGTMDataLayer({
        event: 'MyAccountPageView',
        pagePath: location.pathname,
        pageTitle: `${user?.csUserid ? 'CS:' : ''} ${window.document.title}`,
      });
    };
  }, [initData?.clientName, location.pathname, triggerGTMDataLayer, user?.csUserid]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'selectedPaymentMethod') {
      // detect change in payment methods drop down to show confirm popup right on dropdown change
      const isRemoveAutoPay = product.paymentStatusDisplayStyle === 'autopay';
      const paymentMethodObj = paymentMethods.find((item) => item.paymentRecordId === value);
      const type = paymentMethodObj?.creditCard ? 'creditCard' : 'bankAccount';

      if (type === 'creditCard' && paymentMethodObj?.isCardExpired === 'Y') {
        // If expired card, push the new message in messages array
        const popupNotifications = notificationsData.popupNotifications;
        const newMsg = [
          {
            messageId: 'EXPIRED_CARD',
            type: 'ERROR',
            content: [paymentMethodObj.cardExpMessage],
          },
        ];
        const result = popupNotifications.concat(newMsg);
        setPopupNotifications(result);

        setState((prevState) => ({ ...prevState, isFormValid: false }));
      } else {
        // If valid/not-expired card or bank account, remove the previously added error message
        const popupNotifications = notificationsData.popupNotifications;
        const index = popupNotifications.findIndex((item) => item.messageId === 'EXPIRED_CARD');
        if (index !== -1) {
          popupNotifications.splice(index, 1);
          setPopupNotifications(popupNotifications);
        }
        setState((prevState) => ({ ...prevState, isFormValid: true }));

        if ((value === '00' && isRemoveAutoPay) || value === 'ADD_NEW_PAYMENT_METHOD') {
          handleSubmit(value);
        }
      }
    } else if (name === 'selectedPaymentFrequecy') {
      // detect change in payment frequecy drop down to update the amount due
      const amountDue = state.paymentFrequencies.find((item) => item.code === value)?.['value2'] as string;
      setState((prevState) => ({ ...prevState, amountDue: amountDue }));
    }
  };

  const handleSubmit = (value?: string) => {
    if (state.selectedPaymentMethod === 'ADD_NEW_PAYMENT_METHOD' || value === 'ADD_NEW_PAYMENT_METHOD') {
      resetPopupNotifications();
      hide('ADD_NEW_PAYMENT_METHOD');
    } else {
      const params = getSubmitParams(value);

      if (params['emailType'] !== 'none') {
        // Do not do anything if no changes have been made
        resetPopupNotifications();
        hide(state.selectedPaymentMethod as string, params);
        return;
      } else {
        hide();
      }
    }
  };

  const getSubmitParams = (value?: string) => {
    const hostName = document.location.hostname;
    const paymentMethodObj = paymentMethods.find(
      (item) => item.paymentRecordId === (value ?? state.selectedPaymentMethod),
    );
    const paymentFreqObj = state.paymentFrequencies.find((item) => item.code === state.selectedPaymentFrequecy);
    const isBillingFeeInd = product.billingFeeInd === 'Y';
    const date = new Date();
    const currentDate =
      (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
      '/' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '/' +
      date.getFullYear();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {
      currentDate: currentDate,
      hostName: hostName,
      client: initData?.client,
      accountNumber: user?.accountNumber,
      policyNumber: product.policyNumber,
      firstName: user?.firstName,
      lastName: user?.lastName,
      userid: user?.userid,
      isEmailSubscribed: user?.isEmailSubscribed,
      paymentFrequency: paymentFreqObj?.value1,
      paymentMethod: paymentMethodObj?.paymentMethodHeader,
      paymentDetails: [
        {
          accountNumber: user?.accountNumber,
          policyNumber: product.policyNumber,
          billingMode: state.selectedPaymentFrequecy,
          paymentMethodRecordId: paymentMethodObj?.paymentRecordId,
          billingMethod: paymentMethodObj?.billingMethodCode ?? '04',
          paymentAmount: state.amountDue,
        },
      ],
    };

    // adding extra params for Email template
    params['emailType'] = getEmailType(params, product);
    params['productName'] = product.productDesc;
    params['paymentDueDate'] = product.dueDate;
    params['address'] = '';
    params['paymentMethodType'] = getPaymentMethodType(paymentMethodObj as PaymentMethod);
    params['lastFourDigit'] = getLastFour(paymentMethodObj as PaymentMethod);

    // add extra params for attestation calculations to be included in email template
    if (
      paymentMethodObj?.paymentRecordId !== '00' &&
      (params['emailType'] === 'add-admin-fees' || params['emailType'] === 'add')
    ) {
      // not if remove auto pay is selected
      if (isBillingFeeInd) {
        const billingFeeObj = product?.billingFees?.find((item) => item.code === paymentMethodObj?.billingMethodCode);
        const premium = convertStringToAmount(paymentFreqObj?.value2 as string);
        const billFee = convertStringToAmount(billingFeeObj?.value2 as string);

        params['premium'] = `${paymentFreqObj?.value2}`;
        params['adminFee'] = `${billingFeeObj?.value2}`;
        params['totalPremium'] = `$${(premium + billFee).toFixed(2)}`;
      } else {
        params['totalPremium'] = `${paymentFreqObj?.value2}`;
      }
    }

    return params;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getEmailType = ({ paymentDetails }: any, product: Product) => {
    const { paymentMethodRecordId, billingMode } = paymentDetails[0];
    const isPaymentMethodUpdated = hasPaymentMethodUpdated(product, paymentMethodRecordId);

    // Remove AutoPay
    if (paymentMethodRecordId === '00' && product.paymentStatusDisplayStyle === 'autopay') {
      // if remove auto pay
      return 'remove';
    }

    // Both value get changed
    if (billingMode !== product.currentPaymentFrequecyCode && isPaymentMethodUpdated) {
      return 'both';
    }

    // From non auto pay to auto pay
    if (product.paymentStatusDisplayStyle !== 'autopay' && paymentMethodRecordId !== '00') {
      return product.billingFeeInd === 'Y' ? 'add-admin-fees' : 'add';
    }

    // Only frequency value get changed
    if (billingMode !== product.currentPaymentFrequecyCode && !isPaymentMethodUpdated) {
      return 'frequency';
    }

    // Only payment method value get changed
    if (billingMode === product.currentPaymentFrequecyCode && isPaymentMethodUpdated) {
      return 'payment-method';
    }

    // not change anything
    return 'none';
  };

  const hasPaymentMethodUpdated = (product: Product, paymentMethodRecordId: string) => {
    if (product.paymentStatusDisplayStyle === 'autopay') {
      // If on auto pay
      if (product.currentPaymentMethodRecordId !== paymentMethodRecordId) {
        // check if drop downs are changed
        return true;
      } else {
        return false;
      }
    } else {
      // if not on auto pay
      if (paymentMethodRecordId !== '00') {
        // if a valid payment method is selected instead of 'Not on Auto Pay'
        return true;
      } else {
        // if 'Not on Auto Pay' is selected, means payment method not updated
        return false;
      }
    }
  };

  const getLastFour = (paymentMethodObj: PaymentMethod) => {
    if (paymentMethodObj.paymentRecordId !== '00') {
      const type = paymentMethodObj.creditCard ? 'creditCard' : 'bankAccount';
      const lastFour = paymentMethodObj[type].accountNumberLast4Digit;
      return lastFour;
    } else {
      return '';
    }
  };

  const getPaymentMethodType = (paymentMethodObj: PaymentMethod) => {
    if (paymentMethodObj.paymentRecordId !== '00') {
      const type = paymentMethodObj.creditCard ? 'creditCard' : 'bankAccount';
      const pType = paymentMethodObj[type].paymentType;
      return pType;
    } else {
      return '';
    }
  };

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={() => {
        resetPopupNotifications();
        hide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Change Auto Pay or Frequency</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {notificationsData.popupNotifications.map((notification, index) => (
          <Notification key={index} id={index} message={notification} />
        ))}
        <h2 className="subHeading">{product.productDesc}</h2>
        <div className="bodyRow">
          <div className="flexBox">
            <ul>
              <li>
                <span className="label">CERTIFICATE/PLAN NUMBER</span>
                <span className="value">{product.policyNumber}</span>
              </li>
              <li>
                <span className="label">PAYMENT FREQUENCY</span>
                <span className="value">
                  {state.paymentFrequencies.length === 1 && <span>{state.paymentFrequencies[0].value1}</span>}
                  {state.paymentFrequencies.length > 1 && (
                    <select
                      value={state.selectedPaymentFrequecy}
                      onChange={handleChange}
                      name="selectedPaymentFrequecy"
                    >
                      {state.paymentFrequencies.map((value) => (
                        <option key={value.code} value={value.code}>
                          {value.value1}
                        </option>
                      ))}
                    </select>
                  )}
                </span>
              </li>
              <li>
                <span className="label">AUTO PAY METHOD</span>
                <span className="value">
                  <select
                    value={state.selectedPaymentMethod as string}
                    onChange={handleChange}
                    name="selectedPaymentMethod"
                  >
                    {paymentMethods.map((value) => (
                      <option key={value.paymentRecordId} value={value.paymentRecordId}>
                        {value.paymentMethodHeader}
                      </option>
                    ))}
                  </select>
                </span>
              </li>
              <li>
                <span className="label">DUE DATE</span>
                <span className="value">{product.dueDate}</span>
              </li>
              <li>
                <span className="label">AMOUNT DUE</span>
                <span className="value">
                  {state.amountDue}
                  <sup>*</sup>
                </span>
              </li>
            </ul>
            {product.disclaimer && (
              <span
                className="updateFrequencyDisclaimer"
                dangerouslySetInnerHTML={{ __html: product.disclaimer }}
              ></span>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn agiaBlueButton-outline"
          data-dismiss="modal"
          onClick={() => {
            resetPopupNotifications();
            hide();
          }}
        >
          Cancel
        </button>

        <button
          id="ma-af-saveChanges"
          disabled={!state.isFormValid || product.paymentAction.saveChanges.disable === 'Y'}
          type="button"
          className="btn agiaBlueButton"
          data-dismiss="modal"
          onClick={() => handleSubmit()}
        >
          Save changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeFrequencyAutopayModal;
