import { useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { UserStore, useUserStore } from 'stores/useUserStore';

interface ConfirmPayNowProps {
  show: boolean;
  hide: (flag?: boolean) => void;
  content: string;
}

const ConfirmPayNow = ({ show, hide, content }: ConfirmPayNowProps) => {
  const location = useLocation();

  const { triggerGTMDataLayer } = useContext(GlobalContext) as GlobalContextType;

  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));

  const { initData } = useInitStore((state: InitStore) => ({
    initData: state.initData,
  }));

  useEffect(() => {
    triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: '/payment-authorization',
      pageTitle: `${user?.csUserid ? 'CS:' : ''} ${initData?.clientName} - Payment Authorization`,
    });
    return () => {
      triggerGTMDataLayer({
        event: 'MyAccountPageView',
        pagePath: location.pathname,
        pageTitle: `${user?.csUserid ? 'CS:' : ''} ${window.document.title}`,
      });
    };
  }, [initData?.clientName, location.pathname, triggerGTMDataLayer, user?.csUserid]);

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Payment Authorization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => {
            hide();
          }}
          type="button"
          className="btn agiaBlueButton-outline"
          data-dismiss="modal"
        >
          I Do Not Agree
        </button>

        <button
          onClick={() => {
            hide(true);
          }}
          type="button"
          className="btn agiaBlueButton"
          data-dismiss="modal"
        >
          I Agree
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmPayNow;
