import { UserStore, useUserStore } from 'stores/useUserStore';

const ImpersonateHeader = () => {
  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));

  return (
    <div className="impersonateHeader">
      <i className="materialicons person">person</i>
      <label className="navText">{user?.csUserid}</label>
    </div>
  );
};

export default ImpersonateHeader;
