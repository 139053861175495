export const LOCALIZATION = {
  EN: {
    LOGIN_TITLE: 'Log in to MyAccount',
    FORGOT_USERNAME: 'Recover your username',
    FORGOT_PASSWORD_1: 'Reset your password',
    FORGOT_PASSWORD_2: 'Reset your password',
    EMAIL_SENT_CONFIRMATION: 'Check your email!',
    REGISTRATION_ALL_STEPS: 'Register your account',
    REACTIVATE_ALL_STEPS: 'Reactivate your account',
    ACCOUNT_DETAILS: 'Authenticate Identity',
    PERSONAL_INFO: 'Personal Information',
    SIGN_IN_CREDS: 'Sign-in Credentials',
    CHANGE_PASSWORD: 'Change your password',
  },
};
