import React from 'react';
import NAV_BORDER from 'assets/images/navBorder.svg';
import ACTIVE_CIRCLE from 'assets/images/activeCircle.svg';
import NON_ACTIVE_CIRCLE from 'assets/images/nonActiveCircle.svg';
import VISITED_CIRCLE from 'assets/images/visitedCircle.svg';

interface StepperTabProps {
  step: number;
}

export const StepperTab: React.FC<StepperTabProps> = ({ step }) => {
  return (
    <div className="nav nav-pills">
      <div className="innerBorder">
        <img alt="Navigation Border" src={NAV_BORDER} />
      </div>
      <div className="nav-item">
        <div className={`navbar-link ${step === 1 ? 'active' : 'visited'}`}>
          <span className="numberText">1</span>
          <img alt="Step 1 Indicator" src={step === 1 ? ACTIVE_CIRCLE : VISITED_CIRCLE} />
          <br />
          <span className="menuText">Identification Information</span>
        </div>
      </div>
      <div className="nav-item">
        <div className={`navbar-link ${step === 2 ? 'active' : step > 2 ? 'visited' : ''}`}>
          <span className="numberText">2</span>
          <img
            alt="Step 2 Indicator"
            src={step === 2 ? ACTIVE_CIRCLE : step > 2 ? VISITED_CIRCLE : NON_ACTIVE_CIRCLE}
          />
          <br />
          <span className="menuText">Personal Information</span>
        </div>
      </div>
      <div className="nav-item">
        <div className={`navbar-link ${step === 3 ? 'active' : ''}`}>
          <span className="numberText">3</span>
          <img alt="Step 3 Indicator" src={step === 3 ? ACTIVE_CIRCLE : NON_ACTIVE_CIRCLE} />
          <br />
          <span className="menuText">Login Credentials</span>
        </div>
      </div>
    </div>
  );
};
