import { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import './styles.scss';
import { ARTICLES, PAGE_TITLES, VIEW_DOC_LOADER_HTML_STR } from 'constants/constants';
import Notification from 'components/common/Notification';
import ProductDetailsDesktop, { ProductDocument } from './ProductDetailsDesktop';
import ProductDetailsMobile from './ProductDetailsMobile';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { UserStore, useUserStore } from 'stores/useUserStore';
import sharedService from 'services/sharedService';
import { AxiosError, TitleContentPair } from 'interfaces/interfaces';
import productsService from 'services/productsService';
import { GetProductDetailsParams } from 'interfaces/apiRequestInterfaces';

interface State {
  pageTitle: string;
  globalWebContents: Record<string, string>;
}

export interface AdditionalInsured {
  fullName: string;
  relationship: string;
}

export interface OptionalRiders {
  productDesc: string;
  policyNumber: string;
  policyStatus: string;
  coverageEffectiveDate: string;
  benefitAmount: string;
  annualPremium: string;
}

export interface ProductOverview {
  productDesc: string;
  isInsuranceProduct: string;
  policyNumber: string;
  coverageType: string;
  policyStatus: string;
  coverageEffectiveDate: string;
  suppressPaymentDueDate: string;
  paymentDueDateLabel: string;
  paymentDueDate: string;
  benefitAmount: string;
  isCancerOrHipOrHap: string;
  owner: string;
  annualPremium: string;
  annualPremiumSpecialCharacter: string;
  paymentFrequency: string;
  message?: string;
}

const ProductDetails = () => {
  const windowRef = useRef<Window | null>(null);
  const [productOverview, setProductOverview] = useState<ProductOverview | null>(null);
  const [additionalInsured, setAdditionalInsured] = useState<AdditionalInsured[] | null>(null);
  const [optionalRiders, setOptionalRiders] = useState<OptionalRiders[]>([]);
  const [productDocuments, setProductDocuments] = useState<ProductDocument[]>([]);
  const [correspondences, setCorrespondences] = useState<ProductDocument[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [state, setState] = useState<State>({
    pageTitle: '',
    globalWebContents: {},
  });
  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));
  const { processMessages, notificationsData } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
    notificationsData: state.notificationsData,
  }));
  const { user } = useUserStore((state: UserStore) => ({
    user: state.user,
  }));
  const { globalContextState } = useContext(GlobalContext) as GlobalContextType;
  const { id: policyNumberParam } = useParams();

  const getProductDetails = useCallback(async () => {
    if (!user) {
      return null;
    }

    try {
      setAppLoading(true);
      const { client } = initData ?? {};
      const params: GetProductDetailsParams = {
        client: client as string,
        accountNumber: user.accountNumber,
        policyNumber: policyNumberParam as string,
        firstName: user.firstName,
        lastName: user.lastName,
        userid: user.userid,
        isEmailSubscribed: user?.isEmailSubscribed,
      };
      const response = await productsService.getProductDetails(params);

      if (response.status === 200) {
        setProductOverview(response.data.productDetail);
        setAdditionalInsured(response.data.additionalInsured);
        setOptionalRiders(response.data.optionalRiders || []);
        setProductDocuments(response.data.productDocuments || []);
        setCorrespondences(response.data.correspondences || []);
      } else {
        setOptionalRiders([]);
        processMessages(response.data?.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    } finally {
      setAppLoading(false);
      setDataLoaded(true);
    }
  }, [initData, policyNumberParam, processMessages, setAppLoading, user]);

  const convertToPDF = (response: string) => {
    const blob = convertBase64toBlob(response, 'application/pdf');
    const isMobile = globalContextState.deviceWidth <= 767;
    const blobURL = URL.createObjectURL(blob);
    windowRef.current!.location = blobURL;
    if (isMobile) {
      setTimeout(() => {
        windowRef.current!.close();
      }, 1000);
    }
  };

  const convertBase64toBlob = (content: string, contentType: string) => {
    contentType = contentType || '';
    const sliceSize = 512;
    const byteCharacters = window.atob(content);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {
      type: contentType || 'application/octet-stream',
    });
  };

  const viewDocument = async (correspondence: ProductDocument, docList: string) => {
    const isIEBrowser = navigator.appVersion.toString().indexOf('.NET') > 0;
    if (!isIEBrowser) {
      windowRef.current = window.open();
      windowRef.current!.document.write(VIEW_DOC_LOADER_HTML_STR);
    }

    try {
      const res = await productsService.getDocument(
        correspondence.documentId,
        correspondence.documentSource,
        correspondence.documentCategory,
      );

      if (res.status === 200) {
        if (res.data.document) {
          updateDocError(false, docList, correspondence.documentId);
          convertToPDF(res.data.document);
        } else {
          if (!isIEBrowser) windowRef.current!.close();
          updateDocError(true, docList, correspondence.documentId);
        }
      } else {
        if (!isIEBrowser) windowRef.current!.close();
        updateDocError(true, docList, correspondence.documentId);
      }
    } catch (error) {
      console.error(error);
      if (!isIEBrowser) windowRef.current!.close();
      updateDocError(true, docList, correspondence.documentId);
    }
  };

  const updateDocError = (flag: boolean, type: string, docId: string) => {
    if (type === 'PRODUCT_DOCS') {
      const updatedDocs = productDocuments.map((doc) =>
        doc.documentId === docId ? { ...doc, showDocError: flag } : doc,
      );
      setProductDocuments(updatedDocs);
    } else {
      const updatedDocs = correspondences.map((doc) =>
        doc.documentId === docId ? { ...doc, showDocError: flag } : doc,
      );
      setCorrespondences(updatedDocs);
    }
  };

  useEffect(() => {
    const { clientName } = initData ?? {};
    setState((prevState) => ({ ...prevState, pageTitle: clientName + PAGE_TITLES.PRODUCT_DETAILS }));
    const getContent = async (titles: string[]) => {
      try {
        setAppLoading(true);
        const params = {
          titles,
        };
        const response = await sharedService.getContents(params);
        if (response.status === 200) {
          const webContents: Record<string, string> = {};
          response.data.items.forEach((element: TitleContentPair) => {
            webContents[element.title] = element.content;
          });
          setState((prevState) => ({ ...prevState, globalWebContents: webContents }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setAppLoading(false);
      }
    };

    getProductDetails();
    getContent([
      ARTICLES.PRODUCT_DETAILS_BENEFITS,
      ARTICLES.PRODUCT_DETAILS_MISMATCH_DATA,
      ARTICLES.SITE_DISCLAIMER,
      ARTICLES.DOCUMENT_ERROR,
    ]);
  }, [getProductDetails, initData, setAppLoading]);

  return (
    <>
      <Helmet>
        <title>
          {
            // globalContextState.pagesTitle[PAGES_TITLE_KEYS.PRODUCT_DETAILS] ||
            state.pageTitle
          }
        </title>
      </Helmet>
      <div className="innerBodyWrapper">
        {notificationsData.infoNotification?.map((notification, index: number) => (
          <Notification key={index} id={index} message={notification} />
        ))}
        <div className="productsDesc">
          {notificationsData.nonInfoNotificaiton?.map((notification, index: number) => (
            <Notification key={index} id={index} message={notification} />
          ))}
          {dataLoaded && (
            <>
              {globalContextState.deviceWidth >= 768 && (
                <ProductDetailsDesktop
                  optionalRiders={optionalRiders}
                  productOverview={productOverview as ProductOverview}
                  additionalInsured={additionalInsured as AdditionalInsured[]}
                  productDocuments={productDocuments}
                  correspondences={correspondences}
                  globalWebContents={state.globalWebContents}
                  openDocument={viewDocument}
                />
              )}
              {globalContextState.deviceWidth <= 767 && (
                <ProductDetailsMobile
                  optionalRiders={optionalRiders}
                  productOverview={productOverview as ProductOverview}
                  additionalInsured={additionalInsured as AdditionalInsured[]}
                  productDocuments={productDocuments}
                  correspondences={correspondences}
                  globalWebContents={state.globalWebContents}
                  openDocument={viewDocument}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
