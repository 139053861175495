import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import { InitStore, useInitStore } from 'stores/useInitStore';
import NotFound from 'components/NotFound';
import Loading from 'components/common/Loading';
import InitOperations from './InitOperations';
import SessionManager from './SessionManager';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();
  const { initData, appLoaded, hostFound, appLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    appLoaded: state.appLoaded,
    hostFound: state.hostFound,
    appLoading: state.appLoading,
  }));

  return (
    <>
      {appLoading && <Loading />}
      <div className="mainWrapper">
        {appLoaded && hostFound ? (
          <>
            <Helmet>
              <link rel="stylesheet" type="text/css" href={initData?.brandDetail?.cssFileUrl} />
            </Helmet>
            <InitOperations />
            <SessionManager />
            <Header />
            <main className={`bodyWrapper ${location.pathname === '/' ? 'not-logged-in' : ''}`}>{children}</main>
            <Footer />
          </>
        ) : null}
        {appLoaded && !hostFound ? <NotFound /> : null}
      </div>
    </>
  );
};

export default Layout;
