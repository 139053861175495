import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  browserName,
  browserVersion,
  isMobile,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from 'react-device-detect';

import './styles.scss';
import { InitStore, useInitStore } from 'stores/useInitStore';
import { PAGE_TITLES } from 'constants/constants';
import { GlobalContext, GlobalContextType } from 'context/GlobalContext';
import { NotificationsStore, useNotificationsStore } from 'stores/useNotificationsStore';
import Notification from 'components/common/Notification';
import { AxiosError } from 'interfaces/interfaces';
import authService from 'services/authService';
import { UserStore, useUserStore } from 'stores/useUserStore';

interface State {
  newPassword: string;
  confirmPassword: string;
  hideNewPassword: boolean;
  hideConfirmPassword: boolean;
  isFormValid: boolean;
  validations: {
    greaterThan8: boolean;
    hasLowerCase: boolean;
    hasUpperCase: boolean;
    hasNumber: boolean;
    newConfirmMatch: boolean;
  };
  pageTitle: string;
}

const ResetPassword = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState<State>({
    newPassword: '',
    confirmPassword: '',
    hideNewPassword: true,
    hideConfirmPassword: true,
    isFormValid: false,
    validations: {
      greaterThan8: false,
      hasLowerCase: false,
      hasUpperCase: false,
      hasNumber: false,
      newConfirmMatch: false,
    },
    pageTitle: '',
  });

  const { setShowNavigationBar } = useContext(GlobalContext) as GlobalContextType;

  const { setUser } = useUserStore((state: UserStore) => ({
    setUser: state.setUser,
  }));

  const { initData, setAppLoading } = useInitStore((state: InitStore) => ({
    initData: state.initData,
    setAppLoading: state.setAppLoading,
  }));

  const { processMessages, notificationsData } = useNotificationsStore((state: NotificationsStore) => ({
    processMessages: state.processMessages,
    notificationsData: state.notificationsData,
  }));

  useEffect(() => {
    setState((prevState) => ({ ...prevState, pageTitle: initData?.clientName + PAGE_TITLES.RESET_PASSWORD }));
  }, [initData?.clientName]);

  useEffect(() => {
    if (location.pathname.includes('/reset-password/')) {
      setShowNavigationBar(false);
    }

    return () => {
      setShowNavigationBar(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      state.validations.greaterThan8 &&
      state.validations.hasLowerCase &&
      state.validations.hasUpperCase &&
      state.validations.hasNumber &&
      state.validations.newConfirmMatch
    ) {
      setState((prevState) => ({ ...prevState, isFormValid: true }));
    } else {
      setState((prevState) => ({ ...prevState, isFormValid: false }));
    }
  }, [
    state.validations.greaterThan8,
    state.validations.hasLowerCase,
    state.validations.hasNumber,
    state.validations.hasUpperCase,
    state.validations.newConfirmMatch,
  ]);

  const checkValidation = () => {
    setState((prevState) => ({
      ...prevState,
      validations: {
        greaterThan8: prevState.newPassword.length >= 8,
        hasLowerCase: !!prevState.newPassword.match(/^(.*[a-z].*)$/),
        hasUpperCase: !!prevState.newPassword.match(/^(.*[A-Z].*)$/),
        hasNumber: !prevState.newPassword.match(/^([^0-9]*)$/),
        newConfirmMatch:
          prevState.newPassword !== '' &&
          prevState.confirmPassword !== '' &&
          prevState.newPassword === prevState.confirmPassword,
      },
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    checkValidation();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const onSubmit = async () => {
    if (state.isFormValid) {
      const payload = {
        password: state.newPassword,
        ticket: params.token,
        hostName: document.location.hostname,
        client: initData?.client,
      } as Record<string, string>;

      try {
        setAppLoading(true);
        const response = await authService.resetPassword(payload);
        if (response.status === 200) {
          await login(response.data.userName);
        } else {
          processMessages(response.data.messages);
        }
      } catch (error) {
        console.error(error);
        const axiosError = error as AxiosError;
        processMessages(axiosError?.response?.data?.messages);
      } finally {
        setAppLoading(false);
      }
    }
  };

  const login = async (username: string) => {
    const params = {
      username: username,
      password: state.newPassword,
      isCustomerUser: false,
      client: initData?.client as string,
    };

    try {
      const response = await authService.login(params);
      if (response.status === 200) {
        setUser(response.data);
        const ipResponse = await authService.getPublicIP();
        const params = {
          username: username,
          isCustomerUser: false,
          client: initData?.client as string,
          clientLoginIp: ipResponse.data?.ip,
          userDeviceInfo: {
            userOS: osName,
            userOSVersion: osVersion,
            browserName: browserName,
            browserVersion: browserVersion,
            isMobile: isMobile ? 'Y' : 'N',
            mobileModel: mobileModel,
            mobileVendor: mobileVendor,
          },
        };

        try {
          const response = await authService.updateUserInformation(params);
          if (response.status !== 403) {
            navigate('/dashboard');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        processMessages(response.data.messages);
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      processMessages(axiosError?.response?.data?.messages);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {
            //   this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.RESET_PASSWORD] ||
            state.pageTitle
          }
        </title>
      </Helmet>
      <div className="resetPwd usernameWrapper">
        <div className="lusernameColumn">
          <h1 className="mainHeadingT">Reset your password</h1>
          {notificationsData.nonInfoNotificaiton.map((notification, index) => (
            <Notification key={index} id={index} message={notification} />
          ))}
          <div className="backgroundSetColor">
            <div className="columnLayout">
              <div className="leftCol">
                <div className="password mt-3">
                  <label htmlFor="pwd">New Password</label>
                  <input
                    className="form-control password"
                    type={state.hideNewPassword ? 'password' : 'text'}
                    name="newPassword"
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                  />
                  <button
                    onClick={() => {
                      setState((prevState) => ({ ...prevState, hideNewPassword: !prevState.hideNewPassword }));
                    }}
                    className="showHide"
                  >
                    <i className={'far ' + (state.hideNewPassword ? 'fa-eye' : 'fa-eye-slash')}></i>
                  </button>
                  <div className="invalid-feedback">Please fill out this field.</div>
                </div>

                <div className="password mt-3">
                  <label htmlFor="pwd">Confirm Password</label>
                  <input
                    className="form-control password"
                    type={state.hideConfirmPassword ? 'password' : 'text'}
                    name="confirmPassword"
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                  />
                  <button
                    onClick={() => {
                      setState((prevState) => ({ ...prevState, hideConfirmPassword: !prevState.hideConfirmPassword }));
                    }}
                    className="showHide"
                  >
                    <i className={'far ' + (state.hideConfirmPassword ? 'fa-eye' : 'fa-eye-slash')}></i>
                  </button>
                  <div className="invalid-feedback">Please fill out this field.</div>
                </div>
              </div>
              <div className="leftCol">
                <div className="password-validation-ctnr">
                  <span className="passwordHeadingText">Password must have: </span> <br></br>
                  <span className={state.validations.greaterThan8 ? 'valid' : 'invalid'}>
                    at least 8 characters in length{' '}
                  </span>{' '}
                  <br></br>
                  <span className={state.validations.hasLowerCase ? 'valid' : 'invalid'}>
                    at least 1 lowercase letter (a-z){' '}
                  </span>{' '}
                  <br></br>
                  <span className={state.validations.hasUpperCase ? 'valid' : 'invalid'}>
                    at least 1 uppercase letter (A-Z){' '}
                  </span>{' '}
                  <br></br>
                  <span className={state.validations.hasNumber ? 'valid' : 'invalid'}>
                    at least 1 number (i.e. 0-9){' '}
                  </span>{' '}
                  <br></br>
                  <span className={state.validations.newConfirmMatch ? 'valid' : 'invalid'}>
                    New and Confirm password must match{' '}
                  </span>{' '}
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="roundBottomButton">
            <button
              className={'btn btn-primary agiaBlueButton mt-3 ' + (!state.isFormValid && 'disabled')}
              type="button"
              value="Submit"
              onClick={onSubmit}
            >
              Submit new password
            </button>
            <Link to="/" className="mt-3">
              Back to login
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
