import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import sharedService from 'services/sharedService';

const BackendServicesInfo = () => {
  const [backendServicesInfo, setBackendServicesInfo] = useState([]);

  useEffect(() => {
    const getBackendServicesInfo = async () => {
      try {
        const response = await sharedService.backendServices();
        if (response.status === 200) {
          setBackendServicesInfo(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getBackendServicesInfo();
  }, []);

  return (
    <>
      {createPortal(
        <div style={{ display: 'none' }} id="backend-services">
          {backendServicesInfo.map((data) => (
            <span key={data}>{data}</span>
          ))}
        </div>,
        document.body,
      )}
    </>
  );
};

export default BackendServicesInfo;
